import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

var defaultMixin = {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    render(h) {
        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + this.type || 'Unnamed';

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ backgroundColor: "#a0ffa0", ...utils.getSize(this.controlData ? this.controlData.SizeOptions : null, this.parentType, this.$parent) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {name}<br />
            </div>
        );
    }
};

Vue.component('default-unknown-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('interval-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('broadcast-listener-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('css-style-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('infinite-grid-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('slim-grid-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('date-range-picker-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('dropdown-multi-select-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('basic-input-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('html-component-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('basic-file-upload-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('popup-menu-button-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('rt-event-handler-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('flow-chart-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('soft-phone-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('dash-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('cc-timeline-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('infinite-conversation-view-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('rooster-editor-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('google-button-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('apple-button-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('acd-direct-button-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('server-event-listener-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('saml-button-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('text-editor-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('circle-chart-dsgn', {
    mixins: [defaultMixin]
});

Vue.component('bar-chart-dsgn', {
    mixins: [defaultMixin]
});

//Vue.component('real-time-grid-dsgn', {
//    mixins: [defaultMixin]
//});

Vue.component('realtime-widget-listener-dsgn', {
    mixins: [defaultMixin]
});
