<template>
    <div :style="style" v-if="todisplay" v-show="isvisible" :class="{ 'c-AdvancedHistory': true, [`c-name-${this.name || 'unnamed'}`]: true }">
        <!--<v-text-field dense outlined
                      style="flex-grow: 0;"
                      v-model="datespan">
        </v-text-field>-->
        <div style="display: flex; flex-direction: row;">
            <c-Date-Range-Picker style="min-width: 420px;"
                                 type="daterangepicker"
                                 :allowTime="true"
                                 :timePickerIncrement="1"
                                 :isDisabled="false"
                                 :default="defaultTime"
                                 placeholder="Select Date Range"
                                 :allowFutureDate="true"
                                 :allowPastDate="true"
                                 @apply-date-range="applyDateRangeHandler"
                                 @clear-date-range="clearDateRangeHandler"></c-Date-Range-Picker>

            <v-text-field dense outlined
                          style="flex-grow: 0; min-width: 400px; margin-top: 4px; margin-left: 10px;"
                          label="Enter Search Text"
                          v-model="search">
                <v-icon slot="append" @click="clearSearch" v-show="search">mdi-close</v-icon>
                <v-icon slot="append-outer" @click="Refresh">mdi-play</v-icon>
            </v-text-field>
        </div>
        <AgGridVue :key="unique_id"
                   :class="{ 'ag-theme-alpine': true }"
                   :style="{ display: 'flex', flexDirection: 'column', flexGrow: '1' }"
                   :columnDefs="columnDefs"
                   _rowData="dataSource"
                   :datasource="dataSource"
                   rowModelType="infinite"
                   @gridReady="onGridReady"
                   @firstDataRendered="onFirstDataRendered">

        </AgGridVue>
        <IconComponent v-show="false" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import BaseComponent from './BaseComponentMixin.jsx';
    import utils from '../../Shared/utils.jsx';
    import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

    import { AgGridVue } from "ag-grid-vue";
    import AgContentDef from './AgContentDef.jsx';
    import cDateRangePicker from './vuecontrols/DateRangePicker.vue';

    // If we move IconComponent into its own file, we can use .vue or .jsx and we
    // won't have to use the render function in this manner:
    const IconComponent = { // inline custom component for ag-grid
        methods: {
            sessionId() {
                return this.params && this.params.data ? this.params.data.sessionid : '...';
            },
            buttonClick(e) {
                alert('clicked ' + this.sessionId());
            },
        },
        render(h) {
            // h is a function that we call to create instances of any UI components.
            // Returning a button with an icon inside that can be clicked.
            const i = h('i',
                {
                    ['aria-hidden']: 'true',
                    class: 'v-icon notranslate mdi mdi-mdi mdi-magnify-plus-outline theme--light',
                    style: 'font-size: 20px;'
                }
            )
            const span = h('span',
                {
                    class: 'v-btn__content'
                },
                [i]
            );
            const button =
                h('button',
                    {
                        type: 'button',
                        class: 'v-btn v-btn--icon v-btn--round theme--light elevation-0 v-size--default secondary--text',
                        medium: 'true',
                        on: {
                            click: (e) => this.buttonClick(e)
                        }
                    },
                    [span]
                );

            return button;
        }
    };

    export default {
        name: "AdvancedHistory",
        mixins: [BaseComponent],
        components: {
            AgGridVue,
            IconComponent,
            cDateRangePicker,
        },
        data: function () {
            return {
                unique_id: utils.generateUUID(),
                datespan: '12/22/2022 12:00:00 AM - 12/22/2022 11:59:59 PM',
                datamodel: null,
                defaultTime: 'today',
                baseurl: 'Apps/OSDemo/GetDetailHistory',
                startDate : '2024-02-01T00:09:00.0000000Z',
                endDate: '2024-03-01T00:09:00.0000000Z',
                dataSource: null,
                search: '',
            }
        },
        created() {
            this.dataSource = {
                getRows: async (params) => {
                    if (this.startDate && this.endDate) {

                        // params: {"startRow":0,"endRow":100,"sortModel":[{"sort":"asc","colId":"starttime"}],"filterModel":{}}
                        // params: {"startRow":0,"endRow":100,"sortModel":[{"sort":"desc","colId":"prequeuetime"}],
                        //          "filterModel":{"callerid":{"filterType":"text","type":"contains","filter":"5432"}}}
                        const q_from = params.startRow;
                        const q_size = params.endRow - params.startRow;

                        let dataurl = `${this.baseurl}?StartTime=${this.startDate}&EndTime=${this.endDate}&PageSize=${q_size}&From=${q_from}`;
                        if (params.sortModel && params.sortModel.length)
                            dataurl += `&SortCol=${params.sortModel[0].colId}&Descending=${params.sortModel[0].sort=='desc'}`;
                        
                        if (params.filterModel) {
                            dataurl += '&filters=' + encodeURIComponent( JSON.stringify(params.filterModel) );
                        }

                        if (this.search)
                            dataurl += '&search=' + encodeURIComponent( this.search );

                        console.log(`params: ${JSON.stringify(params)}`);
                        console.log('url: ' + dataurl);

                        const res = await utils.api.get(dataurl);

                        console.log(`get returned ${res && res.rows ? res.rows.length : 0} row(s)`);

                        params.successCallback(res.rows, res.total);
                    }
                }
            };
        },
        //Created Replaced with preRenderComplete
        computed: {
            style() {
                return {
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "400px",
                    padding: '8px',
                    ...this.sizeStyle,
                };
            },
            columnDefs() {
                // This should be database driven - for a given report, we should define columns and save in couchdb.
                // But the settings in couch should be simplified - we don't want to be defining all the properties
                // of a column definition - that would defeat the purpose. Keep it to a standard set of column types
                // and predefine the other settings (hidden, sortable, etc.).
                return [
                    { "field": "", "filter": false, "headerName": "", "hide": false, "sortable": false, "cellRenderer": "IconComponent" },
                    { "colId": "starttime", "field": "starttime", "filter": true, "headerName": "Date", "hide": false, "sortable": true, resizable: true, valueFormatter: this.dateFormatter },
                    { "colId": "masterid", "field": "masterid", "filter": true, "headerName": "Master SessionID", "hide": true, "sortable": true, resizable: true },
                    { "colId": "direction", "field": "direction", "filter": true, "headerName": "Direction", "hide": false, "sortable": true, resizable: true },
                    { "colId": "timelinetype", "field": "timelinetype", "filter": true, "headerName": "Channel Type", "hide": false, "sortable": true, resizable: true },
                    { "colId": "destination", "field": "destination", "filter": true, "headerName": "Destination", "hide": false, "sortable": true, resizable: true, valueFormatter: this.phonenumFormatter },
                    { "colId": "destinationname", "field": "destinationname", "filter": true, "headerName": "Destination Description", "hide": true, "sortable": false, resizable: true },
                    { "colId": "callername", "field": "callername", "filter": true, "headerName": "Originator Name", "hide": false, "sortable": true, resizable: true },
                    { "colId": "queuename", "field": "queuename", "filter": true, "headerName": "Queue", "hide": false, "sortable": true, resizable: true },
                    { "colId": "userid", "field": "userid", "filter": true, "headerName": "User Name", "hide": false, "sortable": true, resizable: true },
                    { "colId": "team", "field": "team", "filter": true, "headerName": "Team", "hide": false, "sortable": true, resizable: true },
                    { "colId": "prequeuetime", "field": "prequeuetime", "filter": true, "headerName": "PreQueue Time", "hide": false, "sortable": true, resizable: true, valueFormatter: this.durationFormatter },
                    { "colId": "queuetime", "field": "queuetime", "filter": true, "headerName": "Queue Time", "hide": false, "sortable": true, resizable: true, valueFormatter: this.durationFormatter },
                    { "colId": "usertalktime", "field": "usertalktime", "filter": true, "headerName": "Talk Time", "hide": false, "sortable": true, resizable: true, valueFormatter: this.durationFormatter },
                    { "colId": "contacttime", "field": "contacttime", "filter": true, "headerName": "Duration", "hide": false, "sortable": true, resizable: true, valueFormatter: this.durationFormatter },
                    { "colId": "timelineid", "field": "timelineid", "filter": true, "headerName": "SessionID", "hide": true, "sortable": true, resizable: true },
                    { "colId": "callerid", "field": "callerid", "filter": true, "headerName": "Originator", "hide": false, "sortable": true, resizable: true, valueFormatter: this.phonenumFormatter }
                ];
            },
        },
        methods: {
            async preRenderComplete() {
                this.finishRenderHandler(this);
                this.Refresh();
            },
            async Refresh() {
                if (this.startDate && this.endDate) {
                //    this.gridApi.refreshServerSide();
                    this.gridApi.refreshInfiniteCache();
                }
            },

            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
            },
            onFirstDataRendered() {
                this.columnApi.autoSizeAllColumns();
            },

            dateFormatter(value) {
                return this.f_date(value.value, 'medium');
            },
            durationFormatter(value) {
                if (typeof value.value === 'number')
                    return this.f_number(value.value);
                else
                    value.value;
            },
            phonenumFormatter(value) {
                return this.f_phoneNumber(value.value);
            },

            applyDateRangeHandler(range) {
                console.log('applyDateRangeHandler');
                console.log(range.startDate);
                console.log(range.endDate);

                this.startDate = range.startDate;
                this.endDate = range.endDate;

                this.Refresh();
            },
            clearDateRangeHandler() {
            },
            clearSearch(){
                this.search = '';
                this.Refresh();
            },
        },
    };
</script>

<style scoped>
</style>