import Vue from 'vue';
import token from '@/Services/token';
import utils from './utils.jsx';
import localStorageSyncService from '@/Services/localStorageSyncService.js';

const carei18nLanguages = [
    {
        name: "Afrikaans",
        native: "Afrikaans",
        key: "af",
        locales: [
            {
                name: "Nambia",
                key: "af-na"
            },
            {
                name: "South Africa",
                key: "af-za"
            }
        ]
    },
    {
        name: "Albanian",
        native: "Shqipja",
        key: "sq",
        locales: [
            {
                name: "Albania",
                key: "sq-al"
            }
        ]
    },
    {
        name: "Arabic",
        native: "العربية",
        key: "ar",
        locales: [
            {
                name: "Algeria",
                key: "ar-dz"
            },
            {
                name: "Bahrain",
                key: "ar-bh"
            },
            {
                name: "Egypt",
                key: "ar-eg"
            },
            {
                name: "Iraq",
                key: "ar-iq"
            },
            {
                name: "Jordan",
                key: "ar-jo"
            },
            {
                name: "Kuwait",
                key: "ar-kw"
            },
            {
                name: "Lebanon",
                key: "ar-lb"
            },
            {
                name: "Libya",
                key: "ar-ly"
            },
            {
                name: "Morocco",
                key: "ar-ma"
            },
            {
                name: "Oman",
                key: "ar-om"
            },
            {
                name: "Qatar",
                key: "ar-qa"
            },
            {
                name: "Saudi Arabia",
                key: "ar-sa"
            },
            {
                name: "Sudan",
                key: "ar-sd"
            },
            {
                name: "Syria",
                key: "ar-sy"
            },
            {
                name: "Tunisia",
                key: "ar-tn"
            },
            {
                name: "United Arab Emirates",
                key: "ar-ae"
            },
            {
                name: "Yemen",
                key: "ar-ye"
            }
        ]
    },
    {
        name: "Bangla",
        native: "বাংলা",
        key: "bn",
        locales: [
            {
                name: "Bangladesh",
                key: "bn-bd"
            },
            {
                name: "India",
                key: "bn-in"
            }
        ]
    },
    {
        name: "Basque",
        native: "Basque",
        key: "eu",
        locales: [
            {
                name: "Spain",
                key: "eu-es"
            }
        ]
    },
    {
        name: "Bulgarian",
        native: "български",
        key: "bg",
        locales: [
            {
                name: "Bulgaria",
                key: "bg-bg"
            }
        ]
    },
    {
        name: "Cantonese",
        native: "粵語 繁體",
        key: "yue",
        locales: [
            {
                name: "China",
                key: "zh-cn"
            },
            {
                name: "Hong Kong",
                key: "yui-hk"
            }
        ]
    },
    {
        name: "Catalan",
        native: "català",
        key: "ca",
        locales: [
            {
                name: "Spain",
                key: "ca-es"
            },
            {
                name: "France",
                key: "ca-fr"
            },
            {
                name: "Italy",
                key: "ca-it"
            }
        ]
    },
    {
        name: "Chinese",
        native: "中文",
        key: "zh",
        locales: [
            {
                name: "China",
                key: "zh-cn"
            },
            {
                name: "Hong Kong",
                key: "zh-hk"
            },
            {
                name: "Taiwan",
                key: "zh-tw"
            }
        ]
    },
    {
        name: "Croatian",
        native: "hrvatski",
        key: "hr",
        locales: [
            {
                name: "Croatia",
                key: "hr-hr"
            },
            {
                name: "Bosnia and Herzegovina",
                key: "hr-ba"
            }
        ]
    },
    {
        name: "Czech",
        native: "čeština",
        key: "cs",
        locales: [
            {
                name: "Czechia",
                key: "cs-cz"
            }
        ]
    },
    {
        name: "Danish",
        native: "Dansk",
        key: "da",
        locales: [
            {
                name: "Denmark",
                key: "da-dk"
            },
            {
                name: "Greenland",
                key: "da-gl"
            }
        ]
    },
    {
        name: "Dutch",
        native: "Nederlands",
        key: "nl",
        locales: [
            {
                name: "Belgium",
                key: "nl-be"
            },
            {
                name: "Netherlands",
                key: "nl-nl"
            }
        ]
    },
    {
        name: "English",
        key: "en",
        locales: [
            {
                name: "Australia",
                key: "en-au"
            },
            {
                name: "Canada",
                key: "en-ca"
            },
            {
                name: "India",
                key: "en-in"
            },
            {
                name: "Ireland",
                key: "en-ie"
            },
            {
                name: "Malta",
                key: "en-mt"
            },
            {
                name: "New Zealand",
                key: "en-nz"
            },
            {
                name: "Philippines",
                key: "en-ph"
            },
            {
                name: "Singapore",
                key: "en-sg"
            },
            {
                name: "South Africa",
                key: "en-za"
            },
            {
                name: "United Kingdom",
                key: "en-gb"
            },
            {
                name: "United States",
                key: "en-us"
            }
        ],
    },
    {
        name: "Estonian",
        native: "eesti",
        key: "et",
        locales: [
            {
                name: "Estonia",
                key: "et-ee"
            }
        ]
    },
    {
        name: "Filipino",
        native: "Filipino",
        key: "fil",
        locales: [
            {
                name: "Philippines",
                key: "fil-ph"
            }
        ]
    },
    {
        name: "Finnish",
        native: "suomi",
        key: "fi",
        locales: [
            {
                name: "Finland",
                key: "fi-fi"
            }
        ]
    },
    {
        name: "French",
        native: "Français",
        key: "fr",
        locales: [
            {
                name: "Belgium",
                key: "fr-be"
            },
            {
                name: "Canada",
                key: "fr-ca"
            },
            {
                name: "France",
                key: "fr-fr"
            },
            {
                name: "Luxembourg",
                key: "fr-lu"
            },
            {
                name: "Switzerland",
                key: "fr-ch"
            }
        ]
    },
    {
        name: "German",
        native: "Deutsch",
        key: "de",
        locales: [
            {
                name: "Austria",
                key: "de-at"
            },
            {
                name: "Belgium",
                key: "de-be"
            },
            {
                name: "Germany",
                key: "de-de"
            },
            {
                name: "Liechtenstein",
                key: "de-li"
            },
            {
                name: "Luxembourg",
                key: "de-lu"
            },
            {
                name: "Switzerland",
                key: "de-ch"
            }

        ]
    },
    {
        name: "Galician",
        native: "Galician",
        key: "gl",
        locales: [
            {
                name: "Spain",
                key: "gl-es"
            }
        ]
    },
    {
        name: "Georgian",
        native: "ქართული ენა",
        key: "ka",
        locales: [
            {
                name: "Georgia",
                key: "ka-ge"
            }
        ]
    },
    {
        name: "Greek",
        native: "Ελληνικά",
        key: "el",
        locales: [
            {
                name: "Cyprus",
                key: "el-cy"
            },
            {
                name: "Greece",
                key: "el-gr"
            }
        ]
    },
    {
        name: "Gujarati",
        native: "ગુજરાતી",
        key: "gu",
        locales: [
            {
                name: "India",
                key: "gu-in"
            }
        ]
    },
    {
        name: "Hebrew",
        native: "עברית",
        key: "he",
        locales: [
            {
                name: "Israel",
                key: "he-il"
            }
        ]
    },
    {
        name: "Hindi",
        native: "हिन्दी",
        key: "hi",
        locales: [
            {
                name: "India",
                key: "hi-in"
            }
        ]
    },
    {
        name: "Hungarian",
        native: "magyar",
        key: "hu",
        locales: [
            {
                name: "Hungary",
                key: "hu-hu"
            }
        ]
    },
    {
        name: "Icelandic",
        native: "íslenska",
        key: "is",
        locales: [
            {
                name: "Iceland",
                key: "is-is"
            }
        ]
    },
    {
        name: "Indonesian",
        native: "Indonesia",
        key: "id",
        locales: [
            {
                name: "Indonesia",
                key: "id-id"
            }
        ]
    },
    {
        name: "Italian",
        native: "Italiano",
        key: "it",
        locales: [
            {
                name: "Italy",
                key: "it-it"
            },
            {
                name: "Switzerland",
                key: "it-ch"
            }
        ]
    },
    {
        name: "Japanese",
        native: "日本語",
        key: "ja",
        locales: [
            {
                name: "Japan",
                key: "ja-jp"
            }
        ]
    },
    {
        name: "Kannada",
        native: "ಕನ್ನಡ",
        key: "kn",
        locales: [
            {
                name: "India",
                key: "kn-in"
            }
        ]
    },
    {
        name: "Korean",
        native: "한국어",
        key: "ko",
        locales: [
            {
                name: "North Korea",
                key: "ko-kp"
            },
            {
                name: "South Korea",
                key: "ko-kr"
            }
        ]
    },
    {
        name: "Latvian",
        native: "latviešu",
        key: "lv",
        locales: [
            {
                name: "Latvia",
                key: "lv-lv"
            }
        ]
    },
    {
        name: "Lithuanian",
        native: "lietuvių",
        key: "lt",
        locales: [
            {
                name: "Lithuania",
                key: "lt-lt"
            }
        ]
    },
    {
        name: "Macedonian",
        native: "македонски",
        key: "mk",
        locales: [
            {
                name: "Macedonia",
                key: "mk-mk"
            }
        ]
    },
    {
        name: "Malay",
        native: "Melayu",
        key: "ms",
        locales: [
            {
                name: "Brunei",
                key: "ms-bn"
            },
            {
                name: "Malaysia",
                key: "ms-my"
            },
            {
                name: "Singapore",
                key: "ms-sg"
            }
        ]
    },
    {
        name: "Malayalam",
        native: "മലയാളം",
        key: "ml",
        locales: [
            {
                name: "India",
                key: "ml-in"
            }
        ]
    },
    {
        name: "Maltese",
        native: "Malti",
        key: "mt",
        locales: [
            {
                name: "Malta",
                key: "mt-mt"
            }
        ]
    },
    {
        name: "Marathi",
        native: "Marathi",
        key: "mr",
        locales: [
            {
                name: "India",
                key: "mr-in"
            }
        ]
    },
    {
        name: "Mongolian",
        native: "монгол хэл",
        key: "mn",
        locales: [
            {
                name: "Mongolia",
                key: "mn-mn"
            }
        ]
    },
    {
        name: "Burmese",
        native: "မြန်မာစကား",
        key: "my",
        locales: [
            {
                name: "Myanmar",
                key: "my-mm"
            }
        ]
    },
    {
        name: "Nepali",
        native: "नेपाली",
        key: "ne",
        locales: [
            {
                name: "Nepal",
                key: "ne-np"
            }
        ]
    },
    {
        name: "Norwegian Bokmål",
        native: "Bokmål",
        key: "nb",
        locales: [
            {
                name: "Norway",
                key: "nb-no"
            }
        ]
    },
    {
        name: "Norwegian",
        native: "norsk",
        key: "no",
        locales: [
            {
                name: "Norway",
                key: "no-no"
            }
        ]
    },
    {
        name: "Polish",
        native: "polski",
        key: "pl",
        locales: [
            {
                name: "Poland",
                key: "pl-pl"
            }
        ]
    },
    {
        name: "Portuguese",
        native: "Português",
        key: "pt",
        locales: [
            {
                name: "Brazil",
                key: "pt-br"
            },
            {
                name: "Portugal",
                key: "pt-pt"
            }
        ]
    },
    {
        name: "Romanian",
        native: "română",
        key: "ro",
        locales: [
            {
                name: "Moldova",
                key: "ro-md"
            },
            {
                name: "Romania",
                key: "ro-ro"
            }
        ]
    },
    {
        name: "Russian",
        native: "русский",
        key: "ru",
        locales: [
            {
                name: "Belaru",
                key: "ru-by"
            },
            {
                name: "Kyrgyzstan",
                key: "ru-kg"
            },
            {
                name: "Kazakhstan",
                key: "ru-kz"
            },
            {
                name: "Moldova",
                key: "ru-md"
            },
            {
                name: "Russia",
                key: "ru-ru"
            },
            {
                name: "Ukraine",
                key: "ru-ua"
            }
        ]
    },
    {
        name: "Serbian",
        native: "српски",
        key: "sr",
        locales: [
            {
                name: "Bosnia & Herzegovina",
                key: "sr-cyrl-ba"
            },
            {
                name: "Montenegro",
                key: "sr-cyrl-me"
            },
            {
                name: "Serbia",
                key: "sr-cyrl-rs"
            },
            {
                name: "Serbia",
                key: "sr-rs"
            },
            {
                name: "Kosovo",
                key: "sr-cyrl-xk"
            }
        ]
    },
    {
        name: "Slovak",
        native: "slovenčina",
        key: "sk",
        locales: [
            {
                name: "Slovakia",
                key: "sk-sk"
            }
        ]
    },
    {
        name: "Slovenian",
        native: "slovenščina",
        key: "sl",
        locales: [
            {
                name: "Slovenia",
                key: "sl-si"
            }
        ]
    },
    {
        name: "Spanish",
        native: "Español",
        key: "es",
        locales: [
            {
                name: "Argentina",
                key: "es-ar"
            },
            {
                name: "Bolivia",
                key: "es-bo"
            },
            {
                name: "Chile",
                key: "es-cl"
            },
            {
                name: "Colombia",
                key: "es-co"
            },
            {
                name: "Costa Rica",
                key: "es-cr"
            },
            {
                name: "Dominican Republic",
                key: "es-do"
            },
            {
                name: "Ecuador",
                key: "es-ec"
            },
            {
                name: "El Salvador",
                key: "es-sv"
            },
            {
                name: "Guatemala",
                key: "es-gt"
            },
            {
                name: "Honduras",
                key: "es-hn"
            },
            {
                name: "Mexico",
                key: "es-mx"
            },
            {
                name: "Nicaragua",
                key: "es-ni"
            },
            {
                name: "Panama",
                key: "es-pa"
            },
            {
                name: "Paraguay",
                key: "es-py"
            },
            {
                name: "Peru",
                key: "es-pe"
            },
            {
                name: "Puerto Rico",
                key: "es-pr"
            },
            {
                name: "Spain",
                key: "es-es"
            },
            {
                name: "United States",
                key: "es-US"
            },
            {
                name: "Uruguay",
                key: "es-uy"
            },
            {
                name: "Venezuela",
                key: "es-ve"
            }
        ]
    },
    {
        name: "Swedish",
        native: "Svenska",
        key: "sv",
        locales: [
            {
                name: "Sweden",
                key: "sv-se"
            }
        ]
    },
    {
        name: "Tamil",
        native: "தமிழ்",
        key: "ta",
        locales: [
            {
                name: "India",
                key: "ta-in"
            },
            {
                name: "Sri Lanka",
                key: "ta-lk"
            },
            {
                name: "Malaysia",
                key: "ta-my"
            },
            {
                name: "Singapore",
                key: "ta-sg"
            }
        ]
    },
    {
        name: "Telugu",
        native: "తెలుగు",
        key: "te",
        locales: [
            {
                name: "India",
                key: "te-in"
            }
        ]
    },
    {
        name: "Thai",
        native: "ไทย",
        key: "th",
        locales: [
            {
                name: "Thailand",
                key: "th-th"
            }
        ]
    },
    {
        name: "Turkish",
        native: "Türkçe",
        key: "tr",
        locales: [
            {
                name: "Cyprus",
                key: "tr-cy"
            },
            {
                name: "Turkey",
                key: "tr-tr"
            }
        ]
    },
    {
        name: "Ukrainian",
        native: "українська",
        key: "uk",
        locales: [
            {
                name: "Ukraine",
                key: "uk-ua"
            }
        ]
    },
    {
        name: "Urdu",
        native: "اردو",
        key: "ur",
        locales: [
            {
                name: "India",
                key: "ur-in"
            },
            {
                name: "Pakistan",
                key: "ur-pk"
            }
        ]
    },
    {
        name: "Vietnamese",
        native: "Tiếng Việt",
        key: "vi",
        locales: [
            {
                name: "Vietnam",
                key: "vi-vn"
            }
        ]
    },
    {
        name: "Wu",
        native: "吴语",
        key: "wuu",
        locales: [
            {
                name: "China",
                key: "wuu-cn"
            }
        ]
    },
    {
        name: "Zulu",
        native: "IsiZulu",
        key: "zu",
        locales: [
            {
                name: "South Africa",
                key: "zu-za"
            }
        ]
    }
];

const appSettings = {
    IsVuePortal: true,
    get HSMDebugging() {
        return utils.hsmlist.hsms.filter(h => h.hsm.visible).map(h => h.name);
    },
    set HSMDebugging(value) {
        utils.hsmlist.setVisibility(value);
    },
    get ShowNotImplemented() {
        return (this.NoContextLocalStorage("careAppSettings.ShowNotImplemented") == 'true');
    },
    set ShowNotImplemented(value) {
        this.NoContextLocalStorage("careAppSettings.ShowNotImplemented", value);
    },
    DefaultDebug: false, // set by environment and overridden by local storage setting
    get ShowUIEvents() {
        // by default only error messages are shown in the console log
        return (this.NoContextLocalStorage("careAppSettings.ShowUIEvents") == 'true');
    },
    set ShowUIEvents(value) {
        this.NoContextLocalStorage("careAppSettings.ShowUIEvents", value);
    },
    get ShowAllLogMessages() {
        // by default only error messages are shown in the console log
        return (this.NoContextLocalStorage("careAppSettings.ShowAllLogMessages") == 'true');
    },
    set ShowAllLogMessages(value) {
        this.NoContextLocalStorage("careAppSettings.ShowAllLogMessages", value);
    },
    get DEBUG() {
        var debug = this.NoContextLocalStorage("careAppSettings.DEBUG");
        return !debug ? this.DefaultDebug : (debug == 'true');
    },
    set DEBUG(value) {
        this.NoContextLocalStorage("careAppSettings.DEBUG", value);
    },
    get SHOWBASE() {
        var debug = this.NoContextLocalStorage("careAppSettings.SHOWBASE");
        return (debug == 'true');
    },
    set SHOWBASE(value) {
        this.NoContextLocalStorage("careAppSettings.SHOWBASE", value);
    },
    get DebugTranslationPrefixSetting()  {
        return this.NoContextLocalStorage("careAppSettings.DebugTranslationPrefix") === "true";
    },
    get DebugTranslationPrefix() {
        var currentSetting = this.NoContextLocalStorage("careAppSettings.DebugTranslationPrefix");
        return (currentSetting === 'true');
    },
    set DebugTranslationPrefix(value) {
        this.NoContextLocalStorage("careAppSettings.DebugTranslationPrefix", value);
    },
    get DisableGuidedAssistants() {
        var disableGuidedAssistant = this.NoContextLocalStorage("careAppSettings.DisableGuidedAssistants");
        return (disableGuidedAssistant === 'true');
    },
    set DisableGuidedAssistants(value) {
        this.NoContextLocalStorage("careAppSettings.DisableGuidedAssistants", value);
    },
    DEBUG_CONSOLE_LINES: 512,
    WhiteLabel: {
        CompanyName: "",
        DomainName: "",
        FavIconString: "",
        FavIconType: "",
        CompanyLogoString: "",
        CompanyLogoType: "",
        LoginImageString: "",
        LoginImageType: "",
        LoginDisplayType: "",
        LoginCustomHtml: "",
        LoginUrl: "",
        ApiUrl: "",
        ApiUrlBandTemplate: "",
        WssSuffix: "",
        GoogleMapApiKey: "",
        FacebookAppId: "",
        AppleSigninId: "",
        GoogleClientId: "",
        ACDDirectId: "",
        IBSendGridEmailForwardingDomain: "",
        SAMLProviders: []
    },
    get ApiBase() {
        return this.WhiteLabel.ApiUrl;
    },
    get ApiURLBandTemplate() {
        return this.WhiteLabel.ApiUrlBandTemplate;
    },
    get FacebookAppId() {
        return this.WhiteLabel.FacebookAppId;
    },
    get AppleSigninId() {
        return this.WhiteLabel.AppleSigninId;
    },
    get GoogleClientId() {
        return this.WhiteLabel.GoogleClientId;
    },
    get ACDDirectId() {
        return this.WhiteLabel.ACDDirectId;
    },
    get GoogleMapsApiKey() {
        return this.WhiteLabel.GoogleMapApiKey;
    },
    LanguageKey: "en",
    LocaleKey: "en",
    WindowLocation: window.location,
    AvailableLanguages: carei18nLanguages,
    Environment: "", // "" = Production || "Development", "Staging", "Local"
    PayeezyXLogin: "",
    PayeezyUrl: "",
    get ReportingTimezone() {
        if (this.careTokenService)
            return this.careTokenService.CustomerTimezone();
        else
            return '';
    },
    get BandID() {
        if (this.careTokenService)
            return this.careTokenService.BandID();
        else
            return '';
    },
    get AccessToken() {
        if (this.careTokenService)
            return this.careTokenService.AccessToken();
        else
            return '';
    },
    get UserEventKey() {
        if (this.careTokenService)
            return this.careTokenService.GetUserEventKey();
        else
            return '';
    },
    get CustomerEventKey() {
        if (this.careTokenService)
            return this.careTokenService.GetCustomerEventKey();
        else
            return '';
    },
    get PublicEventKey() {
        return 'GeneralEvent_Public';
    },
    get ChannelName() {
        if (this.careTokenService)
            return this.careTokenService.GetChannelName();
        else
            return '';
    },
    UpdateToken: function (info) {
        if (this.careTokenService)
            return this.careTokenService.UpdateToken(info);
    },
    LocalStorage: function (key, value) {
        var context = "";

        if (this.careTokenService)
            context = this.careTokenService.UserID() + '_' + this.careTokenService.CustomerID();

        let prefixedKey = 'user.' + context + '.' + key;

        if (value === undefined)
            return localStorage.getItem(prefixedKey);
        // When we write new keys, we want to make sure those keys are sent down to our local storage sync service.
        localStorageSyncService.dispatchLocalStorageUpdate({ [prefixedKey]: value });
        if (value == null)
            localStorage.removeItem(prefixedKey);
        else
            localStorage.setItem(prefixedKey, value);
    },
    UserLocalStorage: function (key, value) {
        var context = "";

        if (this.careTokenService)
            context = this.careTokenService.UserID();

        let prefixedKey = 'user.' + context + '.' + key;

        if (value === undefined)
            return localStorage.getItem(prefixedKey);
        
        // When we write new keys, we want to make sure those keys are sent down to our local storage sync service.
        localStorageSyncService.dispatchLocalStorageUpdate({ [prefixedKey]: value });
        
        if (value == null)
            localStorage.removeItem(prefixedKey);
        else
            localStorage.setItem(prefixedKey, value);
    },
    NoContextLocalStorage: function (key, value) {
        let prefixedKey = 'user.' + key;

        if (value === undefined)
            return localStorage.getItem(prefixedKey);
        
        // When we write new keys, we want to make sure those keys are sent down to our local storage sync service.
        localStorageSyncService.dispatchLocalStorageUpdate({ [prefixedKey]: value });

        if (value == null)
            localStorage.removeItem(prefixedKey);
        else
            localStorage.setItem(prefixedKey, value);
    },
    UnfinishedApis: function () {
        if (this.careApiRequestMonitorService && this.careService) {
            var apisWaiting = this.careApiRequestMonitorService.GetWaitingAPIs();
            var count = careHelpfulFunctions.length(apisWaiting);

            console.log("APIs Waiting: " + count);
            if (count)
                console.log(JSON.stringify(apisWaiting));
        }
    },
    angular$qService: null,
    $rootScope: null,
    careApiService: null,
    care$qService: null,
    careCookieService: null,
    careWebSocketService: null,
    careTokenService: null,
    careService: null,
    careActionService: null,
    careCenterBase: null,
    careApiRequestMonitorService: null,
    plantUmlService: null,
    careControlHelperService: null,
    ServerTimeOffset: null, // difference in server time to client time
    ClientTimeSnapshot: null, // client time used to calculate ServerTimeOffset
    ServerTimeSnapshot: null, // server time used to calculate ServerTimeOffset
    InstanceGuid: null, // Unique guid generated by server. Used to initialize token InstanceGuid if not already existing
    SystemId: null, // Used to load default language translations 
    DebugInfo: {
        ConsoleLog: [],
        DebugActionLog: []
    },
    $timeout: function (callback, waitms) {
        if (!waitms)
            //Vue.nextTick(callback);
            window.setTimeout(callback, 100); // Set to run after 100ms rather than on nextTick -- specifically to handle the phone number assignment page
        else
            window.setTimeout(callback, waitms);
    },

    OpenDocumentForDebug: function (docInfo, parentControlModel, scope) {
        var actions = [
            {
                "$typeSchema": "/schema/public/Platform.Schema.UIActions.v1/UIAction_Reference",
                "PrimitiveAction": true,
                "ActionType": "ReferenceUserAction",
                "RunAsync": false,
                "ActionData": {
                    "UserAction": {
                        "CacheAction": true,
                        "ActionData": {
                            "docInfo": docInfo
                        },
                        "ActionURL": "public/Authoring/Ctrl-RightClick"
                    },
                    "Debug": {
                        "BreakPoint": false
                    }
                }
            }
        ];

        this.careActionService.actionBroadcaster({
            actions: actions,
            scope: scope || this.careService.$rootScope,
            parent: parentControlModel,
        });
    }
};

// Referencable from $controlModel, provides place for users to store global variables
const careGlobalVars = {};

const careHSMs = [];

const globalTranslationList = {};

const careTokenSettings = {
    info: undefined
};

const careRequestParams = {};

const careInstanceSettings = {
    //We don't need a UUID here; we just need something that will be unique among app instantiations. Current time in milliseconds will do nicely.
    //However, if we ever do decide that we want to track instances uniquily across tenants, we'll need to revisit this and change it to a UUID.
    id: new Date().getTime(),

    instantiationTimeMillis: new Date().getTime(),
    userAgent: navigator.userAgent,
    amqpChannelName: null //Set by ServerEventCoordinatorService when registering a channel with RabbitMQ
};

const careChildWindowCommunication = {
    careDebugServiceChildWindow: {}
};

if (window.sessionStorage && window.sessionStorage.getItem("ChildWindowClearSessionStorage") == "true"){
    sessionStorage.clear();
}

export {
    carei18nLanguages,
    appSettings,
    careGlobalVars,
    careHSMs,
    globalTranslationList,
    careTokenSettings,
    careRequestParams,
    careInstanceSettings,
    careChildWindowCommunication,
};