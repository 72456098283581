import Vue from "vue";
import Vuetify from "vuetify";
import EventBus from "./event-bus.js";
import axios from "axios";
import ace from "./components/3rdParty/AceEditor.jsx";
import utils from "../Shared/utils.jsx";
import methods from "../Shared/methods.js";
import filters from "../Shared/filters.js";
import computed from "../Shared/computed.js";
import careHelpfulFunctions from "./careHelpfulFunctions.jsx";
import BaseComponent from "./components/BaseComponentMixin.jsx";
import token from "@/Services/token";
import api from "@/Services/api";

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// Import all CallCorp controls which will register them with Vue
import Accordion from "./components/Accordion.jsx";
import ACDDirectButton from "./components/ACDDirectButton.jsx";
import ActionService from "./components/ActionService.jsx";
import AdvancedHistory from "./components/AdvancedHistory.vue";
Vue.component("advanced-history", AdvancedHistory);
import AgGrid from "./components/AgGrid.jsx";
import ApexGeneralChart from "./components/ApexGeneralChart.vue";
Vue.component("apex-general-chart", ApexGeneralChart);
import AppleButton from "./components/AppleButton.jsx";
import AudioPlayer from "./components/AudioPlayer.vue";
Vue.component("audio-player", AudioPlayer);
import AutoComplete from "./components/AutoComplete.jsx";
import BadgeHandler from './components/BadgeHandler.jsx';
import BarChart from "./components/BarChart.jsx";
import BasicButton from "./components/BasicButton.jsx";
import BasicFileUpload from "./components/BasicFileUpload.vue";
Vue.component("basic-file-upload", BasicFileUpload);
import BasicForm from "./components/BasicForm.jsx";
import BasicGrid from "./components/BasicGrid.jsx";
import BasicInput from "./components/BasicInput";
import BasicLink from "./components/BasicLink.jsx";
import BasicSwitch from "./components/BasicSwitch.jsx";
import BasicTab from "./components/BasicTab.jsx";
import BasicToggle from "./components/BasicToggle.jsx";
import BasicTree from "./components/BasicTree.jsx";
import BasicPopupMenu from "./components/BasicPopupMenu.jsx";
import BroadcastListener from "./components/BroadcastListener.jsx";
import "./components/CallcorpPlayer";
import CardList from "./components/CardList.jsx";
import CardBrowser from "./components/CardBrowser.jsx";
import CheetahGrid from "./components/CheetahGrid.jsx";
import CircleChart from "./components/CircleChart.jsx";
import ComboBox from "./components/ComboBox.jsx";
import ControlContainer from "./components/ControlContainer.jsx";
import ControlDesigner from "./components/ControlDesigner.jsx";
import ConversationView from "./components/ConversationView.jsx";
import CssStyle from "./components/CssStyle.jsx";
import Dash from "./components/Dash.jsx";
import DateRangePicker from "./components/DateRangePicker.jsx";
import DefaultUnknown from "./components/DefaultUnknown.jsx";
import DebugHsmLog from "./components/DebugHsmLog.jsx";
import DesktopNotify from "./components/DesktopNotify.jsx";
import DocumentBootstrap from "./components/DocumentBootstrap.jsx";
import DropdownList from "./components/DropdownList.jsx";
import DropdownMultiSelect from "./components/DropdownMultiSelect.jsx";
import DynamicControlContainer from "./components/DynamicControlContainer.jsx";
import DynamicMenuList from "./components/DynamicMenuList.jsx";
import DynamicTab from "./components/DynamicTab.jsx";
import DynamicTabContent from "./components/DynamicTabContent.jsx";
import DynamicTree from "./components/DynamicTree.jsx";
import FastGrid from "./components/FastGrid.jsx";
import FlowChart from "./components/FlowChart.jsx";
import FileDiff from "./components/FileDiff.vue";
Vue.component("file-diff", FileDiff);
import ForEach from "./components/ForEach.jsx";
import ForEachSimple from "./components/ForEachSimple.jsx";
//import Hsm from './components/HSM.jsx';
import GoogleButton from "./components/GoogleButton.jsx";
import GoogleButton_v2 from "./components/GoogleButton_v2.jsx";
import HsmNew from "./components/HSM/HSM.jsx";
import HorizontalStack from "./components/HorizontalStack.jsx";
import HtmlEditor from "./components/HtmlEditor.jsx";
import HtmlTable from "./components/HtmlTable.jsx";
import Html from "./components/Html.jsx";
import Icon from "./components/Icon.jsx";
import IconPopupMenu from "./components/IconPopupMenu.jsx";
import IconStack from "./components/IconStack.jsx";
import IdleTimeout from "./components/IdleTimeout.jsx";
import IFrame from "./components/IFrame.jsx";
import Image from "./components/Image.jsx";
import InfiniteConversationView from "./components/InfiniteConversationView.jsx";
import InfiniteGrid from "./components/InfiniteGrid.jsx";
import Interval from "./components/Interval.jsx";
import Javascript from "./components/Javascript.jsx";
import LineChart from "./components/LineChart.vue";
Vue.component("line-chart", LineChart);
import MediaApproval from "./components/MediaApproval.vue";
import MenuFlowChart from "./components/MenuFlowChart.jsx";
Vue.component("media-approval", MediaApproval);
import MultiSelectList from "./components/MultiSelectList.vue";
Vue.component("multiselectlist", MultiSelectList);
import MultiSelectListItem from "./components/MultiSelectListItem.vue";
Vue.component("multiselectlistitem", MultiSelectListItem);
import NavigationDrawer from "./components/NavigationDrawer.jsx";
import Notification from "./components/Notification.jsx";
import PopupMenuButton from "./components/PopupMenuButton.jsx";
import ProgressBar from "./components/ProgressBar.jsx";
import ProgressCircle from "./components/ProgressCircle.jsx";
import RealTimeGrid from "./components/RealTimeGrid.jsx";
import RealTimeWidgetListener from "./components/RealTimeWidgetListener.jsx";
import ReCaptcha from "./components/ReCaptcha.jsx";
import RoosterEditor from "./components/RoosterEditor.vue";
Vue.component("rooster-editor", RoosterEditor);
import RTEventHandler from "./components/RTEventHandler.jsx";
import SAMLButton from "./components/SAMLButton.jsx";
import Search from "./components/Search.jsx";
import SelectList from "./components/SelectList.jsx";
import ServerEventListener from "./components/ServerEventListener.jsx";
import SlimGrid from "./components/SlimGrid.jsx";
import SoftPhone from "./components/SoftPhone.vue";
Vue.component("soft-phone", SoftPhone);
import SoundPlayer from "./components/SoundPlayer.jsx";
import SplitButton from "./components/SplitButton.jsx";
import SplitPanes from "./components/SplitPanes.jsx";
import Text from "./components/Text.jsx";
import TextEditor from "./components/TextEditor.jsx";
import Timeline from "./components/Timeline.jsx";
import Timeout from "./components/Timeout.jsx";
import TranslationContainer from "./components/TranslationContainer.jsx";
import TreeView from "./components/TreeView.jsx";
import UserControl from "./components/UserControl.jsx";
import VerticalStack from "./components/VerticalStack.jsx";
import Watcher from "./components/Watcher.jsx";
import WebRtcPhone from "./components/WebRtcPhone.jsx";
import Wizard from './components/Wizard.jsx';
import WizardButton from './components/WizardButton.jsx';
import YouTubeVideo from "./components/YouTubeVideo.jsx";

import ExperimentalTab from "./components/ExperimentalTab.jsx";
import HiddenContainer from "./components/HiddenContainer.jsx";

import OnEnterDefault from "./directives/OnEnterDefault";
import dayjs from "dayjs";

import RappidPlugin from './components/3rdParty/rappid';
import vuetify from '@/plugins/vuetify';

Vue.use(RappidPlugin);

export default Vue.component("start-application", {
    data: () => ({
        rootid: "",
        loading: true,
        access_token: null,
        refresh_token: null,
        control: null,
        CustomerID: null,
        date_value: {
            value: null,
        },
        global_vars: {
            api_busy_indicator: false,
            debug_reveal: false,
            Theme: vuetify.getTheme(),
        },
        service_vars: {
            tokenVars: {},
        },

        PrivateVariables: {
            AccessToken: { Value: null },
            RefreshToken: { Value: null },
            ExpiresIn: { Value: null },
            ExpiresAbsoluteTime: { Value: null },
            UserSecurityContextID: { Value: null },
            UserID: { Value: null },
            CustomerSecurityContextID: { Value: null },
            CustomerTextToSpeechVoice: { Value: null },
            CustomerAbbreviation: { Value: null },
            CustomerID: { Value: null },
            CustomerName: { Value: null },
            CustomerTimezone: { Value: null },
            CustomerRegionCode: { Value: null },
            CustomerPhoneNumberFormat: { Value: null },
            CustomerDefaultPromptLanguage: { Value: null },
            E911Compliance: { Value: null },
            CustomerRoles: { Value: null },
            BandID: { Value: null },
            ProviderType: { Value: null },
            UserName: { Value: null },
            Email: { Value: null}
        },
    }),
    created() {
        token.PrivateVariables = this.PrivateVariables;
        // Attempt to initialize the private variables from sessionStorage if found
        if (sessionStorage)
            for (let key in this.PrivateVariables) {
                if (typeof this.PrivateVariables[key] === 'object') {
                    const value = sessionStorage.getItem(key);
                    if (value !== undefined)
                        this.PrivateVariables[key].Value = value;
                }
            }

        window.addEventListener("keydown", (e) => {
            if (e.key == "Control" || e.key == "ControlLeft" || e.key == "ControlRight ") {
                this.global_vars.debug_reveal = true;
            }
        });
        window.addEventListener("keyup", (e) => {
            if (e.key == "Control" || e.key == "ControlLeft" || e.key == "ControlRight ") {
                this.global_vars.debug_reveal = false;
            }
        });
        window.addEventListener("event-bus-listener", (e) => {
            if (Array.isArray(e.detail.args))
                EventBus.$emit(e.detail.name, ...e.detail.args);
            else
                EventBus.$emit(e.detail.name, e.detail.args);
        });

        this.rootid = utils.generateUUID();
        EventBus.$on("Logout", this.performLogout);
        EventBus.$on("SelectedCustomerChanged", this.performLogout);

        this.date_value.value = new Date();
        careHelpfulFunctions.date_value = this.date_value;
        utils.global_variables = this.global_vars;
        utils.service_variables = this.service_vars;

        this.interval = setInterval(() => {
            this.date_value.value = new Date();
        }, 1000);

        this.$emit("start-application-created");

        this.getControl();
    },
    destroyed() {
        EventBus.$off("Logout", this.performLogout);
        EventBus.$off("SelectedCustomerChanged", this.performLogout);
    },
    async mounted() {
    },
    async mounted() {},
    props: {
        route: "",
        query: "",
        path: "",
    },
    computed: {
        ...computed,
    },
    methods: {
        ...methods,
        ...filters,
        performLogout() {
            this.rootid = utils.generateUUID();

            //clear any global variables as needed.
            utils.logout();
        },
        getControl: async function() {
            // Load the master user control to get everything running
            if (!this.control) {
                if (this.route) {
                    let route = this.route.substr(1);
                    if (route.includes('!')) route = route.replace('!', '');
                    if (route.startsWith("/")) route = route.substr(1);
                    if (route.includes("?")) route = route.substring(0, route.indexOf("?"));
                    if (route.startsWith("Control")) route = route.replace("Control", "");

                    if (!route)
                        route = 'v1/Vue/CallCenterPortal';
                    else if (route.startsWith('#'))
                        route = 'v1/Vue/CallCenterPortal';

                    this.control = await api.get("Document/UserControl/" + route);
                }
                else
                    this.control = await api.get("Document/UserControl/v1/Vue/CallCenterPortal");
            }
        },
    },
    render(h) {
        if (!this.control) return "Loading...";

        let DynamicControl = utils.getDynamicComponent(h, this.control);
        if (!DynamicControl) return <div>Control type of {this.control.ControlType} is unknown</div>;

        return (
            <DynamicControl
                key={this.rootid}
                ref="mainscreen"
                root={this}
                name={this.control.ControlData.Name}
                parentType="App"
                controlData={this.control.ControlData}
                type={this.control.ControlType}
                cacheControl={this.control.CacheControl}
                controlEvents={this.control.Events}
                asbase={true}
            ></DynamicControl>
        );
    },
});
