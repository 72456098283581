<template>
    <div id="app" style="display:flex; flex-grow:1">
        <div v-if="layout.items.length == 0" style="display: grid; grid-template-rows: 1fr 1fr 1fr; width: 100%">
            <v-icon style="font-size:20vh; top: 80px; color: #BDBDBD">mdi-chart-box-plus-outline</v-icon>
            <div style="display:flex; flex-direction:column; justify-content:center; align-items:center; flex-grow:1; gap:10px;">
                <div>
                <b style="font-size: 24px">
                    Start building your dashboard!
                </b>
            </div>
            <div v-if="!editing">Select <v-icon>mdi-pencil</v-icon> to begin adding cards from the library.</div>
            </div>
        </div>
        <dashboard v-if="layout.items.length > 0" :id="'dashExample'" style="display:flex; flex-direction:column; flex-grow:1; height:1px; width:1px">
            <dash-layout v-for="l in [layout]" v-bind="l" :debug="false" :key="l.breakpoint">
                <dash-item v-for="item in l.items" v-bind.sync="item" :key="item.id">
                    <v-card class="content" elevation="3">
                        <v-app-bar dense style="flex-grow: 0" flat color="rgba(0, 0, 0, 0)">
                            <v-app-bar-nav-icon color="black" v-show="false"></v-app-bar-nav-icon>

                            <v-toolbar-title class="text-h6 black--text pl-0" v-if="editing && item.$isEditing">
                                <v-text-field dense hide-details outlined class="pt-1 pb-1" v-model="item.title">
                                </v-text-field>
                            </v-toolbar-title>

                            <v-toolbar-title class="text-h6 black--text pl-0" v-else>
                                {{ item.title || ('Untitled ' + item.id) }}
                            </v-toolbar-title>

                            <v-btn small icon v-if="editing && !item.$isEditing" @click="editTitle($event, item)">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn small icon v-if="editing && item.$isEditing" @click="saveTitle($event, item)">
                                <v-icon small>mdi-check</v-icon>
                            </v-btn>
                            <v-btn small icon v-if="editing && item.$isEditing" @click="cancelEditTitle($event, item)">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn small icon v-if="editing" @click="removeThis($event, item)">
                                <v-icon color="error"> mdi-trash-can-outline </v-icon>
                            </v-btn>
                        </v-app-bar>
                        <div class="dashitem_content">
                            <slot name="content" v-bind="item">
                                This is the content
                            </slot>
                        </div>
                    </v-card>

                    <template v-slot:resizeBottomRight v-show="editing">
                        <svg width="1em"
                             height="1em"
                             viewBox="0 0 20 20"
                             focusable="false"
                             role="img"
                             alt="icon"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="#42b983"
                             class="b-icon bi bi-arrow-down-right mx-auto"
                             data-v-11c9e491>
                            <g data-v-11c9e491>
                                <path fill-rule="evenodd"
                                      d="M14 9.5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H9a.5.5 0 010-1h4.5V10a.5.5 0 01.5-.5z"
                                      clip-rule="evenodd"></path>
                                <path fill-rule="evenodd"
                                      d="M4.646 5.646a.5.5 0 01.708 0l9 9a.5.5 0 01-.708.708l-9-9a.5.5 0 010-.708z"
                                      clip-rule="evenodd"></path>
                            </g>
                        </svg>
                    </template>
                </dash-item>
            </dash-layout>
        </dashboard>
        <v-dialog :value="pickervisible" :max-width="dialog_width" scrollable>
            <v-card>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" :style="{backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px'}">
                    <v-toolbar-title class="title white--text pl-0">
                        Delete Card
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <busyIcon></busyIcon>

                    <v-btn color="white" icon @click="() => {this.pickervisible = false; this.selectedItem = -1;}">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-text class="mt-2">
                    Are you sure you want to delete?
                </v-card-text>

                <v-card-actions style="justify-content: flex-end;">
                    <v-btn color="primary" :elevation="0" @click="(e) => {this.$emit('remove', this.selectedItem); this.pickervisible = false;}">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";

    export default {
        name: "App",
        components: {
            Dashboard,
            DashLayout,
            DashItem
        },
        data: function () {
            return {
                old_title: '',
                tb_icon: 'mdi-dots-vertical',
                selectedItem: -1,
                dialog_width: 400,
                picker: null,
                pickervisible: false,
                isEditingTitle: false,
            };
        },
        props: {
            layout: null,
            editing: false,
            toolbar_icon: null,
        },
        created() {
            if (this.toolbar_icon)
                this.tb_icon = this.toolbar_icon;
        },
        methods: {
            removeThis(e, item) {
                this.pickervisible = true;
                this.selectedItem = item;
            },
            editTitle(e, item) {
                this.old_title = item.title;
                Vue.set(item, '$isEditing', true);
            },
            saveTitle(e, item) {
                item.$isEditing = false;
            },
            cancelEditTitle(e, item) {
                item.title = this.old_title;
                item.$isEditing = false;
            },
        }
    };
</script>

<style scoped>
    .content {
        height: 100%;
        width: 100%;
        text-align: center;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        /*border: 2px solid #42b983;
        background-color: #42b9833d;*/
    }

    .dashitem_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
        flex-grow: 1;
        overflow: hidden;
    }
</style>