import Vue from 'vue';
import Vuetify from 'vuetify';
import utils from '../../Shared/utils.jsx';
import Bar from '../../Assets/Bar.png';
import BarWithLine from '../../Assets/BarWithLine.png';
import BlankCard from '../../Assets/BlankCard.jpg';
import Donut from '../../Assets/Donut.png';
import Numeric from '../../Assets/Numeric.png';
import Table from '../../Assets/Table.png';

Vue.component('card-browser', {
    data: () => ({
        list: [],
        filter: '',
        selectedCardId: '',
        imageMap: {
            "Bar": Bar,
            "BarWithLine": BarWithLine,
            "Donut": Donut,
            "Numeric": Numeric,
            "Table": Table,
        }
    }),
    props: {
        confirm: null,
        cancel: null,
    },
    async created() {},
    mounted() {
        this.Refresh();
    },
    methods: {
        async Refresh() {
            var tempList = await utils.api.get('Apps/Platform/Schema/RealTime/v1/WidgetDefinition/ListAllPossibleByID');
            await this.getThumbnails(tempList);
            this.list = tempList;
            
        },
        returnControl(id, title) {
            this.confirm(id, title);
            this.selectedCardId = null;
        },
        search(value) {
            this.filter = value;
        },
        highlightCard(card) {
            this.selectedCardId = card.ID;
        },
        select() {
            if(this.list.length == 0)
                return;
            
            let selectedCard = this.list.find(c => c.ID == this.selectedCardId);
            if(!selectedCard?.UserControl)
                return;

            this.confirm(selectedCard);
            this.selectedCardId = null;
        },
        async getThumbnails(widgetDefs){
            widgetDefs.forEach(w => {
                w.thumbnail = this.imageMap[w.Image] || BlankCard;
            })
           
            return;
        },
        getCardStyle(card){
            let style = {};
            if(card.ID == this.selectedCardId)
            {
                style = {
                    border: "2px solid var(--v-primaryActive-base)"
                }
            }
            return style;
        },
    },
    render(h) {
        let items;
        if (this.list.length > 0){
            items = this.list.filter(c => ((!this.filter || c.Name.toLowerCase().includes(this.filter.toLowerCase())) && c.UserControl)).map(c => (
                <v-col cols="4" class="my-2">
                    <v-card 
                        class="content pa-2 mx-2" 
                        height="100%" hover on-click={() => this.highlightCard(c)} 
                        style={this.getCardStyle(c)}>
                        <v-img src={c.thumbnail}/>
                        <v-card-text class="text--primary" style="word-break: normal">{c.Name}</v-card-text>
                    </v-card>
                </v-col>
            ));
        }
        else
            items = <tr><td>Loading...</td></tr>;

        return (
            <v-card>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" style={{ backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px' }}>
                    <v-toolbar-title class="title white--text pl-0">
                        Add new card
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn elevation={0} color="white" icon on-click={this.cancel}>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    
                </v-app-bar>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <v-text-field
                        style="max-width: 300px;"
                        class="my-4 mx-5"
                        dense hide-details
                        clear-icon="mdi-close-circle"
                        clearable
                        placeholder="Quick Search"
                        prepend-inner-icon="mdi-magnify"
                        on-input={(value) => this.search(value)}
                    ></v-text-field>
                    <span style="flex-grow: 1;"></span>
                </div>
                
                <v-card-text style={{ overflow: 'auto'}}>
                    <v-container>
                        <v-row no-gutters>
                            {items}
                        </v-row>
                    </v-container>
                </v-card-text>

                
                <v-card-actions class="mt-2" style="justify-content: flex-end;">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="ma-1" elevation={0} on-click={() => this.select()}>Add</v-btn>
                </v-card-actions>
            </v-card>
        );
    }
});