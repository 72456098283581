import Vue from 'vue';
import BaseComponent from './BaseAgGridMixin.jsx';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import { AgGridVue } from "ag-grid-vue";
import DropdownFilter from './vuecontrols/agGridDropdownFilter.vue';
import _ from 'lodash';

const CellButton = {
    data: () => ({
        type: 'CellButton',
    }),
    created() {
        if (this.btn.Condition && !this.btn.$$Condition)
            this.btn.$$Condition = utils.compileExpression(this, this.btn.Condition, this.cd.RowModelName || 'row');

        if (this.btn.Icon && !this.btn.$$Icon)
            this.btn.$$Icon = utils.compile(this, this.btn.Icon, false, this.cd.RowModelName || 'row');

        if (this.btn.Color && !this.btn.$$Color)
            this.btn.$$Color = utils.compile(this, this.btn.Color, false, this.cd.RowModelName || 'row');

        if (this.btn.Tooltip && !this.btn.$$Tooltip)
            this.btn.$$Tooltip = utils.compile(this, this.btn.Tooltip, false, this.cd.RowModelName || 'row');
    },
    props: {
        root: null,
        btn: null,
        cd: null,
        scopeitems: null,
        controlscope: null,
        c_context: null,
        rowData: null,
    },
    methods: {
        rowMenuClick(e, data, btn, m) {
            //e.cancelBubble = true;
            //e.preventDefault();
            utils.executeAndCompileAllActions(m.Actions, { RowIndex: -1, Data: this.rowData }, this.c_context);
        },
        menuOpenClose(state, data, btn, m) {
            if (btn.OnMenuOpen)
                utils.executeAndCompileAllActions(btn.OnMenuOpen, { RowIndex: -1, Data: this.rowData, State: state }, this.c_context);
        },
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: -1, Data: this.rowData }, this.c_context);
        },
        //used in the grid to calculate the value of the command column.
        getEvaluationResults(button, context, data) {
            var condition = (button.$$Condition ? utils.evaluate(button.$$Condition, context, false, null, false, data) : null);

            return {
                condition: condition,
                color: (button.$$Color && condition ? utils.evaluate(button.$$Color, context, false, null, false, data) : null),
                icon: (button.$$Icon && condition ? utils.evaluate(button.$$Icon, context, false, null, false, data) : null)
            };
        }
    },
    render(h) {
        const data = this.rowData;
        const btn = this.btn;

        if (btn.$$Condition && !utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data))
            return null;

        let color;
        if (btn.$$Color)
            color = utils.evaluate(btn.$$Color, this.c_context, false, null, false, data);

        let icon;
        if (btn.$$Icon)
            icon = utils.evaluate(btn.$$Icon, this.c_context, false, null, false, data);

        let b;
        if (btn.Menu && btn.Menu.length > 0) {
            const scopedSlots = {
                activator: ({ on, attrs }) =>
                    <v-btn elevation={0}
                        v-show={!btn.$$Condition || utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data)}
                        icon small
                        {...{ on }}
                        {...{ attrs }}
                    >
                        <v-icon
                            style={{ color: color }}
                            small>
                            {icon}
                        </v-icon>
                    </v-btn>
            };

            const items = [];
            for (let i = 0; i < btn.Menu.length; i++) {
                const m = btn.Menu[i];
                if (m.Separator)
                    items.push(<v-divider></v-divider>);
                else {
                    if (m.DisplayExpression && !m.$$displayexpn) 
                        m.$$displayexpn = utils.compileExpression(this, m.DisplayExpression, this.cd.RowModelName || 'row');

                    if (m.$$displayexpn && !utils.evaluate(m.$$displayexpn, this.c_context, false, null, false, data))
                        return;

                    if (m.EnabledExpression && !m.$$enabledexpn)
                        m.$$enabledexpn = utils.compileExpression(this, m.EnabledExpression, this.cd.RowModelName || 'row');

                    if (m.Icon && !m.$$iconexpn)
                        m.$$iconexpn = utils.compile(this, m.Icon, this.cd.RowModelName || 'row');

                    if (m.Icon && m.IconColor && !m.$$iconcolorexpn)
                        m.$$iconcolorexpn = utils.compile(this, m.IconColor, this.cd.RowModelName || 'row');

                    let menuicon;
                    if (m.$$iconexpn) {
                        let iconcolor;
                        if (m.$$iconcolorexpn)
                            iconcolor = utils.evaluate(m.$$iconcolorexpn, this.c_context, false, null, false, data);

                        menuicon =
                            <v-list-item-icon>
                                <v-icon style={{ color: iconcolor }} small>{utils.evaluate(m.$$iconexpn, this.c_context, false, null, false, data)}</v-icon>
                            </v-list-item-icon>;
                    }

                    items.push(
                        <v-list-item
                            key={i}
                            disabled={(m.$$enabledexpn && !utils.evaluate(m.$$enabledexpn, this.c_context, false, null, false, data)) ? true : false}
                            on-click={(e) => this.rowMenuClick(e, data, btn, m)}>
                            {menuicon}
                            <v-list-item-content>
                                <v-list-item-title>{m.Title}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    );
                }
            }

            b = (
                <v-menu
                    offset-x
                    close-on-click={true}
                    close-on-content-click={true}
                    scopedSlots={scopedSlots}
                    on-input={(state) => this.menuOpenClose(state, data, btn)}
                >
                    <v-list dense>
                        <v-list-item-group>
                            {items}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        }
        else if (btn.Actions && btn.Actions.length > 0)
            b = (
                <v-btn elevation={0}
                    icon small
                    on-click={(e) => this.rowCommandClick(e, data, btn)}>
                    <v-icon
                        style={{ color: color }}
                        small>
                        {icon}
                    </v-icon>
                </v-btn>
            );
        else
            b = (
                <v-btn elevation={0}
                    style={{ cursor: 'default' }}
                    depressed
                    icon small>
                    <v-icon
                        style={{ color: color }}
                        small>
                        {icon}
                    </v-icon>
                </v-btn>
            );

        if (btn.$$Tooltip) {
            const tooltip = utils.evaluate(btn.$$Tooltip, this.c_context, false, null, false, data);
            if (tooltip)
                b = utils.generateTooltip(h, b, tooltip, 'right');
        }

        return b;
    }
};

const CellTemplate = {
    components: {
        CellButton,
    },
    data: () => ({
        root: null,
        type: 'CellTemplate',
        columnDef: null,
        scopeitems: null,
        controlscope: null,

        c_context: null,
        rowData: null,
    }),
    created() {
        // Copy parameters into fields so our interpolations will work
        this.root = this.params.callcorp.root;
        this.columnDef = this.params.callcorp.columnDef;
        this.scopeitems = this.params.callcorp.scopeitems;
        this.controlscope = this.params.callcorp.controlscope;
        this.c_context = this.params.callcorp.context;
        this.rowData = this.params.data; // <-- for reactivity
    },
    methods: {
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: -1, Data: this.params.data }, this.c_context);
        },
        rowLinkClick(e, data, actions) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(actions, { RowIndex: -1, Data: this.params.data }, this.c_context);
        },
        rowMenuClick(e, data, btn, m) {
            //e.cancelBubble = true;
            //e.preventDefault();
            utils.executeAndCompileAllActions(m.Actions, { RowIndex: -1, Data: this.params.data }, this.c_context);
        },
        menuOpenClose(state, data, btn, m) {
            if (btn.OnMenuOpen)
                utils.executeAndCompileAllActions(btn.OnMenuOpen, { RowIndex: -1, Data: this.params.data, State: state }, this.c_context);
        },
    },
    render(h) {
        const buttons = [];
        const cd = this.columnDef;
        if (cd.condition_expn)
            if (!utils.evaluate(cd.condition_expn, this.c_context, false, null, false, this.params.data))
                return null;

        for (let j = 0; j < this.columnDef.CommandButtons.length; j++) {
            const btn = this.columnDef.CommandButtons[j];

            buttons.push(
                <CellButton
                    btn={btn}
                    cd={cd}
                    root={this.root}
                    scopeitems={this.scopeitems}
                    controlscope={this.controlscope}
                    c_context={this.c_context}
                    rowData={this.rowData}
                >
                </CellButton>
            );
            /*
            if (btn.Condition && !btn.$$Condition)
                btn.$$Condition = utils.compileExpression(this, btn.Condition, cd.RowModelName || 'row');

            if (btn.Icon && !btn.$$Icon)
                btn.$$Icon = utils.compile(this, btn.Icon, false, cd.RowModelName || 'row');

            if (btn.Color && !btn.$$Color)
                btn.$$Color = utils.compile(this, btn.Color, false, cd.RowModelName || 'row');

            if (btn.Tooltip && !btn.$$Tooltip)
                btn.$$Tooltip = utils.compile(this, btn.Tooltip, false, cd.RowModelName || 'row');

            const data = this.rowData; // this.params.data;

            let color;
            if (btn.$$Color)
                color = utils.evaluate(btn.$$Color, this.c_context, false, null, false, data);

            let icon;
            if (btn.$$Icon)
                icon = utils.evaluate(btn.$$Icon, this.c_context, false, null, false, data);

            let b;
            if (btn.Menu && btn.Menu.length > 0) {
                const scopedSlots = {
                    activator: ({ on, attrs }) =>
                        <v-btn elevation={0}
                            v-show={!btn.$$Condition || utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data)}
                            icon small
                            {...{ on }}
                            {...{ attrs }}
                        >
                            <v-icon
                                style={{ color: color }}
                                small>
                                {icon}
                            </v-icon>
                        </v-btn>
                };

                const items = [];
                for (let i = 0; i < btn.Menu.length; i++) {
                    const m = btn.Menu[i];
                    if (m.Separator)
                        items.push(<v-divider></v-divider>);
                    else {
                        if (m.EnabledExpression && !m.$$enabledexpn)
                            m.$$enabledexpn = utils.compileExpression(this, m.EnabledExpression, cd.RowModelName || 'row');

                        if (m.Icon && !m.$$iconexpn)
                            m.$$iconexpn = utils.compile(this, m.Icon, cd.RowModelName || 'row');

                        if (m.Icon && m.IconColor && !m.$$iconcolorexpn)
                            m.$$iconcolorexpn = utils.compile(this, m.IconColor, cd.RowModelName || 'row');

                        let menuicon;
                        if (m.$$iconexpn) {
                            let iconcolor;
                            if (m.$$iconcolorexpn)
                                iconcolor = utils.evaluate(m.$$iconcolorexpn, this.c_context, false, null, false, data);

                            menuicon =
                                <v-list-item-icon>
                                <v-icon style={{ color: iconcolor }} small>{utils.evaluate(m.$$iconexpn, this.c_context, false, null, false, data)}</v-icon>
                                </v-list-item-icon>;
                        }

                        items.push(
                            <v-list-item
                                key={i}
                                disabled={(m.$$enabledexpn && !utils.evaluate(m.$$enabledexpn, this.c_context, false, null, false, data)) ? true : false}
                                on-click={(e) => this.rowMenuClick(e, data, btn, m)}>
                                {menuicon}
                                <v-list-item-content>
                                    <v-list-item-title>{m.Title}</v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>
                        );
                    }
                }

                b = (
                    <v-menu
                        offset-x
                        close-on-click={true}
                        close-on-content-click={true}
                        scopedSlots={scopedSlots}
                        on-input={(state) => this.menuOpenClose(state, data, btn)}
                    >
                        <v-list dense>
                            <v-list-item-group>
                                {items}
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                );
            }
            else if (btn.Actions && btn.Actions.length > 0)
                b = (
                    <v-btn elevation={0}
                        v-show={!btn.$$Condition || utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data)}
                        icon small
                        on-click={(e) => this.rowCommandClick(e, data, btn)}>
                        <v-icon
                            style={{ color: color }}
                            small>
                            {icon}
                        </v-icon>
                    </v-btn>
                );
            else
                b = (
                    <v-btn elevation={0}
                        style={{ cursor: 'default' }}
                        depressed
                        v-show={!btn.$$Condition || utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data)}
                        icon small>
                        <v-icon
                            style={{ color: color }}
                            small>
                            {icon}
                        </v-icon>
                    </v-btn>
                );

            if (btn.$$Tooltip) {
                const tooltip = utils.evaluate(btn.$$Tooltip, this.c_context, false, null, false, data);
                if (tooltip)
                    b = utils.generateTooltip(h, b, tooltip, 'right');
            }

            buttons.push(b);
            */
        }
        return <span>{buttons}</span>;
    }
};

Vue.component('fast-grid', {
    mixins: [BaseComponent],
    components: {
        AgGridVue,
        CellTemplate,
        DropdownFilter,
    },
    data: () => ({
        column_defs_ready: false,
    }),
    created() {
        if (this.controlData.EnableDynamicColumns && this.controlData.DynamicColumns) {
            this.dynamicColumns = utils.compileObject(this, this.controlData.DynamicColumns, 'rows');
            this.columnTemplates = careHelpfulFunctions.toLookup(this.controlData.ColumnTemplates, 'Name');
        }

        if (this.controlData.SelectOptions && this.controlData.SelectOptions.Enable && this.controlData.SelectOptions.SelectableExpression)
            this.selectableexpn = utils.compileExpression(this, this.controlData.SelectOptions.SelectableExpression, 'row');

            this.prepareColumnDefs();
    },
    computed: {
        dataSource: function () {
            return this.modelfunc ? this.modelfunc() : null;
        },
        dynamicColumnsValue: function () {
            return utils.evaluateObject(this.dynamicColumns, this, this.dataSource);
        },
    },
    watch: {
        dataSource: function (newVal, oldVal) {
            if (!this.column_defs_ready) {
                this.prepareColumnDefs();
            }
        }
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        async Refresh(clearSelectedRows) {
            this.internalRefresh(clearSelectedRows);
        },

        prepareColumns() {
            // Generate compiled expressions for all interpolations
            for (let i = 0; i < this.columnDefs.length; i++) {
                const cd = this.columnDefs[i];

                if (cd.Condition)
                    cd.condition_expn = utils.compileExpression(this, cd.Condition, cd.RowModelName || 'row');

                for (let j = 0; j < cd.CommandButtons.length; j++) {
                    const btn = cd.CommandButtons[j];

                    if (btn.Condition)
                        btn.$$Condition = utils.compileExpression(this, btn.Condition, cd.RowModelName || 'row');

                    if (btn.Icon)
                        btn.$$Icon = utils.compile(this, btn.Icon, false, cd.RowModelName || 'row');

                    if (btn.Color)
                        btn.$$Color = utils.compile(this, btn.Color, false, cd.RowModelName || 'row');

                    if (btn.Tooltip)
                        btn.$$Tooltip = utils.compile(this, btn.Tooltip, false, cd.RowModelName || 'row');
                }
            }
        },

        async prepareColumnDefs() {
            const h = this.$createElement;
            let coldefs;
            if (!this.controlData.EnableDynamicColumns && this.columnDefs && this.columnDefs.length > 0) {
                coldefs = [];

                if (this.controlData.SelectOptions.Enable) {
                    let checkboxVisible = true;
                    if (this.selectableexpn)
                        checkboxVisible = ({ data }) => utils.evaluate(this.selectableexpn, this, false, null, false, data);

                    coldefs.push({
                        headerName: '',
                        sortable: false,
                        resizable: true,
                        checkboxSelection: checkboxVisible,
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        pinned: 'left',
                        width: 50,
                    });
                }

                for (let i = 0; i < this.columnDefs.length; i++) {
                    const cd = this.columnDefs[i];

                    utils.debug(`pushing regular coldef for field:${cd.Field} as name:${cd.Name}`);
                    coldefs.push(await this.generateColumnDef(h, cd, cd, i, cd.Field, cd.Name, cd.DisplayName, false));
                }
            }
            else if (this.controlData.EnableDynamicColumns && this.dynamicColumns && this.columnTemplates) {
                coldefs = [];

                if (this.controlData.SelectOptions.Enable) {
                    let checkboxVisible = true;
                    if (this.selectableexpn)
                        checkboxVisible = ({ data }) => utils.evaluate(this.selectableexpn, this, false, null, false, data);

                    coldefs.push({
                        headerName: '',
                        sortable: false,
                        resizable: true,
                        checkboxSelection: checkboxVisible,
                        headerCheckboxSelection: true,
                        pinned: 'left',
                        width: 50,
                    });
                }

                const dc = this.dynamicColumnsValue;
                if (dc && Array.isArray(dc)) {
                    // Read this.columns_visible from local storage if present
                    const cv = this.System.LocalStorage(this.storageKey + '-columns_visible');
                    if (cv)
                        Vue.set(this, 'columns_visible', JSON.parse(cv));

                    if (!cv || this.columns_visible.length !== dc.length)
                        this.columns_visible = dc.map(d => d.default_visible === undefined ? true : d.default_visible);

                    this.init_columns_visible = [...this.columns_visible];

                    for (let i = 0; i < dc.length; i++) {
                        const cd = this.columnTemplates[dc[i].template];
                        if (!cd) continue;

                        utils.debug(`pushing templated coldef for field:${dc[i].field}, template:${dc[i].template}`);
                        coldefs.push(await this.generateColumnDef(h, cd, dc[i], i, dc[i].field, '', dc[i].caption, true, dc[i].default_visible === undefined ? true : dc[i].default_visible));
                    }
                }
            }

            this.column_defs = coldefs;
            this.column_defs_ready = true;
        },
        async generateColumnDef(h, cd, col, index, field, name, caption, istemplate, default_visible) {
            // cd is the columnDef - which, for a template, is reused for multiple columns.
            // col is either the columnDef or the dynamicColumn - it is passed as a place to cache
            //     compiled expressions for the column since it needs to be distinct per column even
            //     for dynamic columns. For non-templated columns, it is the same as cd.

            if (!cd.visibleexpr && cd.Visible && typeof cd.Visible === 'string') {
                cd.visibleexpr = utils.compileObject(this, cd.Visible);
            }

            let field_extractor = col.field_extractor;
            if (!field_extractor)
                field_extractor = new Function('f', `return f.${field};`);

            // Cache this to prevent regular recompile
            //if (!istemplate)
            col.field_extractor = field_extractor;

            if (cd.Condition && !col.condition_expn)
                col.condition_expn = utils.compileExpression(this, cd.Condition, [cd.RowModelName || 'row', cd.ItemModelName || 'item']);
            
            let cellFilterFunc;

            if (cd.ActionOnlyColumn) {
                const def = ({
                    headerName: cd.DisplayName,
                    resizable: true,
                    cellRendererFramework: 'CellTemplate',
                    cellRendererParams: {
                        callcorp: {
                            root: this.root,
                            columnDef: cd,
                            scopeitems: this.scopeitems,
                            controlscope: this.controlscope,
                            context: this,
                        }
                    },
                    valueGetter: (node) => {
                        var callcorp = node.colDef.cellRendererParams.callcorp;
                        var colDef = callcorp?.columnDef;

                        let data;

                        if (colDef) {
                            data = {
                                colData: col.condition_expn ? utils.evaluate(col.condition_expn, callcorp.context, false, null, false, node.data) : null,
                                colActionData: colDef.CommandButtons?.map(button => CellButton.methods.getEvaluationResults(button, callcorp.context, node.data))
                            };
                        } else {
                            data = {
                                colData: null,
                                colActionData: null
                            }
                        }

                        return data;
                    },
                    equals: (l, r) => {
                        return _.isEqual(l, r);
                    }
                });
              if (cd.Width > 0) {
                def.width = parseInt(cd.Width);
              }

              return def;
            }
            else {
                let dataType;
                if (cd.DataType && cd.DataType != 'default')
                    dataType = cd.DataType;

                let valueGetter;
                if (cd.Value && !col.value_expn)
                    col.value_expn = utils.compile(this, cd.Value, false, [cd.RowModelName || 'row', cd.ItemModelName || 'item']);

                if (col.condition_expn && col.value_expn)
                    valueGetter = (node) => utils.evaluate(col.condition_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) ? utils.evaluate(col.value_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) : '';
                else if (col.value_expn)
                    valueGetter = (node) => utils.evaluate(col.value_expn, this, false, null, false, [node.data, field_extractor(node.data)], true);
                //else if (cd.condition_expn)
                //    valueGetter = (node) => utils.evaluate(cd.condition_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) ? field_extractor(node.data) : '';

                let valueFormatter;
                if (cd.CellFilter && !col.cellfilter_expn) {
                    const filter = `{{ row.${field} | ${cd.CellFilter} }}`;
                    col.cellfilter_expn = utils.compile(this, filter, false, ['row', cd.ItemModelName || 'item']);
                }
                if (col.cellfilter_expn && col.condition_expn)
                    valueFormatter = (node) => utils.evaluate(col.condition_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) ? utils.evaluate(col.cellfilter_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) : '';
                else if (col.cellfilter_expn && !col.condition_expn)
                    valueFormatter = (node) => utils.evaluate(col.cellfilter_expn, this, false, null, false, [node.data, field_extractor(node.data)], true);
                else if (col.condition_expn && !valueGetter)
                    valueFormatter = (node) => utils.evaluate(col.condition_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) ? field_extractor(node.data) : '';

                if (!valueGetter && !valueFormatter) {
                    const code = `return typeof data === 'object' ? JSON.stringify(data) : data;`;
                    const func = new Function('data', code);
                    valueFormatter = (node) => {
                        try { return func(field_extractor(node.data)); } catch (e) {
                            utils.warn(`Grid cell failed to evaluate ${code}`, e); return '';
                        }
                    };
                }

                const cdef = {
                    headerName: caption || name || field,
                    field: field,
                    sortable: cd.Sortable,
                    filter: true,
                    resizable: true,
                    comparator: this.colSortComparator,
                    hide: !this.init_columns_visible[index]
                };
                
                if (cd.Lookup && cd.Lookup.SourceType != 'None') {
                    // Lookups can't use a value getter or formatter, it overrides the lookup
                    valueGetter = null;
                    valueFormatter = null;

                    let displayExpr;
                    switch (cd.Lookup.DisplayType) {
                        case 'Field':
                            displayExpr = cd.Lookup.DisplayField;
                            break;
                        case 'Expression':
                            const displayfunc = utils.compileExpression(this, cd.Lookup.DisplayExpression, cd.RowModelName);
                            displayExpr = (item) => utils.evaluate(displayfunc, this, false, null, false, item);
                            break;
                    }
                    let valueExpr;
                    switch (cd.Lookup.ValueType) {
                        case 'Field':
                            valueExpr = cd.Lookup.ValueField;
                            break;
                        case 'Expression':
                            const valuefunc = utils.compileExpression(this, cd.Lookup.ValueExpression, cd.RowModelName);
                            valueExpr = (item) => utils.evaluate(valuefunc, this, false, null, false, item);
                            break;
                    }

                    const transformer = (rows) => Object.fromEntries(rows.map(r => {
                        const value = typeof valueExpr === 'string' ? r[valueExpr] : valueExpr(r);
                        const display = typeof displayExpr === 'string' ? r[displayExpr] : displayExpr(r);
                        return [value, display];
                    }));

                    let dataSource;
                    let lookup;
                    switch (cd.Lookup.SourceType) {
                        case 'Raw':
                            if (!cd.Lookup.dataSourceExpr)
                                cd.Lookup.dataSourceExpr = utils.compileObject(this, cd.Lookup.SourceRaw);

                            dataSource = utils.evaluateObject(cd.Lookup.dataSourceExpr, this);

                            // Transform the array into an object
                            // cdef.refData = transformer(dataSource);
                            lookup = transformer(dataSource);
                            valueGetter = (node) => lookup[field_extractor(node.data)];
                            break;

                        case 'URL':
                            cdef.refData = {};

                            if (!cd.Lookup.dataSourceUrl)
                                cd.Lookup.dataSourceUrl = utils.compile(this, cd.Lookup.SourceURL);

                            let url = utils.evaluate(cd.Lookup.dataSourceUrl, this);
                            let res = await utils.api.get(url, false, cd.Lookup.CacheResults);

                            utils.debug(`Lookup returned: ${JSON.stringify(res)}`);

                            //cdef.refData = transformer(res);
                            lookup = transformer(res);

                            utils.debug(`Transformed to: ${JSON.stringify(cdef.refData)}`);
                            break;
                    }

                    if (lookup) {
                        valueGetter = (node) => lookup[field_extractor(node.data)];
                        valueFormatter = valueGetter;

                        cdef.valueGetter = valueGetter;
                    }

                    // Enterprise feature
                    //cdef.filter = 'agSetColumnFilter'; 
                    //cdef.cellEditor = 'select';
                    //cdef.cellEditorParams = { values: Object.keys(cdef.refData) };

                    // cdef.refData = { key: value, ... }
                }

                if (valueFormatter)
                    cdef.valueFormatter = valueFormatter;

                if (valueGetter)
                    cdef.valueFormatter = valueGetter;
                    //cdef.valueGetter = valueGetter;

                if (cd.SortAndFilterTemplate) {
                    if (!col.sorttemplate_expn)
                        col.sorttemplate_expn = utils.compile(this, cd.SortAndFilterTemplate, false, [cd.RowModelName || 'row', cd.ItemModelName || 'item']);

                    if (col.condition_expn)
                        cdef.valueGetter = (node) => utils.evaluate(col.condition_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) ? utils.evaluate(col.sorttemplate_expn, this, false, null, false, [node.data, field_extractor(node.data)], true) : '';
                    else
                        cdef.valueGetter = (node) => utils.evaluate(col.sorttemplate_expn, this, false, null, false, [node.data, field_extractor(node.data)], true);
                }

                if (cd.Align == 'Right')
                    cdef.type = 'rightAligned';

                if (cd.HeaderType && cd.HeaderType != 'DefaultFilter') {
                    cdef.filter = cd.HeaderType;
                    cdef.filterParams = {
                        label: `Select Filter for ${cdef.DisplayName || cdef.name || cdef.field}:`,
                    };
                }

                if (false && cd.LinkActions && cd.LinkActions.length > 0) {
                    const scopedSlots = {
                        default: ({ data }) =>
                            <v-btn elevation={0} class="text-capitalize"
                                small
                                text
                                on-click={(e) => this.rowLinkClick(e, data, cd.LinkActions)}
                            >
                                {cellFilterFunc(data.row.data)}
                            </v-btn>
                    };

                    const buttons = [];
                    buttons.push(
                        <DxButton scopedSlots={scopedSlots}>
                        </DxButton>
                    );
                    return (
                        <DxColumn
                            type="buttons"
                            name={name || field}
                            caption={caption || name || field}
                            data-field={field}
                            data-type={dataType}
                            calculate-cell-value={cellFilterFunc}
                            calculate-sort-value={cd.sortandfilterfunc}
                            visible={this.columns_visible[index]}
                        >
                            {buttons}
                        </DxColumn>
                    );
                }
                else
                    return cdef;
            }
        },

        getGridMenuColumnList(h, menus) {
            if (!this.controlData.EnableDynamicColumns && this.columnDefs && this.columnDefs.length > 0) {
                for (let i = 0; i < this.columnDefs.length; i++) {
                    const col = this.columnDefs[i];
                    if (col.ActionOnlyColumn) continue;

                    menus.push(
                        <v-list-item key={`col_${i}`} on-click={() => this.toggleColumnVisible(col.Field || col.Name, i)}>
                            <v-list-item-icon>
                                <v-icon small>{this.columns_visible[i] ? 'mdi-check' : ''}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{col.DisplayName || col.Name || col.Field}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    );
                }
            }
            else if (this.controlData.EnableDynamicColumns && this.dynamicColumns && this.columnTemplates) {
                const dc = this.dynamicColumnsValue;
                if (dc && Array.isArray(dc)) {
                    for (let i = 0; i < dc.length; i++) {
                        const cd = this.columnTemplates[dc[i].template];
                        if (!cd || !dc[i].field) continue;

                        //utils.debug(`pushing templated coldef for field:${dc[i].field}, template:${dc[i].template}`);
                        //coldefs.push(await this.generateColumnDef(h, cd, i, dc[i].field, '', dc[i].caption, true, dc[i].default_visible === undefined ? true : dc[i].default_visible));

                        menus.push(
                            <v-list-item key={`col_${i}`} on-click={() => this.toggleColumnVisible(dc[i].field, i)}>
                                <v-list-item-icon>
                                    <v-icon small>{this.columns_visible[i] ? 'mdi-check' : ''}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{dc[i].caption || dc[i].field}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        );
                    }
                }
            }
        },

        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: data.rowIndex, Data: data.row.data }, this);
        },
        rowLinkClick(e, data, actions) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(actions, { RowIndex: data.rowIndex, Data: data.row.data }, this);
        },
        rowMenuClick(e, data, btn, m) {
            //e.cancelBubble = true;
            //e.preventDefault();
            utils.executeAndCompileAllActions(m.Actions, { RowIndex: data.rowIndex, Data: data.row.data }, this);
        },
        menuOpenClose(state, data, btn, m) {
            if (btn.OnMenuOpen)
                utils.executeAndCompileAllActions(btn.OnMenuOpen, { RowIndex: data.rowIndex, Data: data.row.data, State: state }, this);
        },
    },
    props: {},
    render() {
        if (!this.todisplay || !this.column_defs_ready)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <div
                key={this.unique_id + '_main'}
                v-show={this.isvisible}
                class={{ 'c-FastGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ag-theme-alpine': true }}
                style={style}
            >
                <v-overlay value={this.loading} absolute={true}>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        size={64}
                    ></v-progress-circular>
                </v-overlay>

                {this.gridHeaders}
                {/*{this.pagination ? 'Paging' : 'Normal'}*/}
                {/*<div style="display: flex; flex-direction: column; border-width: 1px; border-style: solid; border-color: gray; border-radius: 3px; margin: 2px; padding: 2px;">*/}
                {/*    <span>{this.modelurlvalue}</span>*/}
                {/*    <span>-</span>*/}
                {/*    <span>dataSource: {this.dataSource ? (this.dataSource.length + ' record(s)') : 'empty'}</span>*/}
                {/*    <v-btn elevation={0} x-small on-click={(e) => this.modelfunc = null}>Clear DS</v-btn>*/}
                {/*</div>*/}
                <ag-grid-vue
                    key={this.unique_id}
                    id={this.datatable_id}
                    class={{ 'ag-theme-alpine': true }}
                    style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
                    on-grid-ready={this.onGridReady}
                    on-first-data-rendered={(e) => this.onFirstDataRendered(e)}
                    on-selection-changed={(e) => this.rowSelectionChanged(e)}
                    columnDefs={this.column_defs}
                    rowData={this.dataSource}
                    rowSelection={this.controlData.SelectOptions.MultiSelect ? "multiple" : null}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    quickFilterText={this.searchText}
                    suppressScrollOnNewData={true}
                    suppressRowClickSelection={true}
                    suppressDragLeaveHidesColumns={true}
                    suppressCellSelection={true}
                    pagination={this.pagination}
                    paginationAutoPageSize={this.pagination}
                    getRowClass={this.getRowClass}
                    onFilterChanged={this.columnFilterChanged}
                    on-sort-changed={this.onSaveGridSortState}
                    on-column-visible={this.onSaveGridColumnState}
                    on-column-pinned={this.onSaveGridColumnState}
                    on-column-resized={this.onSaveGridColumnState}
                    on-column-moved={this.onSaveGridColumnState}
                    on-column-row-group-changed={this.onSaveGridColumnState}
                >
                </ag-grid-vue>
            </div>
        );

        /*

         * */


        //rowHeight={26}

        //const style = {
        //    overflow: "auto",
        //    display: "flex",
        //    flexDirection: "column",
        //    ...this.sizeStyle,
        //    maxHeight: "1000px",
        //};

        //let scopedSlots = {};
        //this.getHeaderControls(scopedSlots);

        //return (
        //    <DxDataGrid
        //        class={{ 'c-FastGrid': true, [`c-name-${this.name || 'unnamed'}`]: true }}
        //        style={style}
        //        data-source={this.dataSource}
        //        key-expr={this.controlData.KeyFields}
        //        allow-column-reordering={true}
        //        allow-column-resizing={true}
        //        column-resizing-mode="widget"
        //        column-auto-width={this.controlData.SizeColumnnsToContent}
        //        show-borders={true}
        //        show-row-lines={false}
        //        show-column-lines={true}
        //        row-alternation-enabled={true}
        //        word-wrap-enabled={false}
        //        scopedSlots={scopedSlots}
        //        selected-row-keys={this.selectedRowKeys}
        //        on-toolbar-preparing={(e) => this.onToolbarPreparing(e)}
        //        on-selection-changed={(s) => this.onSelectionChanged(s)}
        //    >
        //        <DxFilterRow visible={this.showFilter} />
        //        <DxSearchPanel visible={this.controlData.EnableFilter} />
        //        <DxScrolling mode={this.scrollMode} />
        //        {this.paging}
        //        {this.pager}
        //        <DxLoadPanel enabled={true} />
        //        <DxStateStoring enabled={true} type="custom" custom-load={this.loadState} custom-save={this.saveState} storage-key={this.storageKey} />
        //        {selector}

        //        {this.coldefs}
        //    </DxDataGrid>
        //);

        // <DxStateStoring enabled={true} type="localStorage" storage-key={this.storageKey} />
    }
});