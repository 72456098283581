<template>   
    <div style="margin-bottom: 0; overflow: auto; width: 100%; height: 100%; padding: 5px; position:relative;" :style="{ minHeight: minHeight }" class="d-flex flex-column flex-grow-1">
        <div class="callcorp-editor d-flex flex-column flex-grow-1" :class="{ 'readonly': readonly }">
            <div class="editor-wrapper editor d-flex flex-column flex-grow-1">
                <div class="composer-wrapper d-flex flex-column flex-grow-1" style="min-height: 41px;">
                    <div ref="contentdiv" :disabled="readonly" class="editor__content" @click="getFormatState()" @keyup.enter.exact.prevent.stop="sendEnter" @keyup="editorKeyUp" @keydown.ctrl.90.exact.stop.prevent="undoSnapshot" @keydown.ctrl.shift.90.exact.stop.prevent="redoSnapshot" :style="{'max-height': this.maxHeight }"></div>
                </div>
                <div v-if="!readonly" class="menu-bar-container" style="border-top: #c0c0c0 1px solid;">
                    <div class="menubar">
                        <div class="d-flex" style="overflow: auto;">
                            <div
                                v-if="!disableFormatting"
                                class="d-flex" style="overflow: auto; gap: 5px; align-items: center;"
                            >
                                <div style="display:flex">
                                    <v-btn
                                        icon
                                        medium
                                        @click="undoSnapshot()"
                                        color="black"
                                        tile
                                        :title="Translate('Undo')"
                                    >
                                        <v-icon>mdi-arrow-u-left-top</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="redoSnapshot()"
                                        color="black"
                                        tile
                                        :title="Translate('Redo')"
                                    >
                                        <v-icon>mdi-arrow-u-right-top</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="formatPaint()"
                                        color="black"
                                        tile
                                        :title="Translate('Format Painter')"
                                    >
                                        <v-icon>mdi-format-paint</v-icon>
                                    </v-btn>
                                    
                                    <v-btn
                                        icon
                                        medium
                                        @click="clearFormat()"
                                        color="black"
                                        tile
                                        :title="Translate('Clear Format')"
                                    >
                                        <v-icon>mdi-format-clear</v-icon>
                                    </v-btn>
                                </div>

                                <div style="height: 25px">
                                    <v-divider vertical />
                                </div>
                                <div>
                                    <DropdownMenu 
                                        :options="headingOptions"
                                        :value="roosterModel.headingLevel"
                                        @input="(value) => setHeadingLevel(value)"
                                        show-activator
                                        max-height="200"
                                    />
                                </div>

                                <div style="height: 25px">
                                    <v-divider vertical />
                                </div>

                                <div>
                                    <DropdownMenu 
                                        :options="fontFamilyOptions"
                                        :value="roosterModel.fontName[0]"
                                        @input="(value) => setFontName(value)"
                                        show-activator
                                        max-height="200"
                                    />
                                </div>
                                
                                <div style="height: 25px">
                                    <v-divider vertical />
                                </div>

                                <div>
                                    <DropdownMenu 
                                        :options="fontSizeOptions"
                                        :value="roosterModel.fontSize[0]"
                                        @input="(value) => setFontSize(value)"
                                        show-activator
                                        max-height="200"
                                    />
                                </div>
                                
                                <div style="height: 25px">
                                    <v-divider vertical />
                                </div>

                                <div style="display:flex">
                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleBold()"
                                        color="black"
                                        :class="{ 'is-active': roosterModel.isBold }"
                                        tile
                                        :title="Translate('Bold')"
                                    >
                                        <v-icon>mdi-format-bold</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleItalic()"
                                        color="black"
                                        :class="{ 'is-active': roosterModel.isItalic }"
                                        tile
                                        :title="Translate('Italicize')"
                                    >
                                        <v-icon>mdi-format-italic</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleUnderline()"
                                        color="black"
                                        :class="{ 'is-active': roosterModel.isUnderline }"
                                        tile
                                        :title="Translate('Underline')"
                                    >
                                        <v-icon>mdi-format-underline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleStrikethrough()"
                                        color="black"
                                        :class="{ 'is-active': roosterModel.isStrikeThrough }"
                                        tile
                                        :title="Translate('Strikethrough')"
                                    >
                                        <v-icon>mdi-format-strikethrough</v-icon>
                                    </v-btn>

                                    <v-menu offset-y
                                        close-on-content-click>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                medium
                                                v-bind="attrs"
                                                v-on="on"
                                                color="transparent"
                                                elevation="0"
                                                tile
                                                style="padding: 0;"
                                                min-width="40"
                                            >
                                                <v-icon :color="roosterModel.textColor.toLowerCase() === 'var(--v-white-base)' ? 'black' : roosterModel.textColor">mdi-format-color-text</v-icon>
                                                <v-icon color="black">mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-color-picker
                                            dot-size="25"
                                            swatches-max-height="150"
                                            hide-mode-switch
                                            hide-inputs
                                            :value="roosterModel.textColor"
                                            @input="(value) => setTextColor(value)"
                                        ></v-color-picker>
                                    </v-menu>

                                    <v-menu offset-y
                                        close-on-content-click>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                medium
                                                v-bind="attrs"
                                                v-on="on"
                                                color="transparent"
                                                elevation="0"
                                                tile
                                                style="padding: 0;"
                                                min-width="40"
                                            >
                                                <v-icon :color="roosterModel.backgroundColor.toLowerCase() === '#ffffff' ? 'black' : roosterModel.backgroundColor" class="rooster-command-bar-icon">mdi-format-color-fill</v-icon>
                                                <v-icon color="black">mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-color-picker
                                            dot-size="25"
                                            swatches-max-height="150"
                                            hide-mode-switch
                                            hide-inputs
                                            :value="roosterModel.backgroundColor"
                                            @input="(value) => setBackgroundColor(value)"
                                        ></v-color-picker>
                                    </v-menu>
                                </div>

                                <div style="height: 25px">
                                    <v-divider vertical />
                                </div>

                                <div style="display:flex">
                                    <v-btn
                                        icon
                                        medium
                                        @click="setAlignment('left')"
                                        color="black"
                                        tile
                                        :title="Translate('Left align')"
                                    >
                                        <v-icon>mdi-format-align-left</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="setAlignment('center')"
                                        color="black"
                                        tile
                                        :title="Translate('Center align')"
                                    >
                                        <v-icon>mdi-format-align-center</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="setAlignment('right')"
                                        color="black"
                                        tile
                                        :title="Translate('Right align')"
                                    >
                                        <v-icon>mdi-format-align-right</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleBullet()"
                                        color="black"
                                        tile
                                        :title="Translate('Bulletted list')"
                                    >
                                        <v-icon>mdi-format-list-bulleted</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleNumbering()"
                                        color="black"
                                        tile
                                        :title="Translate('Numbered list')"
                                    >
                                        <v-icon>mdi-format-list-numbered</v-icon>
                                    </v-btn>

                                
                                    <v-btn
                                        icon
                                        medium
                                        @click="setIndentation('outdent')"
                                        color="black"
                                        tile
                                        :title="Translate('Decrease indent')"
                                    >
                                        <v-icon>mdi-format-indent-decrease</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="setIndentation('indent')"
                                        color="black"
                                        tile
                                        :title="Translate('Increase indent')"
                                    >
                                        <v-icon>mdi-format-indent-increase</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleCode()"
                                        color="black"
                                        tile
                                        :title="Translate('Code block')"
                                    >
                                        <v-icon>mdi-code-tags</v-icon>
                                    </v-btn>

                                    <v-btn
                                        icon
                                        medium
                                        @click="toggleQuote()"
                                        color="black"
                                        tile
                                        :title="Translate('Quote')"
                                    >
                                        <v-icon>mdi-format-quote-close</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div v-if="!disableImagePicker">
                                <input ref="insertimage" @change="processInsertImageChange" style="display:none;" type="file" id="insert-image" accept="image/png, image/jpeg, image/jpg" :files="fileList" multiple/>
                                <v-btn
                                    icon
                                    medium
                                    @click="$refs.insertimage.click()"
                                    color="black"
                                    tile
                                    :title="Translate('Insert image')"
                                >
                                    <v-icon>mdi-camera-image</v-icon>
                                </v-btn>
                            </div>
                            <div ref="emojibutton">
                                <v-btn
                                    ref="emojibutton"
                                    icon
                                    medium
                                    @click.stop.prevent="showEmojiPicker = !showEmojiPicker"
                                    color="black"
                                    tile
                                    :title="Translate('Emoji')"
                                >
                                    🙂
                                </v-btn>
                            </div>
                            <div v-if="!disableQuickText">
                                <v-btn
                                    icon
                                    medium
                                    @click="initiateQuickText()"
                                    color="black"
                                    tile
                                    :title="Translate('Quick text')"
                                >
                                    <v-icon>mdi-pound</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="allowSend">
                                <v-btn
                                    icon
                                    medium
                                    @click="send()"
                                    color="primary"
                                    :disabled="sendDisabled"
                                    tile
                                    :title="Translate('Send')"
                                >
                                    <v-icon>mdi-send-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div @click.stop v-if="showEmojiPicker" style="position: absolute;" :style="{ top: emojiButtonPosition().top, left: emojiButtonPosition().left }">
            <VEmojiPicker
                @select="addEmoji"
                ref="emojipicker"
                :i18n="emojiI18n"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { 
    getFormatState,
    setFontSize,
    setBackgroundColor,
    setFontName,
    setTextColor,
    insertImage,
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrikethrough,
    toggleBullet,
    toggleNumbering,
    setAlignment,
    setIndentation,
    toggleCode,
    clearFormat,
    toggleBlockQuote,
    setHeadingLevel
} from 'roosterjs-content-model-api';
import { EditorAdapter } from 'roosterjs-editor-adapter';
import { HtmlSanitizer } from 'roosterjs-editor-dom';
import * as legacyRoosterPlugins from 'roosterjs-editor-plugins';
import { FONT_SIZE_OPTIONS as fontSizeOptions, FONT_FAMILY as fontFamilyOptions, HEADING_STYLES as headingOptions } from '../javascript/roosterFonts';
import RoosterPickerDataProvider from '../javascript/roosterPickerDataProvider';
import RoosterContentChangedPlugin from '../javascript/roosterContentChangedPlugin';
import FormatPainterPlugin from '../javascript/roosterFormatPainterPlugin';
import DropdownMenu from './RoosterDropdownMenu.vue';
import { VEmojiPicker } from 'v-emoji-picker';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';
import methods from '../../../Shared/methods';

export default {
    name: "RoosterEditorBase",
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        initialContent: {
            type: String,
            default: ''
        },
        maxHeight: {
            type: Number,
            default: null
        },
        allowSend: {
            type: Boolean,
            default: false
        },
        sendDisabled: {
            type: Boolean,
            default: false,
        },
        sendOnEnter: {
            type: Boolean,
            default: false
        },
        disableFormatting: {
            type: Boolean, 
            default: false
        },
        disableImagePicker: {
            type: Boolean,
            default: false
        },
        disableQuickText: {
            type: Boolean,
            default: false
        },
        minHeight: {
            type: String,
            default: "0px"
        }
    },
    components: {
        DropdownMenu,
        VEmojiPicker
    },
    data() {
        return {
            fileList: [],
            keyMap: {},
            mountPoint: {},
            editor: null,
            emojiPicker: null,
            options: null,
            roosterModel: null,
            fontFamilyOptions: null,
            headingOptions: null,
            fontSizeOptions: null,
            pickerDataProvider: null,
            formatPainterPlugin: null,
            pickerPlugin: null,
            showEmojiPicker: false,
            emojiI18n: {
                search: methods.Translate('Search emojis...'),
                categories: {
                    recents: methods.Translate('Recent Emojis'),
                    smileys: methods.Translate('Smileys & Emotion'),
                    people: methods.Translate('People & Body'),
                    animals: methods.Translate('Animals & Nature'),
                    food: methods.Translate('Food & Drink'),
                    activities: methods.Translate('Activities'),
                    travel: methods.Translate('Travel & Places'),
                    objects: methods.Translate('Objects'),
                    symbols: methods.Translate('Symbols'),
                    flags: methods.Translate('Flags'),
                },
            },
        }
    },
    created() {
        this.init();
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeEmojiPicker);
        this.editor.dispose();
    },
    mounted() {
        window.addEventListener('click', this.closeEmojiPicker);
        this.pickerDataProvider = new RoosterPickerDataProvider();
        if (!this.disableQuickText)
            this.pickerPlugin = new legacyRoosterPlugins.PickerPlugin(this.pickerDataProvider, {
                changeSource: 'PLUGIN_PICKER',
                triggerCharacter: '#'
            });

        this.formatPainterPlugin = new FormatPainterPlugin();

        let plugins = [
            new RoosterContentChangedPlugin(this.broadcastContent.bind(this)),
            this.formatPainterPlugin
        ];

        let legacyPlugins = [
            new legacyRoosterPlugins.HyperLink(() => ('Ctrl+click to open link'), "_blank", (anchor) => {
                anchor.target = "_blank"
            }),
            new legacyRoosterPlugins.ImageResize(10, 10, 'var(--v-black-base)', true)
        ];

        if (this.pickerPlugin)
            legacyPlugins.push(this.pickerPlugin);

        let defaultSegmentFormat = {
            fontFamily: 'Helvetica, sans-serif',
            fontSize: '12pt',
            textColor: 'var(--v-black-base)',
            backgroundColor: "#FFFFFF"
        };

        let initialContent = '<div></div>';

        this.options = {
            plugins,
            legacyPlugins,
            defaultSegmentFormat,
            initialContent,
        };
        
        //Have to use EditorAdapter because we need access to legacy plugins since v9 doens't have all the plugins ported over yet
        this.editor = new EditorAdapter(this.$refs.contentdiv, this.options);
        
        //html sanitizer now takes an html node and not just an html string, so we need to create an element to pass to the sanitizer
        //we then need to insert the node
        var initialNode = document.createElement("div");
        initialNode.innerHTML = this.initialContent;
        new HtmlSanitizer({unknownTagReplacement: '*'}).sanitize(initialNode)
        this.editor.insertNode(initialNode, {updateCursor: false, replaceSelection:true, insertOnNewLine:false, position: 0});

        this.getFormatState();

        this.editor.setEditorDomAttribute("contentEditable", !this.readonly);
    },
    computed: {
        
    },
    methods: {
        ...methods,
        init() {
            this.roosterModel = {};
            Vue.set(this.roosterModel, 'fontName', ['Helvetica, sans-serif']);
            Vue.set(this.roosterModel, 'fontSize', ["12pt"]);
            Vue.set(this.roosterModel, 'textColor', "var(--v-black-base)");
            Vue.set(this.roosterModel, 'backgroundColor', "#FFFFFF");
            Vue.set(this.roosterModel, 'headingLevel', "Normal text");
            this.fontFamilyOptions = fontFamilyOptions;
            this.fontSizeOptions = fontSizeOptions;
            this.headingOptions = headingOptions;
        },
        getFormatState(){
            if (this.editor.getSelectionRange().toString().length == 0 && this.editor.getScrollContainer().textContent.length > 1){
                var formatState = getFormatState(this.editor);
                formatState.fontName = [(this.fontFamilyOptions.find(f => f.value.includes(formatState.fontName)) ? formatState.fontName : 'Helvetica, sans-serif').replace(/['"]+/g, '')];
                formatState.fontSize = [(this.fontSizeOptions.find(s => s.value.includes(formatState.fontSize)) ? formatState.fontSize : '12pt')];
                formatState.headingLevel = this.headingOptions[formatState.headingLevel]?.value || "Normal text";

                //need to convert an rgb value to hex so that it doesn't trigger the input for fontcolor unintentionally
                if(formatState.textColor.startsWith('rgb'))
                    formatState.textColor = '#' + formatState.textColor.match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                else
                    formatState.textColor = formatState.textColor == 'var(--v-black-base)' ? '#000' : formatState.textColor;

                //need to convert an rgb value to hex so that it doesn't trigger the input for background color unintentionally
                if(formatState.backgroundColor.startsWith('rgb'))
                    formatState.backgroundColor = '#' + formatState.backgroundColor.match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('').toUpperCase();

                this.roosterModel = formatState;
            } else if (this.editor.getScrollContainer().textContent.length <= 1){
                var defaultSegmentFormat = {
                    textColor: "var(--v-black-base)",
                    backgroundColor : "#FFFFFF" ,
                    fontName : ['Helvetica, sans-serif'],
                    fontSize :  ['12pt'],
                    headingLevel: "Normal text"
                }
                this.setFontSize('12pt')
                this.roosterModel = defaultSegmentFormat;
            }
        },

        processInsertImageChange() {
            for(var file of this.$refs.insertimage.files) {
                insertImage(this.editor, file);
            }
            this.$refs.insertimage.value = '';
        },

        sendEnter() {
            if(this.sendOnEnter && this.allowSend && !this.pickerDataProvider?.showingDropdown) {
                this.send();
            }
        },

        send() {
            this.broadcastContent();
            Vue.nextTick(() => {
                if(!this.sendDisabled) {
                    this.$emit('send');
                    Vue.nextTick(() => this.clearTextArea());
                }
            })
        },

        addEmoji(emoji) {
            this.editor.insertContent(emoji.data, {position: 3, insertOnNewLine: false, updateCursor: true, replaceSelection: true} );
            this.closeEmojiPicker();
        },

        closeEmojiPicker() {
            this.showEmojiPicker = false;
        },

        setFontName(fontName){
            if (fontName){
                Vue.set(this.roosterModel, 'fontName', [fontName]);
                setFontName(this.editor, fontName);
                return "Font Family Set";
            }
            return;
        },

        setFontSize(fontSize) {
            if (fontSize){
                Vue.set(this.roosterModel, 'fontSize', [fontSize]);
                
                setFontSize(this.editor, fontSize.toString());
                return "Font Size Set";
            }
        },

        setHeadingLevel(level) {
            if (level){
                Vue.set(this.roosterModel, 'headingLevel', level.toString());
                
                setHeadingLevel(this.editor, this.headingOptions.findIndex(h => h.value == level));
                return "Heading Level Set";
            }
        },

        setTextColor(color){
            if (color){
                Vue.set(this.roosterModel, 'textColor', color);
                setTextColor(this.editor, color);
                return "Text Color Set";
            }
        },

        setBackgroundColor(color){
            if (color){
                Vue.set(this.roosterModel, 'backgroundColor', color);
                setBackgroundColor(this.editor, color);
                return "Background Color Set";
            }
        },

        formatPaint() {
          this.formatPainterPlugin.startFormatPainter();  
        },

        clearFormat() {
            clearFormat(this.editor);
        },

        toggleBold(){
            Vue.set(this.roosterModel, 'isBold', !this.roosterModel.isBold);
            toggleBold(this.editor);
        },

        toggleItalic(){
            Vue.set(this.roosterModel, 'isItalic', !this.roosterModel.isItalic);
            toggleItalic(this.editor);
        },

        toggleUnderline(){
            Vue.set(this.roosterModel, 'isUnderline', !this.roosterModel.isUnderline);
            toggleUnderline(this.editor);
        },

        toggleStrikethrough(){
            Vue.set(this.roosterModel, 'isStrikeThrough', !this.roosterModel.isStrikeThrough);
            toggleStrikethrough(this.editor);
        },

        toggleBullet(){
            Vue.set(this.roosterModel, 'isBullet', !this.roosterModel.isBullet);
            toggleBullet(this.editor);
        },

        toggleNumbering(){
            Vue.set(this.roosterModel, 'isNumbering', !this.roosterModel.isNumbering);
            toggleNumbering(this.editor);
        },

        setAlignment(alignment){
            setAlignment(this.editor, alignment);
        },

        setIndentation(indentation){
            setIndentation(this.editor, indentation);
        },

        undoSnapshot(){
            this.editor.undo();
        },

        redoSnapshot(){
            this.editor.redo();
        },

        toggleCode(){
            Vue.set(this.roosterModel, 'isCodeBlock', !this.roosterModel.isCodeBlock);
            toggleCode(this.editor);
        },

        toggleQuote(){
            Vue.set(this.roosterModel, 'isQuote', !this.roosterModel.isQuote);
            toggleBlockQuote(this.editor);
        },       

        initiateQuickText() {
            this.editor.insertContent('#');
            this.pickerPlugin.setIsSuggesting(true);
            this.pickerDataProvider.queryStringUpdated('');
        },

        editorKeyUp(event){
            this.$emit('rooster-key-up', event);
            //look for backpace and arrow key (left, up, right, down) navigation so we can update the formatstate
            if ([8, 37, 38, 39, 40].includes(event.which)) {
                this.getFormatState();
            }
        },

        broadcastContent() {
            let content = this.editor.getContent();
            this.$emit('input', content, careHelpfulFunctions.stripHTML(content));
        },

        clearTextArea() {
            let initialContent = '<div></div>';

            this.editor.setContent(initialContent);
        },

        removeAllChildNodes(parent) {
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
        },
        focus() {
            this.editor.focus();
        },
        
        emojiButtonPosition() {
            if(this.$refs.emojibutton) {
                var offsetTop = this.$refs.emojibutton.offsetTop;
                var offsetLeft = this.$refs.emojibutton.offsetLeft;

                return {
                    left: offsetLeft - 268 + 'px',
                    top: offsetTop - 270 + 'px'
                }
            }
            else 
                return {}
        },
    },
    watch: {
        readonly: function(newVal) {
            this.editor.setEditorDomAttribute("contentEditable", !newVal);
        },
    },
};
</script>

<style scoped>
#EmojiPicker {
    width: 310px;
}

.callcorp-editor .editor-wrapper {
    min-height: 50px;
    display: flex;
    flex: 1;
    flex-basis: auto;
    flex-direction: column;
    padding: 1px;
    position: relative;
    overflow: hidden;
    border: #c0c0c0 1px solid;
}

.readonly.callcorp-editor .editor-wrapper {
    border: none;
    margin: 0;
}

.composer-wrapper {
    padding: 10px;
}

.readonly .composer-wrapper {
    padding: 0;
}

.menu-bar-container, .menubar {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    background: #efefef;
}

.is-active {
    background-color: #dadada;
}
 
/* Overrides of default css for the editor itself */
.editor {
	position: relative;
}

.editor p {
    margin-bottom: 0;
}

.editor:focus, .editor__content:focus, .ProseMirror:focus {
    outline: none;
}

.editor__content {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
    min-height: 20px;
    flex-grow: 1;
    line-height:normal; 
}

.editor__content pre {
    background-color: rgba(var(--palette-neutral-8,234, 234, 234),1);
    float: left;
    clear: left;
    min-width: 100%;
}



</style>

<style>
.callcorp-editor table {
    display: contents !important;
}
.container-emoji {
    max-height: 200px;
}
</style>