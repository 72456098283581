import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

import { AgGridVue } from "ag-grid-vue";

const CellTemplate = {
    computed: {
    },
    render(h) {
        const icons = [];
        if (this.params.columnDef.Icons)
            for (let i = 0; i < this.params.columnDef.Icons.length; i++) {
                const ico = this.params.columnDef.Icons[i];

                if (ico.condition_expn && !utils.evaluate(ico.condition_expn, this.params.cccontext, false, null, false, this.params.data))
                    continue;

                let color;
                if (ico.color_expn)
                    color = utils.evaluate(ico.color_expn, this.params.cccontext, false, null, false, this.params.data);

                let tooltip;
                if (ico.tooltip_expn)
                    tooltip = utils.evaluate(ico.tooltip_expn, this.params.cccontext, false, null, false, this.params.data);

                const icon = utils.evaluate(ico.icon_expn, this.params.cccontext, false, null, false, this.params.data);

                let button;
                if (color)
                    button = <v-btn elevation={0} icon small><v-icon style={{ color: color }} small>{icon}</v-icon></v-btn>;
                else
                    button = <v-btn elevation={0} icon small><v-icon small>{icon}</v-icon></v-btn>;

                if (tooltip)
                    button = utils.generateTooltip(h, button, tooltip, 'right');

                icons.push(button)
            }

        return <span>{icons}</span>;
    }
};

Vue.component('cc-ag-grid', {
    mixins: [BaseComponent],
    components: {
        AgGridVue,
        CellTemplate,
    },
    data: () => ({
        datatable_id: null,

        modelfunc: null,
        modelurl: null,
        modelvalue: null,

        loading: false,

        gridApi: null,
        columnApi: null,
        searchText: '',
    }),
    created() {
        this.datatable_id = `data-table-${utils.generateUUID()}`;
        this.itemsPerPage = this.controlData.ItemsPerPage || 30;

        if ((this.controlData.DataType === 'URL' || this.controlData.DataType === undefined) && this.controlData.DataURL) {
            this.modelurl = utils.compile(this, this.controlData.DataURL);
        }
        else if (this.controlData.DataType === 'Raw' && this.controlData.Data) {
            this.modelvalue = utils.compileObject(this, this.controlData.Data);
        }
        this.prepareColumns();
    },
    computed: {
        columnDefs: function () {
            const h = this.$createElement;
            const cols = [];

            if (this.controlData.SelectOptions.Enable)
                cols.push({
                    headerName: '',
                    sortable: false,
                    resizable: true,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    pinned: 'left',
                    width: 50,
                });

            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];
                if (!hdr.Visible)
                    continue;

                switch (hdr.Type) {
                    case 'Value':
                        let valueGetter;
                        if (hdr.Value && !hdr.value_expn)
                            hdr.value_expn = utils.compile(this, hdr.Value, false, hdr.RowModelName || 'row');
                        if (hdr.value_expn)
                            valueGetter = (node) => utils.evaluate(hdr.value_expn, this, false, null, false, node.data);

                        let valueFormatter;
                        if (hdr.cellfilter_expn && hdr.condition_expn)
                            valueFormatter = (node) => utils.evaluate(hdr.condition_expn, this, false, null, false, node.data) ? utils.evaluate(hdr.cellfilter_expn, this, false, null, false, [node.data, node.value], true) : '';
                        else if (hdr.cellfilter_expn && !hdr.condition_expn)
                            valueFormatter = (node) => utils.evaluate(hdr.cellfilter_expn, this, false, null, false, [node.data, node.value], true);
                        else if (hdr.condition_expn)
                            valueFormatter = (node) => utils.evaluate(hdr.condition_expn, this, false, null, false, node.data) ? hdr.field_extrator(node.data) : '';

                        cols.push({
                            headerName: hdr.DisplayName || hdr.Name || hdr.Field,
                            field: hdr.Field,
                            sortable: hdr.Sortable,
                            filter: true,
                            resizable: true,
                            valueGetter: valueGetter,
                            valueFormatter: valueFormatter,
                        });
                        break;

                    case 'Icons':
                        cols.push({
                            headerName: '',
                            resizable: true,
                            cellRendererFramework: 'CellTemplate',
                            cellRendererParams: {
                                columnDef: hdr,
                                cccontext: this,
                            },
                        });
                        break;
                }

            }

            return cols;
        },
        dataSource: function () {
            return this.modelfunc ? this.modelfunc() : null;
        },
        modelurlvalue: function () {
            if (this.modelurl)
                return utils.evaluate(this.modelurl, this);
            else
                return '';
        },
        modelrawvalue: function () {
            if (this.modelvalue)
                return utils.evaluateObject(this.modelvalue, this);
            else
                return [];
        },

        gridHeaders: function () {
            const h = this.$createElement;
            const items = [];
            items.push(
                <v-text-field
                    style="max-width: 300px;"
                    class="mb-1"
                    dense hide-details
                    clear-icon="mdi-close-circle"
                    clearable
                    placeholder="Quick Search"
                    prepend-inner-icon="mdi-magnify"
                    value={this.searchText}
                    on-input={(v) => this.searchText = v}
                ></v-text-field>
            );

            // Separator to push remaining items to right
            items.push(<span style="flex-grow: 1;"></span>);

            // Resizer (runs auto-resize for all columns)
            items.push(utils.generateTooltip(h,
                <v-btn elevation={0} small plain on-click={(e) => this.columnApi.autoSizeAllColumns()}>
                    <v-icon>
                        mdi-arrow-left-right-bold
                    </v-icon>
                </v-btn>, 'Size columns to content', 'bottom'));

            // Refresh
            items.push(utils.generateTooltip(h,
                <v-btn elevation={0} small plain on-click={(e) => this.Refresh()}>
                    <v-icon>
                        mdi-cached
                    </v-icon>
                </v-btn>, 'Refresh', 'bottom'));

            const scopedSlots = {
                activator: ({ on, attrs }) =>
                    <v-btn elevation={0} small plain
                        {...{ on }}
                        {...{ attrs }}
                    >
                        <v-icon>
                            mdi-menu
                        </v-icon>
                    </v-btn>
            };

            const menus = [];

            menus.push(
                <v-list-item key={0} on-click={(e) => this.exportAllAsCSV(e)}>
                    <v-list-item-content>
                        <v-list-item-title>Export all data as csv</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            );

            menus.push(
                <v-list-item key={1} on-click={(e) => this.exportVisibleAsCSV(e)}>
                    <v-list-item-content>
                        <v-list-item-title>Export visible data as csv</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            );

            menus.push(
                <v-list-item key={2} on-click={(e) => this.restoreDefaults(e)}>
                    <v-list-item-content>
                        <v-list-item-title>Restore Default Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            );

            menus.push(<v-divider></v-divider>);

            // Add all columns with check boxes for visible

            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const col = this.controlData.ColumnDefs[i];
                if (col.Type != 'Value') continue;

                menus.push(
                    <v-list-item key={i+3} on-click={(e) => this.toggleColumnVisibility(e, col)}>
                        <v-list-item-icon>
                            <v-icon small>{col.Visible ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{col.DisplayName || col.Name || col.Field}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );
            }

            // Menu
            const menu =
                <v-menu
                    offset-y
                    close-on-click={true}
                    close-on-content-click={true}
                    scopedSlots={scopedSlots}
                >
                    <v-list dense style="max-height: 600px" class="overflow-y-auto">
                        <v-list-item-group>
                            {menus}
                        </v-list-item-group>
                    </v-list>
                </v-menu>;

            items.push(menu);

            return (
                <div style={{ display: 'flex' }}>
                    {items}
                </div>
            );
        },
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            await this.Refresh();
        },
        async Refresh(clearSelectedRows) {
            if (clearSelectedRows) {
            }

            this.loading = true;
            try {
                let model;
                if (this.controlData.DataType == 'URL' && this.modelurl) {
                    model = this.modelurlvalue ? await utils.api.get(this.modelurlvalue, false, false) : [];

                    if (!this.modelurl_watch$)
                        this.modelurl_watch$ = this.$watch(
                            function (newvalue) {
                                return this.modelurlvalue;
                            },
                            function (val, oldval) {
                                this.Refresh();
                            }
                        );
                }
                else if (this.controlData.DataType == 'Raw' && this.modelvalue)
                    try {
                        model = this.modelrawvalue;

                        if (!this.modelvalue_watch$)
                            this.modelvalue_watch$ = this.$watch(
                                function () {
                                    try {
                                        return this.modelrawvalue;
                                    }
                                    catch (e) {
                                        utils.warn('SlimGrid modelvalue ' + this.controlData.Model.Definition + ' failed to evaluate: ' + e);
                                        return null;
                                    }
                                },
                                function (val, oldval) {
                                    this.Refresh();
                                },
                                {
                                    deep: false
                                }
                            );
                    }
                    catch (e) {
                        utils.warn('BasicGrid modelvalue ' + this.controlData.Data + ' failed to evaluate: ' + e);
                        model = [];
                    }
                else
                    model = [];

                this.modelfunc = () => model;
            }
            finally {
                this.loading = false;
            }
        },
        prepareColumns() {
            // Generate compiled expressions for all interpolations
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];

                if (hdr.Condition && !hdr.condition_expn)
                    hdr.condition_expn = utils.compileExpression(this, hdr.Condition, hdr.RowModelName || 'row');

                switch (hdr.Type) {
                    case 'Value':
                        if (!hdr.field_extrator)
                            hdr.field_extrator = new Function('f', `return f.${hdr.Field};`);

                        if (hdr.Value && !hdr.value_expn)
                            hdr.value_expn = utils.compile(this, hdr.Value, false, hdr.RowModelName || 'row');

                        if (hdr.CellFilter && !hdr.cellfilter_expn)
                            hdr.cellfilter_expn = utils.compile(this, hdr.CellFilter, false, [hdr.RowModelName || 'row', hdr.ValueModelName || 'value']);

                        break;

                    case 'Icons':
                        for (let i = 0; i < hdr.Icons.length; i++) {
                            const ico = hdr.Icons[i];

                            if (ico.Condition && !ico.condition_expn)
                                ico.condition_expn = utils.compileExpression(this, ico.Condition, hdr.RowModelName || 'row');

                            if (!ico.icon_expn)
                                ico.icon_expn = utils.compile(this, ico.Icon, false, hdr.RowModelName || 'row');

                            if (ico.Color && !ico.color_expn)
                                ico.color_expn = utils.compile(this, ico.Color, false, hdr.RowModelName || 'row');

                            if (ico.Tooltip && !ico.tooltip_expn)
                                ico.tooltip_expn = utils.compile(this, ico.Tooltip, false, hdr.RowModelName || 'row');
                        }
                        break;
                }
            }
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
        },
        SizeColumnsToFit() {
            this.columnApi.autoSizeAllColumns();
        },
        getSelectedRows() {
            const selectedNodes = this.gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            const selectedStr = selectedData.map(node => `${node.Name} ${node.Folder}`).join(', ');
            alert(selectedStr);
        },
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: data.rowIndex, Data: data.row.data }, this);
        },
        clickIcon(e, hdr, ico, item) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(ico.Actions, { Data: item }, this);
        },
    },
    props: {},
    render() {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <div
                class={{ 'c-AgGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ag-theme-alpine': true }}
                style={style}
            >
                <v-overlay value={false && this.loading} absolute={true}>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        size={64}
                    ></v-progress-circular>
                </v-overlay>

                {this.gridHeaders}

                <ag-grid-vue
                    id={this.datatable_id}
                    class={{ 'ag-theme-alpine': true }}
                    style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
                    on-grid-ready={this.onGridReady}
                    on-first-data-rendered={this.SizeColumnsToFit}
                    columnDefs={this.columnDefs}
                    rowData={this.dataSource}
                    rowSelection={this.controlData.SelectOptions.MultiSelect ? "multiple" : null}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    quickFilterText={this.searchText}
                    suppressDragLeaveHidesColumns={true}

                    suppressRowClickSelection={true}
                    suppressCellSelection={true}
                >
                </ag-grid-vue>
            </div>
        );
    }
});