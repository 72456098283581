import Vue from 'vue';
import BaseComponent from './BaseAgGridMixin.jsx';
import utils from '../../Shared/utils.jsx';

import { AgGridVue } from "ag-grid-vue";

Vue.component('basic-grid', {
    mixins: [BaseComponent],
    components: {
        AgGridVue,
    },
    data: () => ({
    }),
    created() {
    },
    computed: {
        dataSource: function () {
            return this.modelfunc ? this.modelfunc() : null;
        },
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async Refresh(clearSelectedRows) {
            this.internalRefresh(clearSelectedRows);
        },
        getSizeDefaults() {
            // Override in child control if you need to adjust the defaults for size
            // Ex: return { Height: { Mode: "Fill" } }
            return {
                Height: { Mode: "Fill" },
                Width: { Mode: "Fill" }
            };
        },
    },
    props: {},
    render() {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <div
                key={this.unique_id + '_main'}
                v-show={this.isvisible}
                class={{ 'c-BasicGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ag-theme-alpine': true }}
                style={style}
            >
                <v-overlay value={this.loading} absolute={true}>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        size={64}
                    ></v-progress-circular>
                </v-overlay>

                {this.gridHeaders}
                <ag-grid-vue
                    key={this.unique_id}
                    id={this.datatable_id}
                    class={{ 'ag-theme-alpine': true }}
                    style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
                    on-grid-ready={this.onGridReady}
                    on-first-data-rendered={(e) => this.onFirstDataRendered(e)}
                    on-selection-changed={(e) => this.rowSelectionChanged(e)}
                    columnDefs={this.agColumnDefs}
                    rowData={this.dataSource}
                    rowSelection={this.controlData.SelectOptions.MultiSelect ? "multiple" : null}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    quickFilterText={this.searchText}
                    suppressScrollOnNewData={true}
                    suppressRowClickSelection={true}
                    suppressCellSelection={true}
                    suppressDragLeaveHidesColumns={true}
                    pagination={this.pagination}
                    paginationAutoPageSize={this.pagination}
                    getRowClass={this.getRowClass}
                    onFilterChanged={this.columnFilterChanged}
                    on-sort-changed={this.onSaveGridSortState}
                    on-column-visible={this.onSaveGridColumnState}
                    on-column-pinned={this.onSaveGridColumnState}
                    on-column-resized={this.onSaveGridColumnState}
                    on-column-moved={this.onSaveGridColumnState}
                    on-column-row-group-changed={this.onSaveGridColumnState}
                >
                </ag-grid-vue>
            </div>
        );

        //const style = {
        //    overflow: "auto",
        //    display: "flex",
        //    flexDirection: "column",
        //    ...this.sizeStyle,
        //    maxHeight: "1000px",
        //};

        //return (
        //    <DxDataGrid
        //        class={{ 'c-BasicGrid': true, [`c-name-${this.name || 'unnamed'}`]: true }}
        //        style={style}
        //        ref={this.dataGridRefName}
        //        v-show={this.isvisible}
        //        data-source={this.dataSource}
        //        key-expr={this.controlData.KeyFields}
        //        allow-column-reordering={true}
        //        allow-column-resizing={true}
        //        column-resizing-mode="widget"
        //        column-auto-width={this.controlData.SizeColumnnsToContent}
                
        //        show-borders={true}
        //        show-row-lines={false}
        //        show-column-lines={true}
        //        row-alternation-enabled={true}
        //        word-wrap-enabled={false}
        //        scopedSlots={scopedSlots}
        //        selected-row-keys={this.selectedRowKeys}
        //        on-toolbar-preparing={(e) => this.onToolbarPreparing(e)}
        //        on-selection-changed={(s) => this.onSelectionChanged(s)}
        //    >
        //        <DxSearchPanel visible={this.controlData.EnableFilter} />
        //        <DxScrolling mode="virtual" />
        //        <DxLoadPanel enabled={true} />
        //        <DxStateStoring enabled={true} type="custom" custom-load={this.loadState} custom-save={this.saveState} storage-key={this.storageKey} />
        //        {selector}

        //        {coldefs}

        //        <DxSummary>
        //            <DxTotalItem
        //                column="name"
        //                summary-type="count"
        //            />
        //            <DxTotalItem
        //                column="fat"
        //                summary-type="max"
        //            />
        //            <DxTotalItem
        //                column="calories"
        //                summary-type="sum"
        //                value-format="thousands"
        //            />
        //        </DxSummary>
        //    </DxDataGrid>
        //);
    }
});