import Vue from 'vue';
import axios from 'axios';
import methods from './methods.js';
import filters from './filters.js';
import EventBus from '../Application/event-bus.js';
import careHelpfulFunctions from '../Application/careHelpfulFunctions.jsx';
import actionDefaults from './actionDefaults';
import apiService from '@/Services/api';
import cachingService from '@/Services/cachingService';
import { ObjFunc, ObjProxy } from '@/Shared/helperClasses';
import editor from '@/Services/editor.jsx';
import _ from 'lodash';

function scanBack(buff) {
    // Scan backwards in buff looking for a whole expression which will be the first parameter of the filter function.
    // Once detected, return the remaining buffer without the extracted expression.
    let i = buff.length - 1;
    let level = 0;
    while (i >= 0) {
        switch (buff[i]) {
            case ')': level++; break;
            case '(':
                if (level <= 0) {
                    // End here
                    return { expression: buff.substr(i + 1), buff: buff.substr(0, i + 1) };
                }
                level--;
                break;
            //case '+':
            //case '-':
            //case '*':
            //case '/':
            //case ':':
            //    // Assume that pipes have higher priority than other operators, so if we encounter
            //    // another operator, end the expression (unless we are in parenthesis).
            //    if (level <= 0) {
            //        // End here
            //        return { expression: buff.substr(i + 1), buff: buff.substr(0, i + 1) };
            //    }
            //    break;
        }
        i--;
    }

    return { expression: buff, buff: '' };
}

function getFilterArgs(buff, index) {
    let j = index;
    let data = '';
    let inquote1 = false;
    let inquote2 = false;
    let level = 0;
    while (j < buff.length) {
        switch (buff[j]) {
            case '(': if (!inquote1 && !inquote2) level++; break;
            case '|':
            case ')': if (!inquote1 && !inquote2 && level <= 0) return { args: data, index: j }; else level--; break;
            case '"': if (!inquote2) inquote1 = !inquote1; break;
            case "'": if (!inquote1) inquote2 = !inquote2; break;
            case ':': if (!inquote1 && !inquote2 && level === 0) { data += ','; j++; continue; } break;
        }
        data += buff[j];
        j++;
    }
    return { args: data, index: j };
}

function extractFilters(whole) {
    // Parse pipe filter expression - the replacer rewrites it as a javascript function:
    // someexpression | datetime:'medium'
    // f_datetime(someexpression, 'medium')

    // Real example (from detailed history grid):
    // ParentByName('maincontainer').Vars.DisplayTime == 0 ? (util.durationSecondsAsString( RowData().XferDuration )) :  ((RowData().XferDuration)/60 | number : 2 )
    // To:
    // ParentByName('maincontainer').Vars.DisplayTime == 0 ? (util.durationSecondsAsString( RowData().XferDuration )) :  (f_number((RowData().XferDuration)/60 , 2) )

    // Input.Data=='NotFound' ? ( RowData().Direction == 'inbound' ? ((RowData().CallerName || RowData().RemoteParty) | phoneNumber) : (RowData().RemoteParty | phoneNumber) ) : Input.Data
    // To:
    //

    let i = 0;
    let buff = '';
    while (i < whole.length) {
        if (whole[i] === '|' && (i >= whole.length - 1 || whole[i + 1] !== '|') && (i <= 0 || whole[i-1] !== '|')) {
            // Scan forward to get the filter name and arguments ( | name [: args])
            let j = i + 1;
            // Skip whitespace
            while (j < whole.length && whole[j] == ' ' || whole[j] == '\t') j++;
            // Take until word break
            let startIndex = j;
            while (j < whole.length && !(/\s/.test(whole[j]) || whole[j] == ':' || whole[j] == ')')) j++;
            let endIndex = j;
            // Filter name
            let name = whole.substring(startIndex, endIndex);
            // Skip whitespace
            while (j < whole.length && whole[j] == ' ' || whole[j] == '\t') j++;

            let args = '';
            if (j < whole.length && whole[j] == ':') {
                // Grab filter arguments (scan to the end or to an ending paranthesis)
                let res = getFilterArgs(whole, j + 1);
                args = res.args;
                j = res.index;
            }

            // Scan back to the prior characters looking for a whole expression (bounded by nothing or parenthesis)
            let input = scanBack(buff);
            buff = input.buff;
            if (args)
                buff += `f_${name}(${input.expression},${args})`;
            else
                buff += `f_${name}(${input.expression})`;

            i = j;
            continue;
        }
        else
            buff += whole[i];

        i++;
    }
    return buff;
}

function parseUserExpression(original, debug, context_name, donotusewith, custommodelas) {
    if (!context_name)
        context_name = 'context';

    // This is an algorithm that can be used to generate a function to perform interpolation
    // using a pre-generated dynamic function. It will take anything in between double curlys {{...}}
    // and build an array with the static text surrounding functions that execute the inner code
    // as JavaScript returning strings.

    // Example original string:
    // var original = 'This is a {{VarByName("Test").length}} of the parsing {{ParamByName("One")}} the end.';
    // Final output:
    // This is a 6 of the parsing <One> the end.

    if (!original || !(typeof original === 'string'))
        return new ObjFunc('', (context) => original);

    const saved = original;
    original = original.replace(/\{\*/g, "{{");
    original = original.replace(/\*\}/g, "}}");
    original = original.replace(/\{#/g, "{{");
    original = original.replace(/#\}/g, "}}");
    original = original.replace(/\{%/g, "{{");
    original = original.replace(/%\}/g, "}}");
    original = original.replace(/\\\{/g, '&Opnc;');
    original = original.replace(/\\\}/g, '&Clsc;');

    let result = [];
    let raw = [];
    let last = 0;
    function replacer(match, p1, offset, whole) {
        // p1 is the text located in between the curlys
        if (offset > 0) {
            const tmp = whole.substr(last, offset - last);
            result.push(tmp);
            raw.push(tmp);
        }

        const saved1 = p1;
        p1 = p1.replace(/&Opnc;/g, '{');
        p1 = p1.replace(/&Clsc;/g, '}');
        p1 = extractFilters(p1);

        // f contains a new JavaScript function to evaluate the code
        let f;
        if (donotusewith) {
            f = 'return ' + p1 + ';';
        }
        else {
            f = 'with (context) {';
            if (debug)
                f += 'debugger;';
            f += 'return ' + p1 + ';';
            f += '}';
        }
        // This builds a dynamic function - context is a value passed in at execution time, it will
        // contain the current context functions, such as VarByName, etc. These can be called directly
        // within the code since we wrapped the body in a with( ) clause.
        let x;
        if (custommodelas && Array.isArray(custommodelas))
            x = new ObjFunc(saved1, new Function([context_name, 'util', ...custommodelas].join(','), f));
        else if (custommodelas)
            x = new ObjFunc(saved1, new Function(context_name, 'util', custommodelas, f));
        else
            x = new ObjFunc(saved1, new Function(context_name, 'util', f));

        result.push(x); // eval(p1)
        raw.push(p1);

        last = offset + match.length;

        // The return is ignored (maybe I can use something other than .replace)
        return '';
    }

    const matcher = /\{\{([^}]+)\}\}/g;
    original.replace(matcher, replacer);

    if (last < original.length) {
        const tmp = original.substr(last);
        result.push(tmp);
        raw.push(tmp);
    }

    const final = (...args) => {
        let text = '';
        for (var i = 0; i < result.length; i++)
            if (typeof result[i] === 'object' && result[i] instanceof ObjFunc) {
                const text_res = result[i].func.apply(null, args);
                if (text_res === undefined || text_res === null)
                    ;
                else if (typeof text_res === 'object')
                    text += JSON.stringify(text_res);
                else
                    text += text_res;
            }
            else
                text += result[i];

        return text;
    };

    //const final = (context, util, custommodel) => {
    //    let text = '';
    //    for (var i = 0; i < result.length; i++)
    //        if (typeof result[i] === 'object' && result[i] instanceof ObjFunc) {
    //            const text_res = result[i].func(context, careHelpfulFunctions, custommodel);
    //            if (text_res === undefined || text_res === null)
    //                ;
    //            else if (typeof text_res === 'object')
    //                text += JSON.stringify(text_res);
    //            else
    //                text += text_res;
    //        }
    //        else
    //            text += result[i];

    //    return text;
    //};

    return new ObjFunc(saved, final);
}

function parseObjectWithUserExpressions(control, parent, custommodelas) {
    if (parent === null)
        return parent;

    if (typeof parent === 'string') {
        // {# object #}
        if ((parent.substr(0, 2) === '{#' && parent.substr(parent.length - 2, 2) === '#}') ||
            (parent.substr(0, 2) === '{%' && parent.substr(parent.length - 2, 2) === '%}')) {
            const expn = parent.substr(2, parent.length - 4);

            if (custommodelas && Array.isArray(custommodelas))
                return new ObjFunc(expn, new Function(['context', 'util', ...custommodelas].join(','), 'with (context) return ' + expn + ';'));
            else if (custommodelas)
                return new ObjFunc(expn, new Function('context', 'util', custommodelas, 'with (context) return ' + expn + ';'));
            else
                return new ObjFunc(expn, new Function('context', 'util', 'with (context) return ' + expn + ';'));
        }
        else {
            return parseUserExpression(parent, false, null, false, custommodelas);
        }
    }

    if (typeof parent === 'object') {
        if (Array.isArray(parent)) {
            let newparent = [];
            for (var i = 0; i < parent.length; i++)
                newparent.push(parseObjectWithUserExpressions(control, parent[i], custommodelas));
            return newparent;
        }
        else {
            let newparent = {};
            for (var key in parent) {
                const item = parent[key];
                // Prevent an object with an action array from being compiled at this stage (actions are compiled as they are executed)
                if (typeof item === 'object' && Array.isArray(item) && item.some(i => i && typeof i === 'object' && 'ActionType' in i))
                    newparent[key] = item;
                else
                    newparent[key] = parseObjectWithUserExpressions(control, item, custommodelas);
            }
            return newparent;
        }
    }

    return parent;
}

function evaluateUserExpression(result, context, custommodel, multiplecustommodels) {
    if (result === null || typeof result === 'undefined')
        return result;

    if (typeof result === 'object' && result != null && result instanceof ObjFunc) {
        if (multiplecustommodels)
            return result.func.apply(null, [context, careHelpfulFunctions, ...custommodel]);
        else
            return result.func(context, careHelpfulFunctions, custommodel);
    }

    if (Array.isArray(result)) {
        let newresult = [];
        for (var i = 0; i < result.length; i++)
            newresult.push(evaluateUserExpression(result[i], context, custommodel, multiplecustommodels));
        return newresult;
    }
    else if (typeof result === 'object') {
        let newresult = {};
        for (var key in result)
            newresult[key] = evaluateUserExpression(result[key], context, custommodel, multiplecustommodels);
        return newresult;
    }
    else
        return result;
}

function parseExpressionWithFilters(original, debug) {
    function filter_replacer(match, pp0, pp1, pp2, offset, whole) {
        // pp1 is the text of the filter expression, Ex: "|datetime:'medium'"
        const filter = pp1.substring(1).split(':');
        const args = filter[1];
        if (args)
            return `f_${filter[0].trim()}(${pp0},${args})${pp2}`;
        else
            return `f_${filter[0].trim()}(${pp0})${pp2}`;
    }
    // Regex to parse pipe filter expression - the replacer rewrites it as a javascript function:
    // someexpression | datetime:'medium'
    // datetime(someexpression, medium)
    // const filter_matcher = /(\b.+[^\|])(\|[^\|].+)$/g;
    const filter_matcher = /(\b.+[^\|])\|(\s*\w+)(.*$)/g;
    return original.replace(filter_matcher, filter_replacer);
}

function convertSetValueToVueSet(source, value) {
    //TODO: transform
    // Base.ChildControlByName('resultform',true).Model.data = value
    //into
    // Vue.set(Base.ChildControlByName('resultform',true).Model, 'data', value)

    //TODO: transform
    // VarByName('BLegHSMs')[Input.HSM.UniqueID] = value
    //into
    // Vue.set(VarByName('BLegHSMs'), Input.HSM.UniqueID, value)

    let quote1 = false;
    let quote2 = false;
    let objname = '';
    let fldname = '';
    let brackets = 0;
    let usequotes = true;
    let i = source.length - 1;
    while (i >= 0) {
        switch (source[i]) {
            case '"':
                fldname = source[i] + fldname;
                if (!quote2) quote1 = !quote1;
                break;
            case '\'':
                fldname = source[i] + fldname;
                if (!quote1) quote2 = !quote2;
                break;
            case '.':
                if (quote1 || quote2 || brackets > 0) {
                    fldname = source[i] + fldname;
                    break;
                }
                objname = source.substr(0, i);
                i = 0;
                break;

            case ']':
                if (brackets > 0 || quote1 || quote2)
                    fldname = source[i] + fldname;

                if (!quote1 && !quote2)
                    brackets++;
                break;

            case '[':
                if (quote1 || quote2) {
                    fldname = source[i] + fldname;
                    break;
                }
                brackets--;
                if (brackets === 0) {
                    objname = source.substr(0, i);
                    usequotes = false;
                    i = 0;
                    break;
                }
                else
                    fldname = source[i] + fldname;
                break;

            default:
                fldname = source[i] + fldname;
                break;
        }
        i--;
    }
    if (!fldname)
        return '';

    if (usequotes) {
        if (fldname.includes('"'))
            fldname = "'" + fldname + "'";
        else
            fldname = '"' + fldname + '"';
    }

    if (!objname)
        return `vue.set(context, ${fldname}, ${value})`;
    else
        return `vue.set(${objname}, ${fldname}, ${value})`;
}

function resolveSizes(size) {
    switch (size) {
        case 'UltraSmall': return '1px';
        case 'ExtraSmall': return '2.5px';
        case 'Small': return '5px';
        case 'Medium': return '10px';
        case 'Large': return '20px';
        case 'Larger': return '40px';
        case 'ExtraLarge': return '80px';
        default:
            return '0px';
    }
}

function getFormattedDateNow() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const mins = now.getMinutes().toString().padStart(2, '0');
    const secs = now.getSeconds().toString().padStart(2, '0');
    const msec = now.getMilliseconds().toString().padStart(3, '0');
    return `${hours}:${mins}:${secs}.${msec}`;
}

function decompileObject(compiledobj) {
    let str = '{';
    let addcomma = false;
    for (let key in compiledobj) {
        if (addcomma)
            str += ',';

        str += `"${key}":`;

        try {
            const item = compiledobj[key];
            if (typeof item === 'object' && item instanceof ObjFunc)
                str += `"${item.code}"`;
            else if (typeof item === 'object' && item instanceof ObjProxy)
                str += `[proxy]`; // "${decompileObject(compiledobj[key].target)}"`;
            else if (typeof item === 'object')
                str += decompileObject(item);
            else if (typeof item === 'string')
                str += `"${item}"`;
            else if (item === undefined || item === null)
                str += 'null';
            else
                str += item;
        }
        catch (e) {
            str += `[error: ${e.toString()}]`;
            utils.warn(`Could not decompileObject at key: ${key}`, e);
        }

        addcomma = true;
    }
    str += '}';
    return str;
}

const cache = {
    access_token: null,
    schema: {},
    schema_date: null,
    async setBoostrap(s) {
        cache.schema = s;
        cache.schema_date = new Date();
        utils.log(new Date() + ' Resolving ' + Object.keys(s).length + ' schema documents...');
        let i = 0;
        for (var key in cache.schema) {
            i++;
            if (key == '/schema/public/Platform.Schema.Data.v1/schema-draft04')
                continue;
            //utils.log('[' + i + '] key:' + key + '...');
            try {
                await cache.resolveReferences(cache.schema[key]);
            }
            catch (e) {
                utils.log('Could not resolve schema, removing from set:' + key);
                delete cache.schema[key];
            }
        }
        utils.log(new Date() + ' Finished resolving ' + Object.keys(s).length + ' schema documents');
    },
    async resolveReferences(schema) {
        let s = schema;

        if ('$ref' in s) {
            const r = await cache.getSchema(s['$ref']);
            if (!r) {
                utils.log(' $ref not found: ' + s['$ref']);
                throw 'Schema not found for ' + s['$ref'];
            }
            r.Id = s['$ref'];

            // this is garbage!! the use case dictates that I must make a copy of the $ref
            // schema object in order to allow the reference definition to override certain
            // fields. what a pile of junk! truly horrendous. (DP - 6/26/2021)
            if (s.condition || s.title || ('default' in s)) {
                const copy_of_r = { ...r };

                if (s.condition)
                    copy_of_r.condition = s.condition;

                if (s.title)
                    copy_of_r.title = s.title;

                if ('default' in s)
                    copy_of_r.default = s.default;

                if ('$isTypeRef' in s)
                    copy_of_r['$isTypeRef'] = s['$isTypeRef'];

                if ('condition' in copy_of_r && copy_of_r.condition && typeof copy_of_r.condition === 'string') {
                    copy_of_r.$$condition = new Function('context', 'util', 'with (context) return ' + copy_of_r.condition + ';');
                }

                return copy_of_r;
            }
            return r;
        }

        if ('allOf' in s) {
            for (var i = 0; i < s.allOf.length; i++) {
                const a = s.allOf[i];
                s.allOf[i] = await cache.resolveReferences(a);
            }
        }
        else if ('oneOf' in s) {
            for (var i = 0; i < s.oneOf.length; i++) {
                const a = s.oneOf[i];
                s.oneOf[i] = await cache.resolveReferences(a);
            }
        }
        else if ('anyOf' in s) {
            for (var i = 0; i < s.anyOf.length; i++) {
                const a = s.anyOf[i];
                s.anyOf[i] = await cache.resolveReferences(a);
            }
        }
        else if ('properties' in s) {
            const tmp = {};
            for (var key in s.properties) {
                if (key.substr(0, 1) === '$' && key !== '$ref') continue;
                try {
                    tmp[key] = await cache.resolveReferences(s.properties[key]);
                }
                catch (e) {
                    utils.log('Failed to resolve key: ' + key + '; ' + e);
                    throw e;
                }
            }
            s.properties = tmp;
        }
        else if ('items' in s) {
            s.items = await cache.resolveReferences(s.items);
        }

        if ('condition' in s && s.condition && typeof s.condition === 'string') {
            //utils.log('Compiling schema condition ' + s.condition);
            //s.condition_src$ = s.condition;
            s.$$condition = new Function('context', 'util', 'with (context) return ' + s.condition + ';');
        }

        return s;
    },
    async getSchema(id, throwOnError) {
        if (id in cache.schema)
            return cache.schema[id];
        else if (`/${id}` in cache.schema)
            return cache.schema[`/${id}`];

        utils.log('Schema cache MISS - loading ' + id);

        let path = id; // Apps/UIWhole/Schema/public/Platform.Schema.SchemaSchemas.v1/SchemaSchema_Type_String
        if (path.substr(0, 1) === '/') path = path.substr(1);

        try {
            if (path.substr(0, 18) === 'Apps/Schema/AnyOf/') {
                path = 'Apps/Schema/GetSchemaAnyOf/' + path.substr(18);

                const s = await utils.api.get(path, false, true, true);
                if (!('anyOf' in s)) throw 'Not a valid schema doc, missing anyOf property';

                cache.schema[id] = s;
                await cache.resolveReferences(s);
                return s;
            }
            else {
                path = 'Apps/UIWhole/' + path;

                const s = await utils.api.get(path, false, true, true);
                if (!('Schema' in s)) throw 'Not a valid schema doc, missing Schema property';

                if ('$ref' in s.Schema) {
                    s.Schema = await cache.getSchema(s.Schema['$ref']);
                    s.Schema.Id = path;
                }
                else {
                    cache.schema[id] = s.Schema;
                    await cache.resolveReferences(s.Schema);
                }

                return s.Schema;
            }
        }
        catch (e) {
            utils.warn('getSchema(' + id + ') failed', e);
            if (throwOnError)
                throw (e);

            return null;
        }
    }
};

const apicache = {
    urls: {}
};

const validation_patterns = {
    datetime: /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const utils = {
    baseuri: 'https://api.callcorplab.com',
    hubproxy: null,
    helpers: {
        extractFilters: extractFilters,
        convertSetValueToVueSet: convertSetValueToVueSet,
        chf: careHelpfulFunctions,
        parseUserExpression: parseUserExpression,
        tryParseInt: function (val, default_val, radix) {
            try {
                radix = radix || 10;
                default_val = default_val || 0;

                //validate this is not null or undefined
                if (val !== null && val !== undefined) {
                    //convert to integer
                    var that = parseInt(val, radix);

                    //check to see if it is not NaN, if not parse
                    if (isNaN(that))
                        return default_val;
                    else
                        return that;
                }
            }
            catch (err) {
                console.log(err);
            }
            //this is not a number
            return default_val;
        },
        dialogTitleImage: '',
        EventBus: EventBus,
    },
    actionDefaults: function () {
        return actionDefaults;
    },
    toggleVisible(control, key, default_value = 'true') {
        //const default_value = 'true';
        const value = control.visible_keys[key] || localStorage.getItem(key);

        if (!value) {
            // No setting, save the opposite of the default_value
            const new_value = default_value === 'true' ? 'false' : 'true';
            localStorage.setItem(key, new_value);
            Vue.set(control.visible_keys, key, new_value);
            utils.log('Saved ' + key + ' as ' + new_value);
        }
        else {
            const new_value = value === 'true' ? 'false' : 'true';
            if (new_value === default_value) {
                // When the value is the default, remove the entry
                localStorage.removeItem(key);
                Vue.delete(control.visible_keys, key);
                utils.log('Deleted ' + key);
            }
            else {
                // Otherwise, we must store the value
                localStorage.setItem(key, new_value);
                Vue.set(control.visible_keys, key, new_value);
                utils.log('Saved ' + key + ' as ' + new_value);
            }
        }
    },
    getVisibility(control, key, default_value = 'true') {
        //const default_value = 'true';
        let value = control.visible_keys[key];
        if (!value) {
            value = localStorage.getItem(key);
            if (value)
                Vue.set(control.visible_keys, key, value);
        }

        if (!value)
            return default_value === 'true';
        else
            return value === 'true';
    },
    log(text) {
        console.log(`${getFormattedDateNow()} ${text}`);
    },
    debug(text, styles, stacktrace) {
        console.debug(`%c${getFormattedDateNow()} ${text}`, styles || 'color: #008000;');
        if (stacktrace)
            console.trace();
    },
    warn(text, err) {
        console.warn(`${getFormattedDateNow()} ${text}`);
        if (err && err.stack)
            console.log(err.stack);
        else if (err && typeof err === 'object')
            console.log(JSON.stringify(err));
    },
    error(text, err) {
        console.error(`${getFormattedDateNow()} ${text}`);
        if (err && err.stack)
            console.log(err.stack);
        else if (err && typeof err === 'object')
            console.log(JSON.stringify(err));
    },
    generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    },
    setAccessToken(token) {
        cache.access_token = token;
    },
    childControlByName(children, name) {
        if (!children) return null;

        for (var i = 0; i < children.length; i++) {
            if (children[i].name == name)
                return children[i];

            let child = utils.childControlByName(children[i].$children, name);
            if (child) return child;
        }

        return null;
    },
    parseExpressionWithFilters: parseExpressionWithFilters,
    compile(control, text, debug, custommodelas) {
        if (text === null || typeof text === 'undefined') return null;
        if (!(typeof text === 'string')) return text;

        try {
            return parseUserExpression(text, debug, undefined, undefined, custommodelas);
        }
        catch (e) {
            utils.warn(`Failed to compile: ${text}`, e);
            return null;
        }
    },
    compileExpression(control, expression, custommodelas) {
        let expr;
        try {
            if (typeof expression === 'string')
            {
                expr = expression;

                if ((expr.startsWith('{%') || expr.startsWith('{#')) && (expr.endsWith('%}') || expr.endsWith('#}')))
                    expr = expr.substr(2, expr.length - 4);

                expr = parseExpressionWithFilters(expr);
            }
            else
                expr = expression;

            if (custommodelas && Array.isArray(custommodelas))
                return new ObjFunc(expression, new Function(['context', 'util', ...custommodelas].join(','), 'with (context) return ' + expr + ';'));
            else if (custommodelas)
                return new ObjFunc(expression, new Function('context', 'util', custommodelas, 'with (context) return ' + expr + ';'));
            else
                return new ObjFunc(expression, new Function('context', 'util', 'with (context) return ' + expr + ';'));
        }
        catch (e) {
            utils.warn(`Failed to compile expression: ${expr || expression}`, e);
            return null;
        }
    },
    compileObject(control, model, custommodelas) {
        return parseObjectWithUserExpressions(control, model, custommodelas);
    },
    compileDynamicObject(control, model) {
        const parsedObject = parseObjectWithUserExpressions(control, model);

        if (parsedObject instanceof ObjFunc)
            return parsedObject;
        else if (parsedObject === undefined || parsedObject === null)
            return parsedObject;
        else
            return new ObjProxy(parsedObject);
    },
    evaluate(compiledfunc, context, debug, haserrorfunc, throwerrors, custommodel, multiplecustommodels) {
        if (!compiledfunc || !compiledfunc.func || !(compiledfunc instanceof ObjFunc))
            return compiledfunc;

        if (debug)
            debugger;

        try {
            let ctx;
            if (context && ('_self' in context))
                ctx = context._self;
            else
                ctx = context;

            if (multiplecustommodels)
                return compiledfunc.func.apply(null, [ctx, careHelpfulFunctions, ...custommodel]);
            else
                return compiledfunc.func(ctx, careHelpfulFunctions, custommodel);
        }
        catch (e) {
            let msg = `Failed to evaluate - [${compiledfunc.code}] in ${context.name || context.Name || context.type || 'unnamed'} : ${e}`;
            if (haserrorfunc)
                msg += ' - attempt will be retried';

            utils.warn(msg, e);
            if (haserrorfunc)
                haserrorfunc();
            if (throwerrors)
                throw(e);
            return null;
        }
    },
    evaluateObject(compiledobj, context, custommodel, multiplecustommodels) {
        try {
            let ctx;
            if (context && ('_self' in context))
                ctx = context._self;
            else
                ctx = context;

            return evaluateUserExpression(compiledobj, ctx, custommodel, multiplecustommodels);
        }
        catch (e) {
            if (typeof compiledobj === 'object' && compiledobj instanceof ObjFunc)
                utils.warn('Failed to evaluate object - ' + compiledobj.code + ' : ' + e, e);
            else if (typeof compiledobj === 'object')
                utils.warn('Failed to evaluate object - ' + decompileObject(compiledobj) + ' : ' + e, e);

            return null;
        }
    },
    evaluateDynamicObject(compiledobj, context) {
        try {
            if (compiledobj instanceof ObjFunc)
                return evaluateUserExpression(compiledobj, context);
            else if (compiledobj instanceof ObjProxy) {
                compiledobj.handler.context = context;
                return compiledobj.myproxy();
            }
            else {
                utils.warn(`evaluateObject called on a non-compiled object`);
                return compiledobj;
            }
        }
        catch (e) {
            if (typeof compiledobj === 'object' && compiledobj instanceof ObjFunc)
                utils.warn('Failed to evaluate object - ' + compiledobj.code + ' : ' + e, e);
            else if (typeof compiledobj === 'object')
                utils.warn('Failed to evaluate object - ' + decompileObject(compiledobj) + ' : ' + e, e);

            return null;
        }
    },
    async compileActions(control, actions) {
        if (!actions)
            return [];

        const compiled_actions = [];
        for (let i = 0; i < actions.length; i++)
            compiled_actions.push(await utils.compileAction(control, actions[i]));
        return compiled_actions;
    },
    async compileAction(control, action) {
        try {
            //utils.log('[compileAction] Compiling ' + action.ActionType + '...');

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint)
                debugger;

            try {
                let actionExpn;
                if (action.ActionExpression)
                    actionExpn = utils.compileExpression(control, action.ActionExpression);
                else
                    actionExpn = null;

                let targetExpn;
                if (action.ActionBroadcastTarget)
                    targetExpn = utils.compile(control, action.ActionBroadcastTarget);
                else
                    targetExpn = null;

                // Declare a new object for the compiled action, and copy the existing action definition (top level deep, nested objects are shallow, which works well in this case)
                const action_compiled = { ...action };

                action.isCompiled = true;

                // The compiled property is a function that returns the actual compiled version, so Vue won't make it reactive.
                // When we go to execute the action, if we have only the original object, we can read the compiled version through
                // this function. No other top-level members of the original action will be modified, as this would trigger Vue.
                action.compiled = () => action_compiled;

                action_compiled.actionExpression = actionExpn;
                action_compiled.targetExpn = targetExpn;
                action_compiled.paramdatafunc = action.actiondatafunc;

                action_compiled.context = {
                    $parent: control,
                    $createElement: control.$createElement,
                    $attrs: {},
                    name: (action.ActionData ? action.ActionData.Name : null) || ('Action:' + action.ActionType),
                    root: control.root,
                    Parent: control,
                    Root: control.Root,
                    Base: control.Base,
                    System: control.System,
                    GlobalVars: control.GlobalVars,
                    ...methods,
                    ...filters,
                };

                if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.LogLevel && action.ActionData.Debug.LogMessage)
                    action_compiled.logmessage = utils.compile(control, action.ActionData.Debug.LogMessage);

                if (action.ActionData && action.ActionData.Vars)
                    action_compiled.vars = utils.compileObject(control, action.ActionData.Vars);

                // Moved to executeAction (ctx, i.e. context, must be the current context on each execution - known only at execute time :( so sad)
                //if (action.ActionData && action.ActionData.Javascript) {
                //    const api = utils.apiWrapper;
                //    const ctx = action_compiled.context; // control;
                //    const chf = careHelpfulFunctions;
                //    action_compiled.context.Javascript = eval("new (" + action.ActionData.Javascript + ")(null, null, api, ctx, chf);");

                //    for (let key in action_compiled.context.Javascript)
                //        if (typeof action_compiled.context.Javascript[key] === 'function')
                //            action_compiled.context.Javascript[key] = action_compiled.context.Javascript[key].bind(action_compiled.context.Javascript);
                //}

                // Moved to executeAction
                //for (let key in action_compiled.context)
                //    if (typeof action_compiled.context[key] === 'function')
                //        action_compiled.context[key] = action_compiled.context[key].bind(action_compiled.context);

                //const args = [];
                //if (action.ActionData)
                //    for (let key in action.ActionData)
                //        if (!(typeof action.ActionData[key] === 'object') && !key.startsWith('$'))
                //            args.push(`${key}:${action.ActionData[key]}`);

                //utils.log(`Compiling Action ${action.ActionType} ActionData:${JSON.stringify(args)}`);

                switch (action.ActionType) {
                    case 'ReferenceUserAction':
                        utils.log('[compileAction] Loading ReferenceUserAction: ' + action.ActionData.UserAction.ActionURL);

                        //utils.log('[compileAction] ReferenceUserAction compiling ActionURL...');

                        // Support interpolation for ActionURL
                        const actionURL = utils.compile(control, action.ActionData.UserAction.ActionURL);

                        try {
                            const url = utils.evaluate(actionURL, control);

                            let apiRequest = {
                                method: 'GET',
                                url: `Document/Action/${url}`,
                                doNotUseWebsocket: true,
                                flatten: true,
                                cache: action.ActionData.UserAction.CacheAction
                            };

                            const res = await apiService.apiRequest(apiRequest);

                            //utils.log(`[compileAction] ReferenceUserAction compiling new action ${res.ActionType}...`);

                            await utils.compileAction(control, res);

                            if (action.ActionData.UserAction.ActionData) {
                                // Get the compiled action from the newly compiled version so that I can save essential data in it
                                const resolved_compiled = res.compiled();

                                // Build a function that will generate action data to be read as ParamByName(...)
                                resolved_compiled.actiondatafunc = utils.compileObject(control, action.ActionData.UserAction.ActionData);
                                resolved_compiled.preactionExpn = actionExpn;
                                // Save ActionData so we can all it's SuccessActions, FailureActions, CompleteActions
                                // resolved_compiled.originalActionData = action.ActionData;
                            }
                            action_compiled.referencedAction = res;
                        }
                        catch (e)
                        {
                            utils.log(`Compiling only URL for ReferencedUserAction URL:${action.ActionData.UserAction.ActionURL} - deferring to runtime`);

                            action_compiled.referencedAction = null;
                            action_compiled.actionURL = actionURL;
                        }

                        // Change the function that returns the compiled action to now return the resolved and compiled action
                        // action.compiled = res.compiled;
                        break;

                    case 'Alert':
                        action_compiled.message = utils.compile(control, action.ActionData.Message);
                        break;

                    case 'ApiRequest':
                        action_compiled.method = utils.compile(control, action.ActionData.Method);
                        action_compiled.url = utils.compile(control, action.ActionData.URL);
                        action_compiled.data = action.ActionData.Data ? utils.compileObject(control, action.ActionData.Data) : null;

                        if (action.ActionData.Headers && typeof action.ActionData.Headers === 'string') {
                            // Interpolate all headers as an object
                            action_compiled.headers_fn = utils.compileObject(control, action.ActionData.Headers);
                        }
                        else if (action.ActionData.Headers && Array.isArray(action.ActionData.Headers) && action.ActionData.Headers.length > 0) {
                            // Interpolate headers one at a time
                            action_compiled.headers_list = [];
                            for (let i = 0; i < action.ActionData.Headers.length; i++) {
                                const h = action.ActionData.Headers[i];
                                const namefn = utils.compile(control, h.Name);
                                const valuefn = utils.compile(control, h.Value);
                                action_compiled.headers_list.push({
                                    namefn: namefn,
                                    valuefn: valuefn,
                                });
                            }
                        }

                        if (action.ActionData.APIProvider)
                            action_compiled.apiprovider = utils.compileExpression(control, action.ActionData.APIProvider);

                        if (action.ActionData.Toast && action.ActionData.Toast.SuccessToast && action.ActionData.Toast.SuccessToast.Title)
                            action_compiled.ActionData.Toast.SuccessToast.title = utils.compile(control, action.ActionData.Toast.SuccessToast.Title);

                        if (action.ActionData.Toast && action.ActionData.Toast.SuccessToast && action.ActionData.Toast.SuccessToast.Message)
                            action_compiled.ActionData.Toast.SuccessToast.message = utils.compile(control, action.ActionData.Toast.SuccessToast.Message);

                        if (action.ActionData.Toast && action.ActionData.Toast.ErrorToast && action.ActionData.Toast.ErrorToast.Title)
                            action_compiled.ActionData.Toast.ErrorToast.title = utils.compile(control, action.ActionData.Toast.ErrorToast.Title);

                        if (action.ActionData.Toast && action.ActionData.Toast.ErrorToast && action.ActionData.Toast.ErrorToast.Message)
                            action_compiled.ActionData.Toast.ErrorToast.message = utils.compile(control, action.ActionData.Toast.ErrorToast.Message);

                        break;

                    case 'Badge':
                        action_compiled.type = utils.compile(control, action.ActionData.Type);
                        action_compiled.key = utils.compile(control, action.ActionData.Key);
                        action_compiled.value = utils.compile(control, action.ActionData.Value);
                        break;

                    case 'OpenChildWindow':
                        if (action.ActionData.TargetWindowName)
                            action_compiled.targetWindowName = utils.compile(control, action.ActionData.TargetWindowName);
                        
                        if (action.ActionData.URL)
                            action_compiled.URL = utils.compile(control, action.ActionData.URL);

                        if (action.ActionData.Specs)
                            action_compiled.specs = utils.compile(control, action.ActionData.Specs);

                        break;

                    case 'SetValue':
                        let value;
                        if (action.ActionData.Value && (((typeof action.ActionData.Value === 'string') && action.ActionData.Value.substr(0, 2) === '{#') || typeof action.ActionData.Value === 'object'))
                            value = utils.compileObject(control, action.ActionData.Value);
                        else
                            value = utils.compile(control, action.ActionData.Value);

                        //Ex: transforms
                        // Base.ChildControlByName('resultform',true).Model.data = value
                        //into
                        // Vue.set(Base.ChildControlByName('resultform',true).Model, 'data', value)

                        //Ex: transforms
                        // VarByName('BLegHSMs')[Input.HSM.UniqueID] = value
                        //into
                        // Vue.set(VarByName('BLegHSMs'), Input.HSM.UniqueID, value)

                        let setvalue = convertSetValueToVueSet(action.ActionData.Field, 'value');
                        let code = `with (context) { ${setvalue}; }`;

                        action_compiled.expression = { code: code, func: new Function('value', 'context', 'util', 'vue', code) };
                        action_compiled.value = value;
                        break;

                    case 'DeleteValue':
                        if (action.ActionData.IsArray) {
                            const index = utils.compileExpression(control, action.ActionData.ArrayIndex);
                            const code = `with (context) {${action.ActionData.Array}.splice($$deleteValueIndex,1); }`;
                            const expr = new Function('$$deleteValueIndex', 'context', 'util', 'Vue', code);

                            action_compiled.propname = index;
                            action_compiled.expr = { code: code, func: expr };
                        }
                        else {
                            const propname = utils.compile(control, action.ActionData.PropertyName);
                            const code = `with (context) { Vue.delete(${action.ActionData.Object}, $$deletePropName); }`;
                            const expr = new Function('$$deletePropName', 'context', 'util', 'Vue', code);

                            action_compiled.propname = propname;
                            action_compiled.expr = { code: code, func: expr };
                        }
                        break;

                    case "EvaluateExpression":
                        action_compiled.expression = utils.compileExpression(control, action.ActionData.Expression);
                        break;

                    case 'IfElse':
                        for (let i = 0; i < action_compiled.ActionData.Expressions.length; i++) {
                            const e = action_compiled.ActionData.Expressions[i];
                            if (e.Expression)
                                e.expression = utils.compileExpression(control, e.Expression);
                        }
                        break;

                    case 'Close':
                        let dialogResult;
                        if (action.ActionData && action.ActionData.DialogResult !== undefined)
                            dialogResult = utils.compileObject(control, action.ActionData.DialogResult);

                        action_compiled.dialogResult = dialogResult;
                        break;

                    case 'ForEach':
                        let source;
                        if (action.ActionData && action.ActionData.Source !== undefined)
                            source = utils.compileObject(control, action.ActionData.Source);

                        action_compiled.source = source;
                        break;

                    case 'Tab':
                        if (action.ActionData.TabID)
                            action_compiled.tabID = utils.compile(control, action.ActionData.TabID);

                        if (action.ActionData.TabTitle)
                            action_compiled.tabTitle = utils.compile(control, action.ActionData.TabTitle);

                        if (action.ActionData.ControlData && action.ActionData.ControlData.Vars)
                            action_compiled.varsobj = utils.compileObject(control, action.ActionData.ControlData.Vars);

                        break;

                    case 'ReplaceContent':
                        action_compiled.controlData = { ...action.ActionData.ControlData };

                        if (action.ActionData.ControlData.Vars)
                            action_compiled.varsexpn = utils.compileObject(control, action.ActionData.ControlData.Vars);

                        if (action.ActionData.ControlData.BreadCrumb && action.ActionData.ControlData.BreadCrumb.Text)
                            action_compiled.breadcrumbtextexpn = utils.compile(control, action.ActionData.ControlData.BreadCrumb.Text);

                        break;

                    case 'DisplayPopup':
                        if (action.ActionData.Title)
                            action_compiled.title = utils.compile(control, action.ActionData.Title);

                        if (action.ActionData.Body)
                            action_compiled.body = utils.compile(control, action.ActionData.Body);

                        if (action.ActionData.Timeout)
                            action_compiled.timeout = utils.compileExpression(control, action.ActionData.Timeout);
                        break;

                    case 'ClosePopup':
                        if (action.ActionData.PopupName)
                            action_compiled.popupname = utils.compile(control, action.ActionData.PopupName);
                        break;

                    case 'DisplayConfirmModal':
                        if (action.ActionData.Title)
                            action_compiled.title = utils.compile(control, action.ActionData.Title);

                        if (action.ActionData.Message)
                            action_compiled.message = utils.compile(control, action.ActionData.Message);

                        if (action.ActionData.OkayButtonText)
                            action_compiled.okaybuttontext = utils.compile(control, action.ActionData.OkayButtonText);

                        if (action.ActionData.CancelButtonText)
                            action_compiled.cancelbuttontext = utils.compile(control, action.ActionData.CancelButtonText);
                        break;

                    case 'LogEntry':
                        if (action.ActionData.Message)
                            action_compiled.message = utils.compile(control, action.ActionData.Message);
                        break;

                    case 'RenameTab':
                        if (action.ActionData.TabTitle)
                            action_compiled.tabtitle = utils.compile(control, action.ActionData.TabTitle);
                        break;

                    case 'Broadcast':
                        if (action.ActionData.Message)
                            action_compiled.message = utils.compile(control, action.ActionData.Message);

                        if (action.ActionData.MessageData)
                            action_compiled.messagedata = utils.compileObject(control, action.ActionData.MessageData);
                        break;

                    case 'SelectMenuItem':
                        if (action.ActionData.MenuItemName)
                            action_compiled.menuname = utils.compile(control, action.ActionData.MenuItemName);
                        break;

                    case 'DisplayFeedback':
                        utils.debug(`Compiling DisplayFeedback with ${action.ActionData.Messages?.length || 0} messages`);
                        if (typeof action.ActionData.Messages === 'object' && Array.isArray(action.ActionData.Messages)) {
                            action_compiled.messages = [];
                            for (let i = 0; i < action.ActionData.Messages.length; i++) {
                                const m = action.ActionData.Messages[i];
                                if (m.Message)
                                    action_compiled.messages.push({
                                        messagetype: m.MessageType || 'Info',
                                        message: utils.compile(control, m.Message)
                                    });
                            }
                        }
                        else if (typeof action.ActionData.Messages === 'string') {
                            // Interpolated
                            action_compiled.messages_expn = utils.compileObject(action.context, action.ActionData.Messages);
                        }

                        if (action.ActionData.MessageGroup)
                            action_compiled.messagegroup = utils.compile(control, action.ActionData.MessageGroup);

                        break;

                    case 'DisplayNotificationHeader':
                    case 'RemoveNotificationHeader':
                        if (action.ActionData.NotificationHeaderGroup)
                            action_compiled.groupexpn = utils.compile(control, control.Translate(action.ActionData.NotificationHeaderGroup));
                        break;

                    case 'DisplayDesktopNotify':
                        action_compiled.titleexpn = utils.compile(control, control.Translate(action.ActionData.Title));
                        action_compiled.bodyexpn = utils.compile(control, control.Translate(action.ActionData.Body));
                        action_compiled.firstActionTitleexpn = utils.compile(control, control.Translate(action.ActionData.FirstActionTitle));
                        action_compiled.secondActionTitleexpn = utils.compile(control, control.Translate(action.ActionData.SecondActionTitle));

                        action_compiled.icoexpnn = utils.compile(control, action.ActionData.Icon);
                        action_compiled.firstActionIconexpn = utils.compile(control, action.ActionData.FirstActionIcon);
                        action_compiled.secondActionIconexpn = utils.compile(control, action.ActionData.SecondActionIcon);
                        action_compiled.thecontextexpn = utils.compile(control, action.ActionData.Context);
                        break;

                    case 'CloseDesktopNotify':
                        action_compiled.desktopNotifyName = utils.compile(control, action.ActionData.DesktopNotifyName);
                        action_compiled.desktopNotifyId = utils.compile(control, action.ActionData.DesktopNotifyId);
                        break;

                    case 'UserIdleReset':
                        action_compiled.timeoutMinutes = utils.compile(control, action.ActionData.TimoutMinutes);
                        action_compiled.optIn = utils.compile(control, action.ActionData.OptIn);
                        break;

                    case 'UserIdleEnable':
                        action_compiled.enable = utils.compile(control, action.ActionData.Enable);
                        break;

                    case 'OpenChildWindowWithToken':
                        action_compiled.targetWindowName = utils.compile(control, action.ActionData.TargetWindowName);
                        action_compiled.url = utils.compile(control, action.ActionData.URL);
                        action_compiled.specs = utils.compile(control, action.ActionData.Specs);
                        action_compiled.tokenSource = utils.compile(control, action.ActionData.TokenSource);
                        action_compiled.windowTitle = utils.compile(control, action.ActionData.WindowTitle);
                        action_compiled.titleBarName = utils.compile(control, action.ActionData.TitleBarName);
                        action_compiled.doNotInheritSessionStorage = utils.compile(control, action.ActionData.DoNotInheritSessionStorage);
                        action_compiled.accessToken = utils.compile(control, action.ActionData.AccessToken);
                        action_compiled.refreshToken = utils.compile(control, action.ActionData.RefreshToken);
                        break;

                    case 'HSMStartServerEventListener':
                        action_compiled.sessionid = utils.compile(this, action.ActionData.SessionID);
                        action_compiled.eventname = utils.compile(this, action.ActionData.EventName);
                        break;

                    case 'HSMStopServerEventListener':
                        action_compiled.sessionid = utils.compile(this, action.ActionData.SessionID);
                        action_compiled.eventname = utils.compile(this, action.ActionData.EventName);
                        break;

                    case 'HSMAddChildHSM':
                        action_compiled.newid = utils.compile(this, action.ActionData.NewId);
                        action_compiled.initialstatepath = utils.compile(this, action.ActionData.InitialStatePath);
                        action_compiled.initialstatedata = utils.compile(this, action.ActionData.InitialStateData);
                        break;

                    case 'HSMRemoveChildHSM':
                        action_compiled.uniqueid = utils.compile(this, action.ActionData.UniqueID);
                        break;

                    case 'HSMEvent':
                        action_compiled.eventName = utils.compile(this, action.ActionData.EventName);
                        if (action.ActionData.Data)
                            action_compiled.data = utils.compileObject(this, action.ActionData.Data);
                        break;

                    case 'HSMSetNextState':
                        action_compiled.nextstatepath = utils.compile(this, action.ActionData.NextStatePath);
                        if (action.ActionData.Data)
                            action_compiled.data = utils.compileObject(this, action.ActionData.Data);
                        break;

                    case 'SetLanguage':
                        action_compiled.language = utils.compile(this, action.ActionData.Language);
                        break;

                    case 'FileDownload':
                        action_compiled.fileURL = utils.compile(this, action.ActionData.FileURL);
                        action_compiled.target = utils.compile(this, action.ActionData.Target);
                        action_compiled.filename = utils.compile(this, action.ActionData.FileName);
                        break;

                    case 'EmitControlEvent':
                        action_compiled.eventName = utils.compile(this, action.ActionData.EventName);
                        action_compiled.input = utils.compileObject(this, action.ActionData.Input);
                        break;

                    case 'RunControlCommand':
                        action_compiled.controlName = utils.compile(this, action.ActionData.ControlName);
                        action_compiled.commandName = utils.compile(this, action.ActionData.CommandName);
                        action_compiled.input = utils.compileObject(this, action.ActionData.Input);
                        break;

                    case 'RunActions':
                        action_compiled.actions = utils.compileObject(this, action.ActionData.Actions);
                        if (action.ActionData.Context)
                            action_compiled.newcontext = utils.compileObject(this, action.ActionData.Context);
                        break;

                    case 'OpenDocumentEditor':
                        action_compiled.docName = utils.compile(this, action.ActionData.DocumentName);
                        action_compiled.docUrl = utils.compile(this, action.ActionData.DocumentURL);
                        action_compiled.docType = utils.compile(this, action.ActionData.DocumentType);
                        action_compiled.defaultFolder = utils.compile(this, action.ActionData.DefaultFolder);
                        action_compiled.menuId = utils.compile(this, action.ActionData.MenuItemId);
                        action_compiled.getDocumentBy = utils.compile(this, action.ActionData.GetDocumentBy);
                        break;

                    case 'Message':
                        action_compiled.message = utils.compile(this, action.ActionData.Message);
                        action_compiled.messagedata = utils.compileObject(this, action.ActionData.MessageData);
                        break;

                }
                return action;
            }
            finally {
                //utils.log('[compileAction] Compiling ' + action.ActionType + ' complete');
            }
        }
        catch (e) {
            const args = [];
            if (action.ActionData && (typeof action.ActionData === 'object'))
                for (let key in action.ActionData)
                    if (!(typeof action.ActionData[key] === 'object') && !key.startsWith('$'))
                        args.push(`${key}:${action.ActionData[key]}`);

            utils.error(`compileAction(action.ActionType:${action.ActionType} ActionData:${JSON.stringify(args)}) failed: ${e}`, e);
            return null;
        }
    },
    async executeAndCompileAllActions(actions, input, context, controlscope, scopeitems) {
        if (actions)
            for (var i = 0; i < actions.length; i++) {
                const a = actions[i];
                if (!a.isCompiled || this.forceRecompile(a))
                    await utils.compileAction(context, a);

                //utils.document_cache.actionsInProgress[context.Location + '-' + a.ActionType] = a;
                await utils.executeAction(a, input, context, controlscope, scopeitems);
                //delete utils.document_cache.actionsInProgress[context.Location + '-' + a.ActionType];
            }
    },
    async executeAndCompileAction(action, input, context, controlscope, scopeitems) {
        if (!action.isCompiled || this.forceRecompile(action))
            await utils.compileAction(context, action);

        return await utils.executeAction(action, input, context, controlscope, scopeitems);
    },
    executeAction(action, input, context, controlscope, scopeitems) {
        if (!action.isCompiled)
            throw `executeAction attempted to run an action that hasn't been compiled yet: ActionType:${action.ActionType}`;

        const a = action.compiled();

        if (a.ActionData && a.ActionData.Debug && a.ActionData.Debug.BreakPoint)
            debugger;

        a.context.$parent = context;
        a.context.Input = input;
        a.context.Base = context.Base;
        a.context.Self = a.context;
        a.context.type = a.ActionType;

        a.context.root = context.root;
        a.context.Parent = context;
        a.context.Root = context.Root;

        a.context.Vars = a.vars ? utils.evaluateObject(a.vars, a.context) : {};
        a.context.controlscope = controlscope || context.controlscope;
        a.context.scopeitems = scopeitems || context.scopeitems;
        a.context.$children = context.$children;

        a.context.Control = a.context.scopeitems; // controlscope || context.controlscope;
        a.context.Params = controlscope || context.controlscope;

        if (a.actiondatafunc)
            a.context.paramData = utils.evaluateObject(a.actiondatafunc, context);

        if (a.ActionData && a.ActionData.Javascript) {
            const api = apiService;
            const ctx = a.context; // control;
            const chf = careHelpfulFunctions;
            a.context.Javascript = eval("new (" + a.ActionData.Javascript + ")(null, null, api, ctx, chf);");

            for (let key in a.context.Javascript)
                if (typeof a.context.Javascript[key] === 'function')
                    a.context.Javascript[key] = a.context.Javascript[key].bind(a.context.Javascript);
        }

        for (let key in a.context)
            if (typeof a.context[key] === 'function')
                a.context[key] = a.context[key].bind(a.context);

        const args = [];
        if (a.ActionData)
            for (let key in a.ActionData)
                if (!(typeof a.ActionData[key] === 'object') && !key.startsWith('$'))
                    args.push(`${key}:${a.ActionData[key]}`);

        if (a.actionExpression && !utils.evaluate(a.actionExpression, a.context)) {
            //utils.log(` *** Skipped invoking Action ${a.ActionType} ActionExpression:'${a.ActionExpression}'==false ActionData:${JSON.stringify(args)}`);
            return Promise.resolve();
        }
        else if (a.preactionExpn && !utils.evaluate(a.preactionExpn, a.context)) {
            //utils.log(` *** Skipped invoking Action ${a.ActionType} Pre-ActionExpression:'${a.preactionExpn.code}'==false ActionData:${JSON.stringify(args)}`);
            return Promise.resolve();
        }

        if (a.logmessage)
            try {
                switch (a.ActionData.Debug.LogLevel) {
                    case 'Info': utils.log(utils.evaluate(a.logmessage, a.context)); break;
                    case 'Debug': utils.debug(utils.evaluate(a.logmessage, a.context)); break;
                    case 'Error': utils.error(utils.evaluate(a.logmessage, a.context)); break;
                }
            }
            catch (e) { }

        ////** Angular version uses CareGlobals.js which defines all actions and their default value **////
        if (!a.ActionBroadcastDirection) {
            if (a.ActionType in actionDefaults)
                a.ActionBroadcastDirection = actionDefaults[a.ActionType].defaultBroadcastDirection;
        }

        if (a.ActionBroadcastDirection == "GlobalNoTarget_BaseContainerChildrenWithTarget")
            a.ActionBroadcastDirection = a.ActionBroadcastTarget ? "BaseContainerChildren" : "Global";

        let targetValue;
        if (a.targetExpn)
            targetValue = utils.evaluate(a.targetExpn, a.context);

        let target;
        if (a.ActionBroadcastDirection) {
            switch (a.ActionBroadcastDirection) {
                case 'Parent':
                    target = a.context.FindParentWithEventListener(`Action-${a.ActionType}:${targetValue}`);
                    if (target)
                        return utils.invokeActionEvent(target, `Action-${a.ActionType}:${targetValue}`, a, args);

                    target = a.context.FindParentWithEventListener(`Action-${a.ActionType}`, targetValue);
                    if (target)
                        return utils.invokeActionEvent(target, `Action-${a.ActionType}`, a, args);
                    else
                        return Promise.resolve();

                case 'BaseContainerChildren':
                    target = context.Base.FindChildWithEventListener(`Action-${a.ActionType}:${targetValue}`);
                    if (target)
                        return utils.invokeActionEvent(target, `Action-${a.ActionType}:${targetValue}`, a, args);

                    target = context.Base.FindChildWithEventListener(`Action-${a.ActionType}`, targetValue);
                    if (target)
                        return utils.invokeActionEvent(target, `Action-${a.ActionType}`, a, args);
                    else
                        return Promise.resolve();

                case 'Children':
                    target = a.context.FindChildWithEventListener(`Action-${a.ActionType}:${targetValue}`);
                    if (target)
                        return utils.invokeActionEvent(target, `Action-${a.ActionType}:${targetValue}`, a, args);

                    target = a.context.FindChildWithEventListener(`Action-${a.ActionType}`);
                    if (target)
                        return utils.invokeActionEvent(target, `Action-${a.ActionType}`, a, args);
                    else
                        return Promise.resolve();

                case 'Global':
                    return utils.invokeActionBroadcastGlobal(a, args, targetValue);
            }
        }
        else
            return utils.invokeActionBroadcastGlobal(a, args);
    },
    invokeActionEvent(target, msg, a, args) {
        //let msg = `Action-${a.ActionType}`;

        if (a.RunAsync) {
            //utils.log(`*** Invoking Action ${a.ActionType} ASYNC ActionData:${JSON.stringify(args)}`);

            a.FinishFunc = function (success, result) { };

            //utils.log(`executeAction emitting to ${target.name || target.type} ${a.ActionType} async...`);

            target.$emit(msg, a);

            return Promise.resolve();
        }

        //utils.log(`*** Invoking Action ${a.ActionType} ActionData:${JSON.stringify(args)}...`);

        let resolver, rejecter;

        const promise = new Promise(function (resolve, reject) {
            resolver = resolve;
            rejecter = reject;
        });

        // Send event and then use a Promise to wait for the response, this allows the caller of executeAction to use await.
        a.FinishFunc = function (success, result) {
            if (success) {
            //    utils.log(`*** Succeeded invoking Action ${a.ActionType} ActionData:${JSON.stringify(args)}`);

                resolver(result);
            }
            else {
                utils.log(`*** Failed invoking Action ${a.ActionType} ActionData:${JSON.stringify(args)}`);

                rejecter(result);
            }
        };
        // Add these for manual action completion
        a.ActionPromiseResolve = function (result) {
            //utils.log(`*** Succeeded invoking Action ${a.ActionType} ActionData:${JSON.stringify(args)}`);
            resolver(result);
        };
        a.ActionPromiseReject = function (result) {
            utils.log(`*** Failed invoking Action ${a.ActionType} ActionData:${JSON.stringify(args)}`);
            rejecter(result);
        };

        //utils.log(`executeAction emitting to ${target.name || target.type} ${a.ActionType}...`);
        target.$emit(msg, a);

        return promise;
    },
    invokeActionBroadcastGlobal(a, args, target) {
        let msg = `Action-${a.ActionType}`;
        if (target)
            msg += `:${target}`;

        if (a.RunAsync) {
            //utils.log(`*** Invoking Action Global broadcast ${a.ActionType} ASYNC ActionData:${JSON.stringify(args)}`);

            a.FinishFunc = function (success, result) { };

            //utils.log(`executeAction emitting ${a.ActionType} async...`);
            EventBus.$emit(msg, a);

            return Promise.resolve();
        }

        //utils.log(`*** Invoking Action Global broadcast ${a.ActionType} ActionData:${JSON.stringify(args)}...`);

        let resolver, rejecter;

        const promise = new Promise(function (resolve, reject) {
            resolver = resolve;
            rejecter = reject;
        });

        // Send event and then use a Promise to wait for the response, this allows the caller of executeAction to use await.
        a.FinishFunc = function (success, result) {
            if (success) {
                //utils.log(`*** Succeeded invoking Action Global broadcast ${a.ActionType} ActionData:${JSON.stringify(args)}`);

                resolver(result);
            }
            else {
                //utils.log(`*** Failed invoking Action Global broadcast ${a.ActionType} ActionData:${JSON.stringify(args)}`);

                rejecter(result);
            }
        };

        EventBus.$emit(msg, a);

        return promise;
    },
    async forceRecompile(action)
    {
        const expression = action.ActionData?.UserAction?.CacheAction;
        
        if(expression !== null && expression !== undefined)
        {
            return expression === false || expression === 'false';
        }
        return false;
    },
    async success(action, input) {
        //utils.log(`Action:${action.ActionType} success`);

        if (action.ActionData.SuccessActions)
            for (let i = 0; i < action.ActionData.SuccessActions.length; i++) {
                const a = action.ActionData.SuccessActions[i];
                try {
                    if (!a.isCompiled || this.forceRecompile(a)) {
                        await utils.compileAction(action.context, a);
                    }

                    await utils.executeAction(a, input, action.context);
                }
                catch (e) {
                    utils.warn('executeAction ' + a.ActionType + ' failed: ' + e);
                }
            }
    },
    async failure(action, input) {
        //utils.log(`Action:${action.ActionType} failure`);

        if (action.ActionData.FailureActions) {
            for (let i = 0; i < action.ActionData.FailureActions.length; i++) {
                const a = action.ActionData.FailureActions[i];
                try {
                    if (!a.isCompiled || this.forceRecompile(a)) {
                        await utils.compileAction(action.context, a);
                    }

                    await utils.executeAction(a, input, action.context);
                }
                catch (e) {
                    utils.warn('executeAction ' + a.ActionType + ' failed: ' + e);
                }
            }
        }
    },
    async complete(action, input) {
        //utils.log(`Action:${action.ActionType} complete`);

        if (action.ActionData.CompleteActions)
            for (let i = 0; i < action.ActionData.CompleteActions.length; i++) {
                const a = action.ActionData.CompleteActions[i];
                try {
                    if (!a.isCompiled || this.forceRecompile(a)) {
                        await utils.compileAction(action.context, a);
                    }

                    await utils.executeAction(a, input, action.context);
                }
                catch (e) {
                    utils.warn('executeAction ' + a.ActionType + ' failed: ' + e);
                }
            }
    },
    findNodePath(path, parent, node) {
        if (parent == node)
            return true;

        if (path.length > 60)
            throw 'findNodePath is too deep. > 60; ' + JSON.stringify(path.map((a) => a.name || a.index));

        const p = path.map((a) => a.name || a.index);

        if (Array.isArray(parent)) {
            for (let i = 0; i < parent.length; i++) {
                const item = parent[i];

                if (typeof item === 'object' && !Array.isArray(item)) {
                    path.push({ index: i, node: parent, childIsArray: Array.isArray(item) });

                    if (utils.findNodePath(path, item, node))
                        return true;

                    path.pop();
                }
            }
        }
        else {
            for (let key in parent) {
                if (key.substr(0, 1) === '$' || key === 'anyOf' || key === 'allOf' || key === 'oneOf' || key === 'elm') continue;
                if (key === '$schema')
                    // Never traverse a schema - they are recusive
                    return false;

                const item = parent[key];

                if (typeof item === 'object') {
                    path.push({ name: key, node: parent, childIsArray: Array.isArray(item) });

                    if (utils.findNodePath(path, item, node))
                        return true;

                    path.pop();
                }
            }
        }
        return false;
    },
    async setSchemaBootstrap(s) {
        await cache.setBoostrap(s);
    },
    getDynamicComponent(h, control, userControlName) {
        let DynamicControl;
        if (control.PrimitiveControl === false) {
            // Handler for user control
            DynamicControl = userControlName || 'dynamic-user-control';
        }
        else
            switch (control.ControlType) {
                case 'ExperimentalTab': DynamicControl = 'experimental-tab'; break;

                case 'Accordion': DynamicControl = 'accordion'; break;
				case 'ACDDirectButton': DynamicControl = 'acd-direct-button'; break;
                case 'ActionService': DynamicControl = 'action-service'; break;
                case 'AdvancedHistory': DynamicControl = 'advanced-history'; break;
                case 'AgGrid': DynamicControl = 'cc-ag-grid'; break;
                case 'ApexGeneralChart': DynamicControl = 'apex-general-chart'; break;
                case 'AppleButton': DynamicControl = 'apple-button'; break;
                case 'AudioPlayer': DynamicControl = 'audio-player'; break;
                case 'AutoComplete': DynamicControl = 'auto-complete'; break;
                case 'BadgeHandler': DynamicControl = 'badge-handler'; break;
                case 'BarChart': DynamicControl = 'bar-chart'; break;
                case 'BasicButton': DynamicControl = 'basic-button'; break;
                case 'BasicFileUpload': DynamicControl = 'basic-file-upload'; break;
                case 'BasicForm': DynamicControl = 'basic-form'; break;
                case 'BasicGrid': DynamicControl = 'basic-grid'; break;
                case 'BasicInput': DynamicControl = 'basic-input'; break;
                case 'BasicLink': DynamicControl = 'basic-link'; break;
                case 'BasicPopupMenu': DynamicControl = 'basic-popup-menu'; break;
                case 'BasicSwitch': DynamicControl = 'basic-switch'; break;
                case 'BasicTab': DynamicControl = 'basic-tab'; break;
                case 'BasicToggle': DynamicControl = 'basic-toggle'; break;
                case 'BasicTree': DynamicControl = 'basic-tree'; break;
                case 'BroadcastListener': DynamicControl = 'broadcast-listener'; break;
                case 'CardList': DynamicControl = 'card-list'; break;
                case 'CheetahGrid': DynamicControl = 'cc-cheetah-grid'; break;
                case 'CircleChart': DynamicControl = 'circle-chart'; break;
                case 'ComboBox': DynamicControl = 'combo-box'; break;
                case 'ControlContainer': DynamicControl = 'stack-vertical'; break;
                case 'ControlDesigner': DynamicControl = 'control-designer'; break;
                case 'ConversationView': DynamicControl = 'conversation-view'; break;
                case 'CssStyle': DynamicControl = 'css-style'; break;
                case 'Dash': DynamicControl = 'dash'; break;
                case 'DateRangePicker': DynamicControl = 'date-range-picker'; break;
                case 'DebugHsmLog': DynamicControl = 'debug-hsm-log'; break;
                case 'DesktopNotify': DynamicControl = 'desktop-notify'; break;
                case 'DocumentBootstrap': DynamicControl = 'document-bootstrap'; break;
                case 'DropdownList': DynamicControl = 'dropdown-list'; break;
                case 'DropdownMultiSelect': DynamicControl = 'dropdown-multi-select'; break;
                case 'DynamicControlContainer': DynamicControl = 'dynamic-control-container'; break;
                case 'DynamicMenuList': DynamicControl = 'dynamic-menu-list'; break;
                case 'DynamicTab': DynamicControl = 'dynamic-tab'; break;
                case 'DynamicTabContent': DynamicControl = 'dynamic-tab-content'; break;
                case 'DynamicTree': DynamicControl = 'dynamic-tree'; break;
                case 'FastGrid': DynamicControl = 'fast-grid'; break;
                case 'FileDiff': DynamicControl = 'file-diff'; break;
                case 'FlowChart': DynamicControl = 'flow-chart'; break;
                case 'ForEach': DynamicControl = 'for-each'; break;
                case 'ForEachSimple': DynamicControl = 'for-each-simple'; break;
                case 'GoogleButton': DynamicControl = 'google-button-v2'; break;
                case 'HeaderBar': DynamicControl = 'stack-horizontal'; break;
                case 'HorizontalLayout': DynamicControl = 'stack-vertical'; break;
                case 'HsmNew': DynamicControl = 'hsm-new'; break;
                case 'Hsm':
                    //if (control.ControlData.Name == 'myhsm')
                        DynamicControl = 'hsm-new';
                    //else
                    //    DynamicControl = 'hsm';
                    break;
                case 'HtmlEditor': DynamicControl = 'html-editor'; break;
                case 'HtmlTable': DynamicControl = 'html-table'; break;
                case 'Html': DynamicControl = 'html-component'; break;
                case 'Icon': DynamicControl = 'basic-icon'; break;
                case 'IconPopupMenu': DynamicControl = 'icon-popup-menu'; break;
                case 'IconStack': DynamicControl = 'icon-stack'; break;
                case 'IFrame': DynamicControl = 'iframe-component'; break;
                case 'Image': DynamicControl = 'image-control'; break;
                case 'InfiniteConversationView': DynamicControl = 'infinite-conversation-view'; break;
                case 'InfiniteGrid': DynamicControl = 'infinite-grid'; break;
                case 'Interval': DynamicControl = 'interval'; break;
                case 'Javascript': DynamicControl = 'javascript'; break;
                case 'Layout':
                case 'LayoutStack':
                    // Legacy stack container
                    switch (control.ControlData.Orientation) {
                        case 'Vertical': DynamicControl = 'stack-vertical'; break;
                        case 'Horizontal': DynamicControl = 'stack-horizontal'; break;
                    }
                    break;
                case 'LayoutTile': DynamicControl = 'layout-tile'; break;
                case 'LayoutWrap': DynamicControl = 'stack-vertical'; break;
              //case 'MicAnalyzer': DynamicControl = 'mic-analyzer'; break;
                case 'LineChart': DynamicControl = 'line-chart'; break;
                case 'MediaApproval': DynamicControl = 'media-approval'; break;
                case 'MenuFlowChart': DynamicControl = 'menu-flow-chart'; break;
                case 'MultiSelectList': DynamicControl = 'multiselectlist'; break;
                case 'MultiSelectListItem': DynamicControl = 'multiselectlistitem'; break;
                case 'NavigationDrawer': DynamicControl = 'navigation-drawer'; break;
                case 'Notification': DynamicControl = 'notification'; break;
                case 'PopupMenuButton': DynamicControl = 'popup-menu-button'; break;
                case 'ProgressBar': DynamicControl = 'cc-progress-bar'; break;
                case 'ProgressCircle': DynamicControl = 'cc-progress-circle'; break;
                case 'RealTimeGrid': DynamicControl = 'real-time-grid'; break;
                case 'RealTimeWidgetListener': DynamicControl = 'realtime-widget-listener'; break;
                case 'Recaptcha': DynamicControl = 'recaptcha'; break;
                case 'RoosterEditor': DynamicControl = 'rooster-editor'; break;
                case 'RTEventHandler': DynamicControl = 'rt-event-handler'; break;
                case 'SAMLButton': DynamicControl = 'saml-button'; break;
                case 'Search': DynamicControl = 'search'; break;
                case 'SelectList': DynamicControl = 'select-list'; break;
                case 'ServerEventListener': DynamicControl = 'server-event-listener'; break;
                case 'SlimGrid': DynamicControl = 'slim-grid'; break;
                case 'SoftPhone': DynamicControl = 'soft-phone'; break;
                case 'SoundPlayer': DynamicControl = 'sound-player'; break;
                case 'SplitButton': DynamicControl = 'split-button'; break;
                case 'SplitPanes': DynamicControl = 'split-panes'; break;
                case 'Text': DynamicControl = 'basic-text'; break;
                case 'TextEditor': DynamicControl = 'text-editor'; break;
                case 'Timeline': DynamicControl = 'cc-timeline'; break;
                case 'Timeout': DynamicControl = 'timeout'; break;
                case 'TreeView': DynamicControl = 'tree-view'; break;
                case 'VerticalLayout': DynamicControl = 'stack-vertical'; break;
                case 'Watcher': DynamicControl = 'basic-watcher'; break;
                case 'WebRtcPhone': DynamicControl = 'web-rtc-phone'; break;
                case 'Wizard': DynamicControl = 'cc-wizard'; break;
                case 'WizardButton': DynamicControl = 'wizard-button'; break;
				case 'YouTubeVideo': DynamicControl = 'youtube-video'; break;
            }
        return DynamicControl;
    },
    getDynamicAction(action) {
        let DynamicAction;
        switch (action.ActionType) {
            case 'DisplayBasicModal': DynamicAction = 'display-basic-modal'; break;
            case 'DisplayBasicConfirmModal': DynamicAction = 'display-basic-confirm-modal'; break;
            case 'Tab': DynamicAction = 'tab-action'; break;
            case 'ReplaceContent': DynamicAction = 'replace-content-action'; break;
            case 'ReferenceUserAction': DynamicAction = 'dynamic-user-action'; break;
        }
        return DynamicAction;
    },
    getDynamicHelperControl(parentType, control) {
        if (parentType == 'BasicPopupMenu' || parentType == 'IconPopupMenu')
            return utils.getDynamicComponent(null, control);

        return null;
    },
    getHeaderControls(h, controlData, items, root, parentType, addControl, selected) {
        //** Note: THIS IS ONLY USED IN THE DESIGNER **//
        let header = [];

        if (controlData.HeaderControls)
            for (let i = 0; i < controlData.HeaderControls.length; i++) {
                const c = controlData.HeaderControls[i];
                let DynamicControl = utils.getDynamicComponent(h, c);
                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                header.push(
                    <DynamicControl
                        key={c.$objectId}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={root}
                        designmodel={c}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlName={c.Name}
                    >
                    </DynamicControl>
                );
            }

        if (selected)
            header.push(
                <div>
                    {utils.generateTooltip(h,
                        <v-btn elevation={0} text small icon style={{ zIndex: 2 }} slot="activator" on-click={(e) => addControl(e, 'header')}>
                            <v-icon x-small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                        </v-btn>,
                        'Add control to header', 'right')}
                </div>
            );

        items.push(
            <div style={{ overflow: "auto", display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0" }}>
                    {header}
                </div>
            </div>
        );
    },
    generateItemsFromArray(h, context, items, model, parentType, eventHandlers) {
        let returnItems = [];

        if(items && Array.isArray(items)) {
            items.forEach((c, index) => {
                if (!(typeof c === 'object'))
                    return;
    
                let DynamicControl = utils.getDynamicComponent(h, c);
    
                if (!DynamicControl)
                    DynamicControl = 'default-unknown';
    
                if (!c.$objectId) c.$objectId = utils.generateUUID();
    
                let id = c.$objectId;
                if (index) id += '_' + index;
    
                returnItems.push(
                    <DynamicControl
                        on={eventHandlers}
                        key={id}
                        type={c.ControlType}
                        name={c.ControlData.Name ? c.ControlData.Name : ''}
                        root={context.root}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        sourceIndex={index}
                        sourceData={model}
                        controlName={c.Name}
                        scopeitems={context.scopeitems}
                        controlscope={context.controlscope}
                        cacheControl={c.CacheControl}
                        controlEvents={c.Events}
                    >
                    </DynamicControl>
                );
            });
        }

        return returnItems;
    },
    generateTooltip(h, activatorElement, content, position, key, classes, openDelay, slot) {
        let returnItem = activatorElement;

        if (!content) {
            return returnItem;
        }
        else {
            let scopedSlots = {
                activator: ({ on }) => {
                    activatorElement.data.on = { ...activatorElement.data.on, ...on };

                    return activatorElement;
                }
            }

            let item;
            if (openDelay)
                if(Array.isArray(content) || (typeof content === 'object'))
                    item = (
                        <v-tooltip
                            key={key}
                            class={classes}
                            open-delay={openDelay}
                            scopedSlots={scopedSlots}
                            {...{ attrs: { [position]: true } }}
                            max-width="500px"
                        >{content}</v-tooltip>
                    );
                else
                    item = (
                        <v-tooltip
                            key={key}
                            class={classes}
                            open-delay={openDelay}
                            scopedSlots={scopedSlots}
                            {...{ attrs: { [position]: true } }}
                            max-width="500px"
                        ><span domPropsInnerHTML={content}></span></v-tooltip>
                    );
            else
                if(Array.isArray(content) || (typeof content === 'object'))
                    item = (
                        <v-tooltip
                            key={key}
                            class={classes}
                            scopedSlots={scopedSlots}
                            {...{ attrs: { [position]: true } }}
                            max-width="500px"
                        >{content}</v-tooltip>
                    );
                else
                    item = (
                        <v-tooltip
                            key={key}
                            class={classes}
                            scopedSlots={scopedSlots}
                            {...{ attrs: { [position]: true } }}
                            max-width="500px"
                        ><span domPropsInnerHTML={content}></span></v-tooltip>
                    );
                        

            if (slot)
                item.slot = slot;

            return item;
        }
    },
    cleanModel(model) {
        let parent = {
            key: null,
            parent: null
        };
        return this.cleanModelRecursive(model, null);
    },
    cleanModelRecursive(model, parent) {
        // Removes $field_expanded properties
        let newmodel;
        if (model && typeof model === 'object') {
            if (Array.isArray(model))
                newmodel = [];
            else
                newmodel = {};

            const keys = Object.keys(model);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                let newparent = {
                    key: key,
                    parent: parent
                };
                if (typeof key === 'string' && key.substring(0, 1) === '$' && key.substring(key.length - 9) === '_expanded')
                    continue;
                else if (typeof key === 'string' && key.toLowerCase() === '$objectid')
                    if (parent?.key === "ControlData" && parent?.parent?.key === "MenuItemData") // $objectid needed for menu force refresh button.
                        newmodel[key] = utils.cleanModelRecursive(model[key], newparent);
                    else
                        continue;
                else
                    newmodel[key] = utils.cleanModelRecursive(model[key], newparent);
            }
        }
        else
            newmodel = model;

        return newmodel;
    },
    cache() {
        return cache;
    },
    apiWrapper: {
        // This is used as the api parameter for Javascript sections, it wraps the result in data.Result
        async get(url, asarray, cacheresult) {
            const res = await utils.api.get(url, asarray, cacheresult);
            return {
                data: { Result: res }
            };
        },
        async post(url, data, asarray, asraw) {
            const res = await utils.api.request("POST", url, data, asarray, asraw);
            return {
                data: { Result: res }
            }; 
        },
        async put(url, data, asarray, asraw) {
            const res = await utils.api.request("PUT", url, data, asarray, asraw);
            return {
                data: { Result: res }
            }; 
        },
        async apiRequest(options) {
            return await apiService.apiRequest(options);
        },
    },
    api: {
        clearCache() {
            apicache.urls = {};
            cachingService.clearCache('APICache');
        },

        verbose: false,
        async get(url, asarray, cacheresult, httponly) {

            if (cacheresult && url in apicache.urls)
                return apicache.urls[url];

            let config = {
                method: 'GET',
                url: url,
                flatten: true,
                cache: cacheresult,
                doNotUseWebsocket: httponly,
            };
            let result = await apiService.apiRequest(config);
            if (!asarray) {
                if (cacheresult) apicache.urls[url] = result;

                return result;
            }

            if (Array.isArray(result)) {
                if (cacheresult) apicache.urls[url] = result;

                return result;
            } else {
                const res = Object.keys(result).map((k) => ({ key: k, ...result[k] }));

                if (cacheresult) apicache.urls[url] = res;

                return res;
            }
        },
        async request(method, url, data, asarray, asraw) {
            let apiRequest = {
               method: method.toLowerCase(),
               url: url,
               data: data
            };

            let res = await apiService.apiRequest(apiRequest);

            if (asraw)
                return res.data;

            const result = ((typeof res.data === 'object') && 'Result' in res.data) ? res.data.Result : res.data;
            if (!asarray)
                return result;

            if (Array.isArray(result))
                return result;
            else
                return Object.keys(result).map((k) => ({ key: k, ...result[k] }));
        },
        async apiRequest(verb, uri, body) {
            return await this.request(verb, uri, body);
        },
    },
    schema: {
        refreshCache() {

        },

        async get(id, throwOnError) {
            return await cache.getSchema(id, throwOnError);
        },
        async resolve(schema) {
            return await cache.resolveReferences(schema);
        },
        getDefaultModel(schema) {
            //utils.debug(`+++ utils.getDefaultModel`);

            switch (schema.type) {
                case 'string':
                    return schema['default'] || '';

                case 'boolean':
                    return schema['default'] || false;

                case 'object':
                    const model = {};
                    if ('Id' in schema)
                        model.$typeSchema = schema.Id;

                    for (var key in schema.properties) {
                        const s = schema.properties[key];
                        const o = utils.schema.resolve_Of(s);

                        if ('default' in o)
                            model[key] = o.default;
                        else if (key == '$objectId')
                            model[key] = utils.generateUUID();
                        else if ('properties' in o)
                            model[key] = utils.schema.getDefaultModel(o);
                        else if (o.type == 'array' && o.arrayStart == 'EmptyArray')
                            model[key] = [];
                        else if (o.format == 'nullableType')
                            model[key] = [
                                {
                                    type: 'null'
                                },
                                {
                                    '$typeSchema': '/schema/public/Platform.Schema.SchemaSchemas.v1/SchemaSchema_Type_String',
                                    type: 'string',
                                    '$objectId': utils.generateUUID()
                                }
                            ];
                    }
                    return model;

                default:
                    return schema['default'];
            }
        },
        resolve_Of(schema) {
            if ('allOf' in schema) {
                // Combine all properties from each allOf element into one final object (note that type must be object for all)
                let s = {};
                for (var i = 0; i < schema.allOf.length; i++) {
                    const a = utils.schema.resolve_Of(schema.allOf[i]);
                    if ('type' in a)
                        s = { ...s, ...a };
                    else {
                        //s.type = s.type || a.type;
                        if ('properties' in a)
                            s.properties = s.properties ? { ...s.properties, ...a.properties } : a.properties;
                        if ('anyOf' in a)
                            s.anyOf = s.anyOf ? [...a.anyOf, ...s.anyOf] : a.anyOf;
                        if ('default' in a)
                            s.default = s.default || a.default;
                    }
                }
                return { ...s, ...schema };
            }
            if ('oneOf' in schema) {
                if (schema.oneOf[0].type === 'null')
                    return { ...schema.oneOf[1], ...schema, $allows_null: true };
            }
            if ('anyOf' in schema) {
                //const enums = [];
                //for (var i = 0; i < schema.anyOf.length; i++) {
                //    enums.push({
                //        name: schema.anyOf[i].title,
                //        value: schema.anyOf[i].Id,
                //        data: {
                //            $typeSchema: schema.anyOf[i].Id
                //        },
                //    });
                //}

                const s = {
                    type: 'string',
                    //enum: enums,
                    ...schema
                };

                return s;
            }
            return schema;
        },
        getConditionContext(control, data) {
            const schema_context = {
                $parent: control,
                ...data,
                ...methods,
            };

            for (let key in schema_context)
                if (typeof schema_context[key] === 'function')
                    schema_context[key] = schema_context[key].bind(schema_context);

            return schema_context;
        },
        getElementType(element) {
            if ('format' in element)
                switch (element.format) {
                    case 'TextEditor':
                        return 'form-text-editor';

                    case 'ReferencedDocumentSchema':
                        return 'referenced-document-schema';

                    case 'HSMStatement_Schema':
                        return 'hsm-statement-schema';

                    case 'LookupList':
                        return 'string-lookup-list';

                    case 'nullableType':
                        return 'nullable-type';
                }

            switch (element.type) {
                case 'string':
                    if (('anyOf' in element))
                        return 'form-anyof';
                    else if (('enum' in element) || ('titleMap' in element))
                        return 'form-select';
                    else
                        return 'form-input';

                case 'integer':
                case 'number': return 'form-number';
                case 'boolean': return 'form-boolean'; // NEW!!! Not debugged yet, on its way
                //case 'boolean': Tag = 'form-checkbox'; break;
                case 'array': return 'form-array';
                case 'object': return null;
                default:
                    let type = ('anyOf' in element) ? 'anyOf' :
                        ('allOf' in element) ? 'allOf' :
                            ('oneOf' in element) ? 'oneOf' :
                                ('unknown:' + element.type);

                    utils.log('skipping type: ' + type);
                    //Tag = 'div';
                    break;
            }

            return null;
        },
        gatherlogSchema(s, levels, txt) {
            txt.type = s.type;
            if (levels > 0)
                if ('properties' in s)
                    for (let key in s.properties) {
                        txt[key] = {};
                        utils.schema.logSchema(s.properties[key], --levels, txt[key]);
                    }
        },
        logSchema(text, s, levels) {
            let txt = {};
            utils.schema.gatherlogSchema(s, levels, txt);
            utils.debug(text + JSON.stringify(txt));
        },

        validateObject(name, s, model, errors) {
            const additionalProperties = s.additionalProperties === undefined || s.additionalProperties === true;

            // Walk through each item in the model and schema, verify the rules and insure
            // all required fields are populated, and no others are found (unless allowed).
            for (let key in s.properties) {
                if (key.startsWith('$$')) continue;

                const p = utils.schema.resolve_Of(s.properties[key]); // schema for this property
                const m = model[key];        // model for this property

                // Form name of element being verified
                const subname = name ? `${name}.${key}` : key;
                utils.schema.validate(subname, p, m, errors);
            }

            if (s.required)
                for (let i = 0; i < s.required.length; i++) {
                    const key = s.required[i];
                    if (!(key in model))
                        errors.push({
                            element: name ? `${name}.${key}` : key,
                            error: `Missing required field named ${key}`,
                            reason: 'required'
                        });
                }

            if (!additionalProperties)
                for (let key in model) {
                    if (!key.startsWith('$$') && !(key in s.properties))
                        errors.push({
                            element: name ? `${name}.${key}` : key,
                            error: `Found a field named ${key} that is not defined in the schema`,
                            reason: 'notallowed'
                        });
                }
        },
        verifyExactType(type, model) {
            switch (type) {
                case 'string':
                    return typeof model === 'string';

                case 'number':
                    return typeof model === 'number';

                case 'integer':
                    return (typeof model === 'number') && (model % 1 === 0);

                case 'object':
                    return (typeof model === 'object') && !Array.isArray(model);

                case 'array':
                    return (typeof model === 'object') && Array.isArray(model);

                case 'boolean':
                    return (typeof model === 'boolean');

                case 'null':
                    return model === null || model === undefined;
            }
        },
        validate(name, s, model, errors) {
            if (Array.isArray(s.type)) {
                // For a type that's an array, find which type is being used of the possible choices
                let t;
                for (let i = 0; i < s.type.length; i++) {
                    if (utils.schema.verifyExactType(s.type[i], model)) {
                        t = s.type[i];
                        break;
                    }
                }

                if (t && typeof t === 'string')
                    // Simple type check, no schema object to do further validation
                    return;
                else if (t && typeof t === 'object')
                    utils.schema.validate(name, t, model, errors);
                else
                    errors.push({
                        element: name || 'Root object',
                        error: `Found type ${typeof model}, expected type to be one of ${s.type.join(',')}`,
                        reason: 'badtype'
                    });
            }
            else if ('anyOf' in s && Array.isArray(s.anyOf)) {
                // Allows any of the listed types
                let tempErrorsList = [];
                for (let i = 0; i < s.anyOf.length; i++) {
                    let t = utils.schema.resolve_Of(s.anyOf[i]);
                    let tempErrors = [];
                    utils.schema.validate(name, t, model, tempErrors);
                    if (tempErrors.length === 0)
                        return;

                    tempErrorsList.push(tempErrors);
                }
                let allerrors = tempErrorsList.flatMap(e => e);
                for (let i = 0; i < allerrors.length; i++)
                    errors.push(allerrors[i]);
                return;
            }
            else if (typeof s.type === 'string') {
                if (model === null) // Removed this: model === undefined || 
                {
                    if (!(s.$allows_null || s.type == 'null'))
                        errors.push({
                            element: name || 'Root object',
                            error: `Missing value, expected ${s.type}.`,
                            reason: 'missing'
                        });

                    return;
                }
                else if (typeof model === 'undefined')
                    // Skip missing fields (how do we know to do this?)
                    return;

                const ok = utils.schema.verifyExactType(s.type, model);

                if (!ok) {
                    let typename = typeof model;
                    if (typename === 'number')
                        typename = isNaN(model) ? 'invalid number' : 'type number';
                    else
                        typename = `type ${typename}`;

                    errors.push({
                        element: name || 'Root object',
                        error: `Found ${typename}, expected type ${s.type}.`
                    });
                }
                else
                    switch (s.type) {
                        case 'string':
                            if (s.minLength && (typeof s.minLength === 'number') && model.length < s.minLength)
                                errors.push({
                                    element: name,
                                    error: `Minimum length ${s.minLength}`,
                                    reason: 'range'
                                });
                            if (s.maxLength && (typeof s.maxLength === 'number') && model.length > s.maxLength)
                                errors.push({
                                    element: name,
                                    error: `Maximum length ${s.maxLength}`,
                                    reason: 'range'
                                });
                            if (s.pattern && (typeof s.pattern === 'string')) {
                                const reg = new RegExp(s.pattern);
                                if (!reg.test(model))
                                    errors.push({
                                        element: name,
                                        error: `Invalid pattern`,
                                        reason: 'pattern'
                                    });
                            }
                            if (s.format && (typeof s.format === 'string')) {
                                // Note: draft 6 & 7 are new and therefore, skipped for now as the Angular version
                                // was based on an earlier draft anyway. These will be nice enhancements one day.
                                switch (s.format) {
                                    case 'date-time':
                                        if (!validation_patterns.datetime.test(model)) {
                                            errors.push({
                                                element: name,
                                                error: `Invalid date-time`,
                                                reason: 'pattern'
                                            });
                                        }
                                        break;
                                    case 'email':
                                        if (!validation_patterns.email.test(model)) {
                                            errors.push({
                                                element: name,
                                                error: `Invalid email address`,
                                                reason: 'pattern'
                                            });
                                        }
                                        break;
                                    case 'hostname':
                                    case 'ipv4':
                                    case 'ipv6':
                                    case 'uri':
                                        break;

                                    case 'time': // draft 7
                                    case 'date': // draft 7
                                    case 'idn-email': // draft 7
                                    case 'idn-hostname': // draft 7
                                    case 'uri-reference': // draft 6
                                    case 'iri': // draft 7
                                    case 'iri-reference': // draft 7
                                    case 'url-template': // draft 6
                                    case 'json-pointer': // draft 6
                                    case 'relative-json-pointer': // draft 7
                                    case 'regex': // draft 7
                                        break;
                                }
                            }
                            break;

                        case 'number':
                        case 'integer':
                            if (s.multipleOf && (typeof s.multipleOf === 'number') && !(model % s.multipleOf === 0))
                                errors.push({
                                    element: name,
                                    error: `Not a multiple of ${s.multipleOf}`,
                                    reason: 'range'
                                });

                            if ((typeof s.minimum === 'number') && (s.exclusiveMinimum === undefined || s.exclusiveMinimum === false) && model < s.minimum)
                                errors.push({
                                    element: name,
                                    error: `Value is below the minimum of ${s.minimum}`,
                                    reason: 'range'
                                });
                            else if ((typeof s.minimum === 'number') && (s.exclusiveMinimum === true) && model <= s.minimum)
                                errors.push({
                                    element: name,
                                    error: `Value is at or below the minimum of ${s.minimum}`,
                                    reason: 'range'
                                });

                            if ((typeof s.maximum === 'number') && (s.exclusiveMaximum === undefined || s.exclusiveMaximum === false) && model > s.maximum)
                                errors.push({
                                    element: name,
                                    error: `Value is more than the maximum of ${s.maximum}`,
                                    reason: 'range'
                                });
                            else if ((typeof s.maximum === 'number') && (s.exclusiveMaximum === true) && model >= s.maximum)
                                errors.push({
                                    element: name,
                                    error: `Value is at or above the maximum of ${s.maximum}`,
                                    reason: 'range'
                                });

                            if ((typeof s.exclusiveMinimum === 'number') && model <= s.exclusiveMinimum)
                                errors.push({
                                    element: name,
                                    error: `Value is at or below the minimum of ${s.exclusiveMinimum}`,
                                    reason: 'range'
                                });
                            if ((typeof s.exclusiveMaximum === 'number') && model >= s.exclusiveMaximum)
                                errors.push({
                                    element: name,
                                    error: `Value is at or above the maximum of ${s.exclusiveMaximum}`,
                                    reason: 'range'
                                });
                            break;

                        case 'object':
                            utils.schema.validateObject(name, s, model, errors);
                            break;

                        case 'array':
                            let uniqueMap;
                            if (s.uniqueItems)
                                uniqueMap = {};

                            for (let i = 0; i < model.length; i++) {
                                const m = model[i];
                                if (s.uniqueItems) {
                                    let key;
                                    if (typeof m === 'object')
                                        key = JSON.stringify(m || {});
                                    else
                                        key = m;

                                    if (key in uniqueMap) {
                                        errors.push({
                                            element: name,
                                            error: `Non-unique item found at index ${i + 1}`,
                                            reason: 'duplicate'
                                        });
                                    }
                                    else
                                        uniqueMap[key] = true;
                                }
                                utils.schema.validate(`${name}[${i}]`, s.items, m, errors);
                            }

                            if (s.minItems !== null && s.minItems > model.length) {
                                errors.push({
                                    element: name,
                                    error: `Not enough items in ${s.title}`,
                                    reason: 'length'
                                });
                            }

                            if (s.maxItems !== null && s.maxItems < model.length) {
                                errors.push({
                                    element: name,
                                    error: `Too many items in ${s.title}`,
                                    reason: 'length'
                                });
                            }

                            break;
                    }
            }
        },
        performValidation(schema, model) {
            const s = utils.schema.resolve_Of(schema);

            const errors = [];
            utils.schema.validate('', s, model, errors);
            return errors;
        }
    },
    forms: {
        insureModelPath(cmodel, element, schema) {
            // This walks down from the root schema to insure the model is initialized up to the ParentModel
            if (element.key)
                for (let i = 0; i < element.key.length - 1; i++) {
                    if (element.key[i] == '[]')
                        // Bail out if we are walking through an array path
                        // (We might make this work sometime, but I don't think it's necessary)
                        break;

                    const key = element.key.slice(0, i + 1);
                    let m = cmodel;
                    for (let j = 0; j < key.length - 1; j++)
                        m = m[key[j]];

                    let s = schema;
                    for (let i = 0; i < key.length; i++)
                        s = s.properties[key[i]];

                    if (s.type == 'object' && !m[key[key.length - 1]])
                        Vue.set(m, key[key.length - 1], {});
                    else if (s.type == 'array' && !m[key[key.length - 1]])
                        Vue.set(m, key[key.length - 1], []);
                }

        },
        mixModels(initial, model) {
            //utils.debug(`+++ utils.mixModels`);
            // takes items in initial that aren't in model and adds them
            for (let key in initial) {
                if (!(key in model))
                    Vue.set(model, key, initial[key]);

                if (typeof initial[key] === 'object' && typeof model[key] === 'object')
                    utils.forms.mixModels(initial[key], model[key]);
            }
        },
        mergeDefaultModel(model, schema, context) {
            //utils.debug(`+++ utils.mergeDefaultModel`);

            switch (schema.type)
            {
                case 'string':
                    return (typeof model === 'undefined' || model === null) ? schema['default'] : model;

                case 'boolean':
                    return (typeof model == 'undefined' || model === null) ? (schema['default'] || false) : model;

                case 'object':
                    if (!model) model = {};
                    for (var key in schema.properties) {
                        const s = schema.properties[key];
                        const o = utils.schema.resolve_Of(s);

                        if (o.$allows_null && (!(key in model) || typeof model[key] == 'undefined' || model[key] === null)) {
                            // Do nothing. If we can have null (and nothing is yet defined), no need to prepopulate anything
                        }
                        else if ('anyOf' in o) {
                            // Do nothing, we can't assume anything about which type in an anyOf to use
                        }
                        else if ('default' in o && (!(key in model) || typeof model[key] == 'undefined')) {
                            if (typeof o.default === 'string' && (o.default.includes('{{' || o.default.includes('{*')))) {
                                o.$$default = utils.compile(context, o.default);
                            }
                            const default_value = o.$$default ? utils.evaluate(o.$$default, context) : o.default;
                            Vue.set(model, key, model[key] || default_value);
                        }
                        else if ('properties' in o) {
                            const v = utils.forms.mergeDefaultModel(model[key], o, context);
                            if (typeof v !== 'undefined')
                                Vue.set(model, key, v);
                        }
                        else if ('items' in o) {
                            const v = utils.forms.mergeDefaultModel(model[key], o, context);
                            if (typeof v !== 'undefined')
                                Vue.set(model, key, v);
                        }
                        else if ('type' in o) {
                            const v = utils.forms.mergeDefaultModel(model[key], o, context);
                            if (typeof v !== 'undefined')
                                Vue.set(model, key, v);
                        }
                    }
                    return model;

                case 'array':
                    if (!model) {
                        let a = schema.arrayStart;
                        if (a == 'FormDefault') {
                            if ('ArrayStartValue' in context)
                                a = context.ArrayStartValue;
                            else
                                a = context.ParentByType('BasicForm')?.ArrayStartValue || 'Nothing';
                        }

                        switch (a) {
                            case 'EmptyArray':
                                return [];

                            case 'DefaultItem':
                                // The default must come from the items to know if it's an object or
                                // a primitive type...
                                const vitem = utils.forms.mergeDefaultModel(undefined, schema.items, context);
                                if (typeof vitem !== 'undefined')
                                    return [vitem];
                                else
                                    return undefined;

                            case 'Nothing':
                            default:
                                return undefined;
                        }
                    }
                    for (let i = 0; i < model.length; i++) {
                        const v = utils.forms.mergeDefaultModel(model[i], schema.items, context);
                        if (typeof v !== 'undefined')
                            Vue.set(model, i, v);
                    }
                    return model;

                default:
                    return (typeof model === 'undefined' || model === null) ? schema['default'] : model;
            }
        },
        getModelElementReader(key, basekey) {
            // Ex:
            // [ 'Field1', '[]', 'subfield1' ]
            // return model.Field1[i].subfield1;
            let s = 'model';
            for (let i = 0; i < key.length; i++) {
                const t = (basekey && i < basekey.length) ? basekey[i] : key[i];
                if (t.startsWith('['))
                    s += (basekey && i < basekey.length) ? basekey[i] : key[i];
                else {
                    let keys = t.split('.');

                    for(let individualKey of keys) {
                        s += '["' + individualKey + '"]';
                    }
                }
            }

            const code = `return ${s};`;
            try {
                return {
                    code: code,
                    func: new Function('model', code)
                };
            } catch (e) {
                utils.warn(`Failed to generate modelElementReader for ${JSON.stringify(key)} / ${basekey ? JSON.stringify(basekey) : ''}: ${code}`, e);
                return null;
            }
        },
        getModelElementWriter(key, basekey) {
            // Ex:
            // [ 'Field1', '[0]' ]
            // Vue.set(model.Field1, 0, value);

            // Ex:
            // [ 'Field1', '[1]', 'subfield1' ]
            // Vue.set(model.Field1[1], 'subfield1', value);

            let s = 'model';
            let f;
            for (let i = 0; i < key.length; i++) {
                const e = (basekey && i < basekey.length) ? basekey[i] : key[i];

                if (i < key.length - 1) {
                    if (e.startsWith('['))
                        s += e;
                    else
                        s += '.' + e;
                }
                else {
                    if (e.startsWith('['))
                        f = e.substr(1, e.length - 2);
                    else
                        f = "'" + e + "'";
                }
            }

            const code = `vue.set(${s}, ${f}, value);`;
            try {
                return {
                    code: code,
                    func: new Function('model', 'value', 'vue', code)
                };
            }
            catch (e) {
                utils.warn(`Failed to generate modelElementWriter for ${JSON.stringify(key)} / ${basekey ? JSON.stringify(basekey) : ''}: ${code}`, e);
                return null;
            }
        },
        getFormType: function (schema) {
            if ('format' in schema && schema.format) {
                switch (schema.format.toLowerCase()) {
                    case 'tabarray':
                    case 'texteditor':
                    case 'array':
                        return schema.format.toLowerCase();

                    case 'checkboxes':
                        return `${schema.format}-${schema.type}`.toLowerCase();
                }
                //if (typeof schema.type === 'string')
                //    return `${schema.format}-${schema.type}`.toLowerCase();
                //else
                return `${schema.format}`.toLowerCase();
            }

            //switch (schema.format) {
            //    case 'TextEditor':
            //        return 'input-multiline';
            //    case 'MediaSelector':
            //}

            switch (schema.type) {
                case 'string':
                    if (('anyOf' in schema))
                        return 'anyof';
                    else if (('enum' in schema) || ('titleMap' in schema))
                        return 'select';
                    else
                        return 'text';

                case 'object': return 'fieldset';
                case 'boolean': return 'checkbox';
                case 'integer':
                case 'number': return 'number';
                default:
                    utils.log(`Emitting form type for schema type: '${schema.type || '<null>'}'`);
                    return schema.type;
            }
        },
        generateAllSchemaItems: function (parentpath, newform, schema) {
            const s = utils.schema.resolve_Of(schema);
            switch (s.type) {
                case 'array':
                    switch (s.items.type) {
                        case 'object':
                            const value = {
                                key: [...parentpath, '[]'],
                                type: utils.forms.getFormType(s.items),
                                formatData: s.items.formatData,
                                title: s.items.title,
                                description: s.items.description,
                                notitle: s.items.notitle || false,
                                titleMap: s.items.titleMap,
                                enum: s.items.enum,
                                readonly: s.items.readonly,
                                schema: s.items,
                                condition: s.items.condition,
                                $$condition: s.items.$$condition,
                                items: [],
                            };
                            utils.forms.generateAllSchemaItems(value.key, value.items, s.items);
                            newform.push(value);
                            break;

                        case 'array':
                            break;

                        default:
                            newform.push({
                                key: [...parentpath, '[]'],
                                type: utils.forms.getFormType(s.items),
                                formatData: s.items.formatData,
                                title: s.items.title,
                                description: s.items.description,
                                titleMap: s.items.titleMap,
                                enum: s.items.enum,
                                readonly: s.items.readonly,
                                condition: s.items.condition,
                                $$condition: s.items.$$condition,
                                schema: s.items,
                            });
                            break;
                    }
                    break;

                case 'object':
                    for (let key in s.properties) {
                        if (key.substr(0, 1) == '$') continue;

                        const item = utils.schema.resolve_Of(s.properties[key]);
                        if (item.type == 'object') {
                            const path = [...parentpath, key];
                            const value = {
                                key: path,
                                type: utils.forms.getFormType(item),
                                formatData: item.formatData,
                                title: item.title,
                                description: item.description,
                                notitle: item.notitle || false,
                                titleMap: item.titleMap,
                                enum: item.enum,
                                readonly: item.readonly,
                                condition: item.condition,
                                $$condition: item.$$condition,
                                schema: item,
                                items: [],
                            };
                            utils.forms.generateAllSchemaItems(path, value.items, item);
                            newform.push(value);
                        }
                        else if (item.type == 'array') {
                            const value = {
                                key: [...parentpath, key],
                                type: utils.forms.getFormType(item),
                                formatData: item.formatData,
                                title: item.title,
                                description: item.description,
                                notitle: item.notitle || false,
                                titleMap: item.titleMap,
                                enum: item.enum,
                                readonly: item.readonly,
                                condition: item.condition,
                                $$condition: item.$$condition,
                                schema: item,
                                items: [],
                            };
                            const path = [...parentpath, key];
                            utils.forms.generateAllSchemaItems(path, value.items, item);
                            newform.push(value);
                        }
                        else {
                            const value = {
                                key: [...parentpath, key],
                                type: utils.forms.getFormType(item),
                                formatData: item.formatData,
                                title: item.title,
                                description: item.description,
                                titleMap: item.titleMap,
                                enum: item.enum,
                                readonly: item.readonly,
                                condition: item.condition,
                                $$condition: item.$$condition,
                                schema: item,
                            };
                            newform.push(value);
                        }
                    }
                    break;

                default:
                    const value = {
                        key: s.key ? [...parentpath, s.key] : parentpath,
                        type: utils.forms.getFormType(s),
                        formatData: s.formatData,
                        title: s.title,
                        description: s.description,
                        titleMap: s.titleMap,
                        enum: s.enum,
                        readonly: s.readonly,
                        condition: s.condition,
                        $$condition: s.$$condition,
                        schema: s,
                    };
                    newform.push(value);
                    break;
            }
        },
        convertStringKeyToArray: function (key) {
            // In:  field.field[].field[].field
            // Out: [ 'field', 'field', '[]', 'field', '[]', 'field' ]
            return key.split(/(\.|\[[^\.]*\])/).filter(k => k && k !== '.');
        },
        generateForm: function (form, newform, s) {
            for (let i = 0; i < form.length; i++) {
                const item = form[i];
                if (!item) continue;

                let value;
                if (typeof item === 'string') {
                    if (item == '*')
                        value = { AllSchemaElements: true };
                    else
                        value = { key: item };
                }
                else if (typeof item === 'object') {
                    value = { ...item };
                }

                if ((value.AllSchemaElements || value.key == '*') && s) {
                    utils.forms.generateAllSchemaItems([], newform, s);
                    continue;
                }

                // Transform text syntax (field.field) into an array (['field','field'])
                if (value.key && typeof value.key === 'string')
                    value.key = utils.forms.convertStringKeyToArray(value.key);

                if (s && value.key) {
                    // Locate the final schema member
                    let field_s = s;
                    for (let k = 0; k < value.key.length; k++) {
                        const key = value.key[k];
                        if ('oneOf' in field_s) {
                            // If this type is a OneOf, find any type that contains the key we are looking for
                            const oneOf = field_s.oneOf;
                            const poss_f = oneOf.find(o => o.properties && (key in o.properties));
                            if (poss_f)
                                field_s = utils.schema.resolve_Of(poss_f);
                        }

                        if (key.startsWith('[') && key.endsWith(']') && field_s.items) {
                            field_s = utils.schema.resolve_Of(field_s.items);
                        }
                        else if (field_s.properties && (key in field_s.properties)) {
                            field_s = utils.schema.resolve_Of(field_s.properties[key]);
                        }
                        else {
                            field_s = null;
                            break;
                        }
                    }

                    if (field_s) {
                        if (value.schema)
                        {
                            //value.schema = { ...field_s, ...value.schema };
                            value.schema = _.merge(field_s, value.schema);
                        }
                        else
                            value.schema = field_s;

                        if (!value.type)
                            value.type = utils.forms.getFormType(value.schema);
                        if (!value.title)
                            value.title = value.schema.title;
                        if (!value.description)
                            value.description = value.schema.description;
                        if (!value.formatData)
                            value.formatData = value.schema.formatData;
                        if (!value.titleMap)
                            value.titleMap = value.schema.titleMap;
                        if (!value.enum)
                            value.enum = value.schema.enum;
                        if (!('notitle' in value))
                            value.notitle = value.schema.notitle;
                        if (typeof value.readonly === 'undefined')
                            value.readonly = value.schema.readonly;
                        if (typeof value.noinput === 'undefined')
                            value.noinput = value.schema.noinput;
                        if (!value.required && s.required)
                            value.required = s.required.includes(value.key[value.key.length - 1]);
                        if (!value.condition) {
                            value.condition = value.schema.condition;
                            value.$$condition = value.schema.$$condition;
                        }
                        else if (value.condition && typeof value.condition === 'string' && !value.$$condition) {
                            //value.condition_src$ = value.condition;
                            value.$$condition = new Function('context', 'util', 'with (context) return ' + value.condition + ';');
                        }

                        switch (value.schema.type) {
                            case 'object':
                                if (!value.items) {
                                    value.items = [];
                                    utils.forms.generateAllSchemaItems(value.key, value.items, value.schema);
                                }
                                else {
                                    const itemsform = [];
                                    utils.forms.generateForm(value.items, itemsform, s);
                                    value = {
                                        key: value.key,
                                        type: value.type,
                                        title: value.title,
                                        description: value.description,
                                        formatData: value.formatData,
                                        titleMap: value.titleMap,
                                        enum: value.enum,
                                        schema: value.schema,
                                        readonly: typeof value.readonly == 'undefined' ? value.schema.readonly : value.readonly,
                                        condition: value.condition || value.schema.condition,
                                        $$condition: value.$$condition || value.schema.$$condition,
                                        items: itemsform,
                                    };
                                }
                                break;

                            case 'array':
                                if (!value.items) {
                                    value.items = [];
                                    utils.forms.generateAllSchemaItems(value.key, value.items, value.schema);
                                }
                                else {
                                    const itemsform = [];
                                    utils.forms.generateForm(value.items, itemsform, s);
                                    const newkey = [...value.key, '[]'];
                                    value = {
                                        key: value.key,
                                        type: value.type,
                                        title: value.title,
                                        description: value.description,
                                        add: value.add,
                                        formatData: value.formatData,
                                        titleMap: value.titleMap,
                                        enum: value.enum,
                                        notitle: value.notitle,
                                        nodrag: value.nodrag,
                                        schema: value.schema,
                                        uniqueItems: value.schema.uniqueItems,
                                        readonly: typeof value.readonly == 'undefined' ? value.schema.readonly : value.readonly,
                                        condition: value.condition || value.schema.condition,
                                        $$condition: value.$$condition || value.schema.$$condition,
                                        items: [
                                            {
                                                key: newkey,
                                                type: 'fieldset',
                                                items: itemsform,
                                            }
                                        ],
                                    };
                                }
                                break;

                            default:
                                if (value.items) {
                                    const itemsform = [];
                                    utils.forms.generateForm(value.items, itemsform, s);
                                    value.items = itemsform;
                                }
                                break;
                        }
                    }
                    else if (value.condition && typeof value.condition === 'string' && !value.$$condition) {
                        //value.condition_src$ = value.condition;
                        try {
                            value.$$condition = new Function('context', 'util', 'with (context) return ' + value.condition + ';');
                        }
                        catch (e) {
                            utils.warn(`Failed to create condition function for '${value.condition}' at form element ${item.key} ${item.type}`, e);
                        }
                    }
                }
                else {
                    if (value.condition && typeof value.condition === 'string' && !value.$$condition) {
                        //value.condition_src$ = value.condition;
                        value.$$condition = new Function('context', 'util', 'with (context) return ' + value.condition + ';');
                    }

                    if (value.items) {
                        if (value.condition && typeof value.condition === 'string' && !value.$$condition) {
                            //value.condition_src$ = value.condition;
                            value.$$condition = new Function('context', 'util', 'with (context) return ' + value.condition + ';');
                        }
                        //value.schema = s;
                        value.items = utils.forms.generateFormWithSchema(value.items, s);
                    }
                    else if (value.tabs) {
                        for (let j = 0; j < value.tabs.length; j++) {
                            const tab = value.tabs[j];
                            tab.items = utils.forms.generateFormWithSchema(tab.items, s);
                        }
                    }
                }
                newform.push(value);
            }
        },
        generateFormWithSchema: function (form, schema) {
            const s = schema ? utils.schema.resolve_Of(schema) : null;
            if (s && s.type != 'object')
                return [];

            const newform = [];
            utils.forms.generateForm(form, newform, s);
            return newform;
        },
        joinFieldKey(key) {
            let keystr = '';
            for (let i = 0; i < key.length; i++) {
                const k = key[i];
                if (k == '[]')
                    keystr += k;
                else if (typeof k === 'number')
                    keystr += `[${k}]`;
                else
                    keystr += keystr ? `.${k}` : k;
            }
            return keystr;
        },
        computed: {
            // Note: This section is only used in the property grid for the designer
            Root: function () {
                return this.root._self;
            },
            ParentModel: function () {
                return this.cmodel;
            },
            FormModel: function () {
                if ('form_model' in this.$attrs)
                    return this.$attrs.form_model;
                else
                    return this.cmodel;
            },
            FieldValue: function () {
                if (this.cmodel && this.schemakey)
                    return this.cmodel[this.schemakey];
                else if (this.cmodel)
                    return this.cmodel;
                else
                    return null;
            },
            Condition: function () {
                // Note: schema conditions are pre-compiled into functions in cache.resolveReferences above

                if (this.schema.$$condition && typeof this.schema.$$condition === 'function')
                    try {
                        const context = utils.schema.getConditionContext(this, { FormModel: this.FormModel, ParentModel: this.cmodel });

                        return this.schema.$$condition(context); // this);
                    }
                    catch (e) {
                        utils.warn('Schema condition expression ' + this.schema.condition + ' failed to evaluate: ' + e);
                        return true;
                    }
                else
                    return true;
            },
            //util: { ...careHelpfulFunctions },
        },
        cleanModel: function (model) {
            // Removes $xxx_expanded properties (used only to track tree node state)
            if (typeof model === 'object') {
                if (Array.isArray(model)) {
                    for (let i = 0; i < model.length; i++) {
                        utils.forms.cleanModel(model[i]);
                    }
                }
                else {
                    for (let key in model) {
                        if ((key.startsWith('$') && key.endsWith('_expanded')) || key == '$objectId')
                            delete model[key];
                        else
                            utils.forms.cleanModel(model[key]);
                    }
                }
            }
        },
    },
    global_variables: {},
    service_variables: {},
    document_cache: {
        menus: {},
        usercontrols: {},
        areas: {},
        menuTreeByName: {},
        contentTreeByName: {},
        getChildren: function (data, parent, outargs) {
            let path = parent.MenuItemData.Title;
            if (parent.MenuPath)
                path = parent.MenuPath + '::' + path;

            const list = data.filter(m => m.MenuPath == path).sort((a, b) => parseInt(a.Index) - parseInt(b.Index));

            const result = [];
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const child = {
                    id: `${outargs.index++}`,
                    name: item.MenuItemData.Title,
                    icon: item.MenuItemData.Icon,
                    item_data: item,
                }
                outargs.content.push(child);
                const children = utils.document_cache.getChildren(data, item, outargs);
                if (children && children.length > 0)
                    child.children = children;

                result.push(child);
            }

            return result;
        },
        getMenuTree: function (menuName) {
            if (menuName in utils.document_cache.menuTreeByName)
                return utils.document_cache.menuTreeByName[menuName];

            const data = utils.document_cache.menus[menuName];
            const result = [];
            const content = [];
            const outargs = { index: 0, content: content };

            let root = data.filter(m => !m.MenuPath).sort((a, b) => parseInt(a.Index) - parseInt(b.Index));
            for (let i = 0; i < root.length; i++) {
                const node = root[i];
                const child = {
                    id: `${outargs.index++}`,
                    name: node.MenuItemData.Title,
                    icon: node.MenuItemData.Icon,
                    item_data: node,
                };
                outargs.content.push(child);
                const children = utils.document_cache.getChildren(data, node, outargs);
                if (children && children.length > 0)
                    child.children = children;

                result.push(child);
            }
            utils.document_cache.menuTreeByName[menuName] = result;
            utils.document_cache.contentTreeByName[menuName] = content;
            return result;
        },
        getContentForTree: function (menuName) {
            if (utils.document_cache.contentTreeByName[menuName])
                return utils.document_cache.contentTreeByName[menuName];

            utils.document_cache.getMenuTree(menuName);
            return utils.document_cache.contentTreeByName[menuName];
        },
        loadInProgress: {},
        actionsInProgress: {},
        clearCache: function () {
            utils.document_cache.menus = {};
            utils.document_cache.usercontrols = {};
            utils.document_cache.areas = {};
            utils.document_cache.menuTreeByName = {};
            utils.document_cache.contentTreeByName = {};
        }
    },
    global: {
        SetAllDocuments: function (all) {
            if (typeof all === 'object' && Array.isArray(all)) {
                // Reset this so hot reload works
                utils.document_cache.menus = {};

                for (let i = 0; i < all.length; i++) {
                    const doc = all[i];

                    if (doc.$doctype == 'MenuItem') {
                        const menuName = doc.MenuName;
                        if (!(menuName in utils.document_cache.menus))
                            utils.document_cache.menus[menuName] = [];

                        utils.document_cache.menus[menuName].push(doc);
                    }
                    else if (doc.$doctype == 'UserControl') {
                        const groupName = doc.$ModuleMetaData?.Config?.GroupName;
                        if (groupName) {
                            if (!(groupName in utils.document_cache.usercontrols))
                                utils.document_cache.usercontrols[groupName] = [];

                            utils.document_cache.usercontrols[groupName].push(doc);
                        }
                    }
                    else if (doc.$doctype == 'UserDefinedArea') {
                        const location = doc.Location;
                        if (!(location in utils.document_cache.areas))
                            utils.document_cache.areas[location] = [];

                        utils.document_cache.areas[location].push(doc);

                        //const order = doc.Order;
                        //const controls = doc.Controls;
                    }
                }

                // Sort the contents of the areas by Order
                for (let key in utils.document_cache.areas) {
                    var area = utils.document_cache.areas[key];
                    area.sort((a, b) => a.Order - b.Order);
                }
            }
        },
        AddDynamicMenus: function (all) {
            for (let i = 0; i < all.length; i++) {
                const doc = all[i];

                const menuName = doc.MenuName;
                if (!(menuName in utils.document_cache.menus))
                    utils.document_cache.menus[menuName] = [];

                utils.document_cache.menus[menuName].push(doc);
            }
        },
    },
    hsmlist: {
        hsms: [],
        registerHSM: function(name, hsm) {
            utils.hsmlist.hsms.push({ name: name, hsm: hsm });
        },
        removeHSM: function (name) {
            const idx = utils.hsmlist.hsms.findIndex(h => h.name == name);
            if (idx >= 0)
                utils.hsmlist.hsms.splice(idx, 1);
        },
        setVisibility: function (values) {
            for (let i = 0; i < utils.hsmlist.hsms.length; i++) {
                const hsm = utils.hsmlist.hsms[i];
                hsm.hsm.setVisibility(values.some(v => v == hsm.name));
            }
        },
    },
    getCustomClasses(styleHints) {
        debugger;
        return "";
    },
    getStyleHints(styleHints) {
        const style = {};
        if (!styleHints)
            return style;

        if (styleHints.BackgroundColor)
            style.backgroundColor = styleHints.BackgroundColor;

        if (styleHints.ForegroundColor)
            style.color = styleHints.ForegroundColor;

        if (styleHints.BorderTop && styleHints.BorderTop !== 'Unspecified') {
            style.borderTop = resolveSizes(styleHints.BorderTop);
            style.borderTopStyle = 'solid';
        }
        if (styleHints.BorderBottom && styleHints.BorderBottom !== 'Unspecified') {
            style.borderBottom = resolveSizes(styleHints.BorderBottom);
            style.borderBottomStyle = 'solid';
        }
        if (styleHints.BorderLeft && styleHints.BorderLeft !== 'Unspecified') {
            style.borderLeft = resolveSizes(styleHints.BorderLeft);
            style.borderLeftStyle = 'solid';
        }
        if (styleHints.BorderRight && styleHints.BorderRight !== 'Unspecified') {
            style.borderRight = resolveSizes(styleHints.BorderRight);
            style.borderRightStyle = 'solid';
        }

        if (styleHints.BorderRadius) {
            style.borderRadius = styleHints.BorderRadius;
        }
        else {
            if (styleHints.BorderTopLeftRadius)
                style.borderTopLeftRadius = styleHints.BorderTopLeftRadius;

            if (styleHints.BorderTopRightRadius)
                style.borderTopRightRadius = styleHints.BorderTopRightRadius;

            if (styleHints.BorderBottomLeftRadius)
                style.borderBottomLeftRadius = styleHints.BorderBottomLeftRadius;

            if (styleHints.BorderBottomRightRadius)
                style.borderBottomRightRadius = styleHints.BorderBottomRightRadius;
        }

        if (styleHints.MarginTop && styleHints.MarginTop !== 'Unspecified') {
            style.marginTop = resolveSizes(styleHints.MarginTop);
        }
        if (styleHints.MarginBottom && styleHints.MarginBottom !== 'Unspecified') {
            style.marginBottom = resolveSizes(styleHints.MarginBottom);
        }
        if (styleHints.MarginLeft && styleHints.MarginLeft !== 'Unspecified') {
            style.marginLeft = resolveSizes(styleHints.MarginLeft);
        }
        if (styleHints.MarginRight && styleHints.MarginRight !== 'Unspecified') {
            style.marginRight = resolveSizes(styleHints.MarginRight);
        }

        if (styleHints.PaddingTop && styleHints.PaddingTop !== 'Unspecified') {
            style.paddingTop = resolveSizes(styleHints.PaddingTop);
        }
        if (styleHints.PaddingBottom && styleHints.PaddingBottom !== 'Unspecified') {
            style.paddingBottom = resolveSizes(styleHints.PaddingBottom);
        }
        if (styleHints.PaddingLeft && styleHints.PaddingLeft !== 'Unspecified') {
            style.paddingLeft = resolveSizes(styleHints.PaddingLeft);
        }
        if (styleHints.PaddingRight && styleHints.PaddingRight !== 'Unspecified') {
            style.paddingRight = resolveSizes(styleHints.PaddingRight);
        }

        if (styleHints.BorderTopColor) {
            style.borderTopColor = styleHints.BorderTopColor;
        }
        if (styleHints.BorderBottomColor) {
            style.borderBottomColor = styleHints.BorderBottomColor;
        }
        if (styleHints.BorderLeftColor) {
            style.borderLeftColor = styleHints.BorderLeftColor;
        }
        if (styleHints.BorderRightColor) {
            style.borderRightColor = styleHints.BorderRightColor;
        }
        if (styleHints.JustifyContent) {
            style.justifyContent = styleHints.JustifyContent;
        }
        if (styleHints.TextAlign) {
            style.textAlign = styleHints.TextAlign;
        }
        if (styleHints.FontSize) {
            style.fontSize = this.resolveFontSize(styleHints.FontSize);
        }
        if (styleHints.FontWeight) {
            style.fontWeight = styleHints.FontWeight.toLowerCase();
        }

        if (styleHints.IsVisible) {
            style.visibility = (styleHints.IsVisible + '').toLowerCase() == 'true' ? 'visible' : 'hidden';
        }

        if (styleHints.Overflow && typeof styleHints.Overflow === 'string') {
            style.overflow = styleHints.Overflow.toLowerCase();
        }

        if (styleHints.ForegroundColor) {
            style.color = styleHints.ForegroundColor + " !important";
        }

        if (styleHints.BackgroundColor) {
            style.backgroundColor = styleHints.BackgroundColor;
        }

        // TODO: Blink and custom classes

        if (styleHints.CustomStyle) {
            if (typeof styleHints.CustomStyle === 'object')
                for (const [key, item] of Object.entries(styleHints.CustomStyle)) {
                    style[key] = item;
                }
            else if (typeof styleHints.CustomStyle === 'string')
                styleHints.CustomStyle.split(';').forEach(customStyle => {
                    let styleArray = customStyle.split(':');
                    if (styleArray && styleArray?.length > 1)
                        style[styleArray[0].trim()] = styleArray[1].trim();
                });
        }

        return style;
    },
    getSize(sizeOptions, parentType, parent) {
        const style = {};
        if (!sizeOptions) {
            if (parentType === 'App') {
                style.height = "100%";
                style.width = "100%";
            }
            return style;
        }

        if (sizeOptions.Height)
            switch (sizeOptions.Height.Mode) {
                case 'Fill':
                    switch (parentType) {
                        case 'App': style.height = "100%"; break;
                        case 'VerticalStack':
                        case 'VerticalLayout':
                        case 'ControlContainer':
                        case 'BasicTab':
                            style.flexGrow = "1";
                            break;
                        case 'HorizontalStack':
                        case 'HorizontalLayout':
                            style.alignSelf = "normal";
                            break;
                    }

                    if (sizeOptions.Height.Min)
                        style.minHeight = sizeOptions.Height.Min + "px";
                    if (sizeOptions.Height.Max)
                        style.maxHeight = sizeOptions.Height.Max + "px";

                    break;

                case 'Fixed':
                    switch (sizeOptions.Height.Units) {
                        case 'Pixels':
                            switch (parentType) {
                                case 'VerticalStack':
                                case 'ControlContainer':
                                case 'VerticalLayout':
                                    style.minHeight = sizeOptions.Height.Value + 'px'; style.maxHeight = sizeOptions.Height.Value + 'px'; break;
                                default:
                                    style.height = sizeOptions.Height.Value + 'px';
                                    break;
                            }
                            break;
                        case 'Percent':
                            switch (parentType) {
                                case 'VerticalStack':
                                case 'ControlContainer':
                                case 'VerticalLayout':
                                    style.flexBasis = sizeOptions.Height.Value + '%'; break;
                                default:
                                    style.height = sizeOptions.Height.Value + '%';
                                    break;
                            }
                            break;
                        default:
                            if (sizeOptions.Height.Value == '0')
                                style.height = '0';
                            if (sizeOptions.Width.Value == '0')
                                style.width = '0';
                    }

                    switch (parentType) {
                        case 'VerticalStack':
                        case 'ControlContainer':
                        case 'VerticalLayout':
                            style.flexGrow = '0'; style.flexShrink = '0'; break;
                    }

                    if (sizeOptions.Height.Min)
                        style.minHeight = sizeOptions.Height.Min + "px";

                    style.overflow = 'auto';
                    break;

                case 'Auto':
                default: // If unassigned, treat as Auto
                    switch (parentType) {
                        case 'VerticalStack':
                        case 'ControlContainer':
                        case 'VerticalLayout':
                            style.flexGrow = '0'; style.flexShrink = '0'; break;
                    }

                    if (sizeOptions.Height.Max)
                        style.maxHeight = sizeOptions.Height.Max + "px";
                    if (sizeOptions.Height.Min)
                        style.minHeight = sizeOptions.Height.Min + "px";

                    break;
            }
        else {
            switch (parentType) {
                case 'VerticalStack':
                case 'ControlContainer':
                case 'VerticalLayout':
                    style.flexGrow = '0'; style.flexShrink = '0'; break;
            }
        }

        if (sizeOptions.Width)
            switch (sizeOptions.Width.Mode) {
                case 'Fixed':
                    switch (sizeOptions.Width.Units) {
                        case 'Pixels':
                            switch (parentType) {
                                case 'HorizontalStack':
                                case 'HorizontalLayout':
                                    style.minWidth = sizeOptions.Width.Value + 'px'; style.maxWidth = sizeOptions.Width.Value + 'px'; break;
                                default:
                                    style.width = sizeOptions.Width.Value + 'px';
                                    break;
                            }
                            break;
                        case 'Percent':
                            switch (parentType) {
                                case 'HorizontalStack':
                                case 'HorizontalLayout':
                                    style.flexBasis = sizeOptions.Width.Value + '%'; break;
                                default:
                                    style.width = sizeOptions.Width.Value + '%';
                                    break;
                            }
                            break;
                    }

                    switch (parentType) {
                        case 'HorizontalStack':
                        case 'HorizontalLayout':
                            style.flexGrow = '0'; style.flexShrink = '0'; break;
                    }

                    if (sizeOptions.Width.Min)
                        style.minWidth = sizeOptions.Width.Min + "px";

                    style.overflow = 'auto';
                    break;

                case 'Auto':
                    switch (parentType) {
                        case 'ContentDef':
                        case 'HorizontalStack':
                        case 'HorizontalLayout':
                            style.flexGrow = '0'; style.flexShrink = '0'; break;
                    }

                    if (sizeOptions.Width.Min)
                        style.minWidth = sizeOptions.Width.Min + "px";
                    if (sizeOptions.Width.Max)
                        style.maxWidth = sizeOptions.Width.Max + "px";

                    switch (sizeOptions.Width.Align) {
                        case 'Right': style.marginLeft = 'auto !important'; break;
                        case 'Left': style.marginRight = 'auto !important'; break;
                        case 'Middle': style.marginLeft = style.marginRight = 'auto !important'; break;
                    }

                    break;

                case 'Fill':
                default: // If unassigned, treat as Fill
                    switch (parentType) {
                        case 'App': style.width = "100%"; break;
                        case 'VerticalStack':
                        case 'VerticalLayout':
                        case 'ControlContainer':
                            style.alignSelf = "normal";
                            break; // Nothing, default is to fill
                        case 'HorizontalStack':
                        case 'HorizontalLayout':
                            style.flexGrow = "1"; break;
                    }

                    if (sizeOptions.Width.Min)
                        style.minWidth = sizeOptions.Width.Min + "px";
                    if (sizeOptions.Width.Max)
                        style.maxWidth = sizeOptions.Width.Max + "px";

                    break;
            }
        else {
            // If Width is undefined, the default is to Auto
            switch (parentType) {
                case 'ContentDef':
                case 'HorizontalStack':
                case 'HorizontalLayout':
                    style.flexGrow = '0'; style.flexShrink = '0'; break;
            }

            // If Width is undefined, the default is to Fill
            //switch (parentType) {
            //    case 'App': style.width = "100%"; break;
            //    case 'VerticalStack':
            //    case 'VerticalLayout':
            //    case 'ControlContainer':
            //        style.alignSelf = "normal";
            //        break; // Nothing, default is to fill
            //    case 'HorizontalStack':
            //    case 'HorizontalLayout':
            //        style.flexGrow = "1"; break;
            //}
        }

        return style;
    },
    resolveFontSize(size) {
        if (!size)
            return '';

        switch (size) {
            case 'xx-smaller': return '.72em';
            case 'x-smaller': return '.8em';
            case 'smaller': return '.9em';
            case 'normal': return '1em';
            case 'larger': return '1.2em';
            case 'x-larger': return '1.6em';
            case 'xx-larger': return '2.4em';
            case 'humongous': return '4em';
            case 'colossal': return '6em';
            case 'x-colossal': return '8em';
            case 'xx-colossal': return '10em';
        }
    },
    compileStyleHints(styleHints) {
        const styles = {};

        if (styleHints.BackgroundColor)
            styles.backgroundColor = utils.compile(null, styleHints.BackgroundColor);

        if (styleHints.ForegroundColor)
            styles.foregroundColor = utils.compile(null, styleHints.ForegroundColor);

        return styles;
    },
    resolveCustomClasses(styleHints, context) {
        if (!styleHints || !styleHints.CustomClasses) {
            return ""
        }

        const customClasses = utils.evaluate(styleHints.CustomClasses, context);
        return customClasses;
    }, 
    resolveStyleHints(styleHints, context) {
        if (!styleHints) return {};

        // Evaluate expressions within the style hints object
        const styles = utils.evaluateObject(styleHints, context);
        if (!styles) return {};

        // Insure background color is honored (required for vuetify buttons)
        if (styles.BackgroundColor)
            styles.BackgroundColor += ' !important';

        // Translate key words into actual styles
        return utils.getStyleHints(styles);
    },
    openForLocalEdit(args) {
        // Construct a Tab action and pass the document URL (fn) as a parameter
        EventBus.$emit('OpenDocumentEditor', args);
    },
    async globalMessageBroadcast(message, data) {
        const actions = [
                {
                    PrimitiveAction: true,
                    ActionType: 'Broadcast',
                    ActionBroadcastDirection: 'Global',
                    ActionData: {
                        Message: message,
                        MessageData: data,
                    },
                }
            ];

        await this.executeAndCompileAllActions(actions, data, this);
    },
    OpenEditorForExternalEdit(args, shared) {
        if (shared)
            editor.showChildWindow(args);
        else {
            // This requires manually logging in, but it allows the edit window to be bookmarked, etc.
            const url = `/?ControlURL=v1/Vue/ExternalEditor_v2&type=${args.Type}&name=${encodeURIComponent(args.Name)}&id=${args.Id}`; // #v1/Vue/ExternalEditor_v2`;
            window.open(url, '_blank', 'noopener');
        }
    },
    OpenDocumentStandAlone(args) {
        //const url = `/#${name}`;

        //TODO: Generate a new Token
        const url = `/?ControlURL=${args.Name}`;

        window.open(url, '_blank', 'noopener');
    },

    renameProperty: function (object, oldname, newname) {
        // Renames a property of an object while preserving the order of the properties
        const arr = utils.toArray(object);
        const idx = arr.findIndex(e => e.key == oldname);
        if (idx >= 0) {
            arr[idx].key = newname;
            return careHelpfulFunctions.toLookup(arr, "key", "value");
        }
        return object;
    },

    reorderProperty: function (object, name, direction) {
        // Changes the order of a property within an object
        // direction: 1 = down, -1 = up
        const arr = utils.toArray(object);
        const idx = arr.findIndex(e => e.key == name);
        switch (direction) {
            case 1: // down
                if (idx < arr.length - 1)
                    arr.splice(idx + 1, 0, arr.splice(idx, 1)[0]);
                break;
            case -1: // up
                if (idx > 0)
                    arr.splice(idx - 1, 0, arr.splice(idx, 1)[0]);
                break;
            default:
                return object;
        }
        return careHelpfulFunctions.toLookup(arr, "key", "value");
    },

    getBaseScopedContext(control, input, vars, sourceData) {
        let context = {
            ...methods,
            $parent: control,
            GlobalVars: utils.global_variables,
            Base: control.Base,
            root: control.Root,
            Root: control.Root,
            Self: control,
            scopeitems: control.scopeitems,
            controlscope: control.controlscope,
            Control: control.Control,
        };

        if (input) {
            context.Input = input;
        }

        if (vars) {
            context.Vars = vars;
        }

        if(sourceData) {
            context.SourceData = sourceData;
        }

        return context;
    },

    logout() {
        if (utils.document_cache)
            utils.document_cache.clearCache();
    },

    generateGuid() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
};

export default utils;