import Vue from "vue";
import BaseComponent from "./BaseComponentMixin.jsx";
import utils from "../../Shared/utils.jsx";
import EventBus from "../event-bus.js";
import api from '@/Services/api';
import "./css/Dash.css";
import ResponsiveDash from "./vuecontrols/responsiveDash.vue";

const cloneDeep = require('lodash.clonedeep')

const busyIcon = {
    render(h) {
        return (
            <v-icon
                small
                color="white"
                style={{ visibility: utils.global_variables.api_busy_indicator ? 'visible' : 'hidden' }}>
                mdi mdi-cloud-download
            </v-icon>
        );
    }
};

Vue.component("dash", {
    mixins: [BaseComponent],
    components: {
        ResponsiveDash,
        busyIcon
    },
    data: function() {
        return {
            iseditable: null,
            delay_expn: 0,
            dashboard: 0,
            editing: false,
            selected_dashboard: -1,
            layout: {
                breakpoint: "xl",
                numberOfCols: 24,
                compact: true,
                items: [],
            },
            picker: null,
            pickervisible: false,
            istemplate: null,
            templateid: null,
            newproperties: {},
            //menuActions: null,
            dash_tabs: [],
            dash_loading: true,
            dialog_width: 860,
        };
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        style() {
            return {
                "display": "flex",
                "flex-direction": "column",
                ...this.sizeStyle,
            };
        },
        isEditableComputed: function () {
            try {

                if (this.iseditable === null && this.controlData.EditableExpression !== null)
                    this.iseditable = utils.compile(this, this.controlData.EditableExpression);

                let iseditable = utils.evaluate(this.iseditable, this) || false;
                return iseditable;
            } catch (e) {
                utils.warn("Editable Expression could not evaluate expression: " + this.controlData.EditableExpression + "; " + e);
                return "";
            }
        },
        isTemplateComputed: function () {
            try {
                if (this.istemplate === null && this.controlData.IsTemplate !== null)
                    this.istemplate = utils.compile(this, this.controlData.IsTemplate);

                let istemplate = utils.evaluate(this.istemplate, this) || false;
                return istemplate;
            } catch (e) {
                utils.warn("Template Expression could not evaluate expression: " + this.controlData.IsTemplate + "; " + e);
                return "";
            }
        }
    },
    created() {
        if (this.controlData.RefreshFrequency)
            this.delay_expn = utils.compileExpression(this, this.controlData.RefreshFrequency);
        
        if(this.isTemplateComputed && this.controlData.TemplateId)
            this.templateid = utils.compile(this, this.controlData.TemplateId)
        
    },
    destroyed() {
        if (this.interval) clearInterval(this.interval);
    },
    methods: {
        async runPassedInActions(theaction) {
            let actions = [];
            actions.push(theaction);
            await utils.executeAndCompileAllActions(actions, this, this);
        },
        async preRenderComplete() {
            this.finishRenderHandler(this);
            await this.Refresh();
            this.dash_loading = false;

            const delay = this.delay_expn ? utils.evaluate(this.delay_expn, this) : 0;

            if (delay)
                this.interval = setInterval(() => {
                    EventBus.$emit("RefreshWidgets");
                }, delay * 1000);

            const lastSelectedDashboard = this.System.LocalStorage(`Dashboard_${this.controlData.Name || 'undefined'}`);
            if(lastSelectedDashboard) {
                this.dashboard = this.dash_tabs.findIndex(dt => dt.ID == lastSelectedDashboard);
                if(this.dashboard == -1) {
                    this.dashboard = 0;
                    this.System.LocalStorage(`Dashboard_${this.controlData.Name || 'undefined'}`, this.dash_tabs[0].ID);

                }
            }
        },
        async Refresh() {
            try {
                let data;
                const templateId = this.templateid ? utils.evaluate(this.templateid, this) : 0;
                if(this.isTemplateComputed) {
                    if(templateId) {
                        data = await utils.api.get(`Apps/Platform/Schema/Data/v1/DashboardTemplate?ID=${templateId}`, false, false);
                        data = [data];
                    }
                    else 
                        data = [{
                            Name: "{Template Name}",
                            Layout: {
                                breakpoint: "xl",
                                numberOfCols: 24,
                                compact: true,
                                items: [],
                            },
                        }];
                } else {
                    data = await utils.api.request('POST', `Apps/Data/v1/UserDashboards/ListAllMyDashboards?AllowPersonalDashboards=${this.controlData.AllowPersonalDashboards}`, this.controlData.DefaultTemplates, false, true);
                    data = data.Result;
                }

                if (data && data.length > 0) {
                    this.dash_tabs = data
                        .sort((a, b) => a.Order - b.Order)
                        .map(d => ({
                            Title: d.Name,
                            Description: d.Description,
                            ID: d.ID,
                            editing: false,
                            layout: d.Layout,
                            TemplateID: d.TemplateID
                        }));
                }
            }
            catch (e) {
                utils.warn(`Failed to load dashboards (Apps/Data/v1/UserDashboards/ListByID)`, e);
            }
        },
        async cancelTab(t) {
            if(t.ID) {
                this.toggleEditing(t);
                await this.Refresh();
            } else {
                this.dash_tabs.pop();
                this.dashboard = this.dash_tabs.length - 1;
                this.System.LocalStorage(`Dashboard_${this.controlData.Name || 'undefined'}`, this.dash_tabs[this.dashboard].ID)

            }
        },
        async saveTab(t) {
            const data = {
                ID: t.ID,
                Name: t.Title,
                Description: t.Description,
                Layout: {
                    breakpoint: t.layout.breakpoint,
                    numberOfCols: t.layout.numberOfCols,
                    compact: t.layout.compact,
                    items: t.layout.items.map(i => ({
                        id: i.id,
                        x: i.x,
                        y: i.y,
                        width: i.width,
                        height: i.height,
                        locked: true,
                        title: i.title,
                        ControlURL: i.ControlURL,
                        ControlData: i.ControlData,
                    })),
                },
                Order: this.dash_tabs.findIndex(a => a.Title == t.Title),
            };
            if(t.TemplateID)
                data.TemplateID = t.TemplateID;

            let res;

            if(this.isTemplateComputed) {
                res = await utils.api.request('POST', 'Apps/Platform/Schema/Data/v1/DashboardTemplate?AutoReplace=true', data);
            } else {
                res = await utils.api.request('POST', 'Apps/Data/v1/UserDashboards/SaveMyDashboard?AutoReplace=true', data);
            }

            if (res.Success) {
                t.ID = res.ID;
                this.toggleEditing(t);
                
                if(this.controlData.SaveActions && this.controlData.SaveActions.length > 0)
                    await utils.executeAndCompileAllActions(this.controlData.SaveActions, null, this);
            }
        },
        async deleteTab(t) {
            this.dialog_width = 400;
            this.picker = (
                <v-card>
                    <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background-color: var(--v-navigation-base); height: 60px">
                        <v-toolbar-title class="title white--text pl-0">
                            <translation-container context={this} value="Delete Dashboard"></translation-container>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <busyIcon></busyIcon>
                        <v-btn elevation={0} color="white" icon on-click={(e) => this.pickervisible = false}>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-text class="mt-2">
                        <translation-container context={this} value="Are you sure you want to delete?"></translation-container>
                    </v-card-text>

                    <v-card-actions class="ma-2" style="justify-content: flex-end;">
                        <v-btn elevation={0} color="primary" on-click={() => this.deleteDashboard(t)}>
                            <translation-container context={this} value="Yes"></translation-container>
                        </v-btn>
                    </v-card-actions>

                </v-card>
            );
            this.pickervisible = true;
        },
        async deleteDashboard(t) {
            if (t.ID) {
                if(t.TemplateID)
                    await utils.api.request('POST', `Apps/Data/v1/UserDashboards/UserSoftDeleteTemplateDashboard?AutoReplace=true`, t);
                else
                    await utils.api.request('DELETE', `Apps/Data/v1/UserDashboards?ID=${t.ID}`);
            }
            this.pickervisible = false;
            const idx = this.dash_tabs.findIndex(a => a == t);
            if (idx >= 0)
                this.dash_tabs.splice(idx, 1);

            if (this.dashboard >= this.dash_tabs.length) {
                this.dashboard = this.dash_tabs.length - 1;
                this.System.LocalStorage(`Dashboard_${this.controlData.Name || 'undefined'}`, this.dash_tabs[this.dashboard].ID)
            }
            
        },

        addTab(e) {
            const t = {
                Title: 'Untitled',
                editing: true,
                layout: {
                    breakpoint: "xl",
                    numberOfCols: 24,
                    compact: true,
                    items: [],
                }
            };

            this.dash_tabs.push(t);

            this.dashboard = this.dash_tabs.length - 1;
            this.System.LocalStorage(`Dashboard_${this.controlData.Name || 'undefined'}`, this.dash_tabs[this.dashboard].ID)
        },
        toggleEditing(t) {
            t.editing = !t.editing;

            for (let i = 0; i < t.layout.items.length; i++) {
                t.layout.items[i].locked = !t.editing;
                Vue.delete(t.layout.items[i], "$isEditing");
            }

            if (!t.editing) {
                // Save layout
                const data = JSON.stringify(t.layout.items);
                this.System.LocalStorage("dash.layout.1", data);
            }
        },

        addWidget(t, cardDefinition, props) {
            let newid = '1';
            if (t.layout.items.length > 0) {
                const id = Math.max(...t.layout.items.map(i => parseInt(i.id)));
                newid = `${id + 1}`;
            }
            let x = 0, y = 0;
            let blocked = true;

            // Scan across and down looking for an opening
            for (y = 0; ; y++) {
                for (x = 0; x < t.layout.numberOfCols; x++) {
                    blocked = t.layout.items.some(i =>
                        (i.x <= x && (i.x + i.width - 1) >= x) &&
                        (i.y <= y && (i.y + i.height - 1) >= y));

                    if (!blocked) break;
                }

                if (!blocked) break;
            }
            
            var newItem = {
                id: newid,
                x: x,
                y: y,
                width: cardDefinition.Width,
                height: cardDefinition.Height,
                locked: false,
                title: cardDefinition.Name,
                ControlURL: cardDefinition.UserControl,
                ControlData: {...cloneDeep(props), FilterId: utils.generateUUID()},
            };

            t.layout.items.push(newItem);
            this.pickervisible = false;
        },

        removeItem(t, item) {
            const idx = t.layout.items.findIndex((i) => i.id == item.id);
            if (idx >= 0) t.layout.items.splice(idx, 1);
        },
        browse(t) {
            this.dialog_width = 860;
            this.picker = (
                <card-browser
                    confirm={(cardDefinition) => this.confirm(cardDefinition, t)}
                    cancel={this.cancel}
                ></card-browser>
            );
            this.pickervisible = true;
        },
        async confirm(cardDefinition, t) {
            this.pickervisible = false;

            // grab the control, find the schema, present a form to edit the properties
            let apiRequest = {
                method: 'GET',
                url: `Apps/UIWhole/UserControl/${cardDefinition.UserControl}`,
                doNotUseWebsocket: true,
                flatten: false,
                cache: false,
            };

            let c = await api.apiRequest(apiRequest);
            let responseControl = c.data?.Result || c.data;
            if (!responseControl) {
                apiRequest.url = `Apps/UIWhole/UserControl/public/${cardDefinition.UserControl}`;
                c = await await api.apiRequest(apiRequest);
                responseControl = c.data?.Result || c.data;
            }

            // Open a dialog with a form to input properties from the schema
            const s = responseControl.ControlDataSchema;
            if(Object.keys(s.properties).length > 0 && s.properties["DashboardPrompt"] && Object.keys(s.properties.DashboardPrompt).length > 0 ){
                this.dialog_width = 860;
                this.picker = (
                    <v-card>
                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background-color: var(--v-navigation-base); height: 60px">

                            <v-toolbar-title class="title white--text pl-0">
                                <translation-container context={this} value={cardDefinition.UserControl}></translation-container>
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <busyIcon></busyIcon>

                            <v-btn elevation={0} color="white" icon on-click={(e) => this.pickervisible = false}>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text class="mt-2">
                            <basic-form
                                controlData={{
                                    FormType: 'Standard',
                                    FormOptions: {
                                        ArrayStart: 'DefaultItem',
                                        DestroyStrategy: 'retain',
                                        NoInput: false,
                                    },
                                    Model: {
                                        Type: 'Raw',
                                        Definition: this.newproperties,
                                    },
                                    Schema: {
                                        Type: 'Raw',
                                        Definition: s.properties.DashboardPrompt,
                                    },
                                    Form: {
                                        Type: 'Inline',
                                        Definition: [
                                            {
                                                AllSchemaElements: true
                                            }
                                        ]
                                    },
                                    FormSize: 'Normal',
                                    SizeOptions: {
                                        Width: {
                                            Mode: 'Fill'
                                        },
                                        Height: {
                                            Mode: 'Auto'
                                        }
                                    }
                                }}
                            ></basic-form>
                        </v-card-text>

                        <v-card-actions class="ma-2" style="justify-content: flex-end;">
                            <v-btn elevation={0} color="primary" on-click={() => this.addWidget(t, cardDefinition, this.newproperties)}>
                                <translation-container context={this} value="OK"></translation-container>
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                );
                this.pickervisible = true;
            } else {
                this.addWidget(t, cardDefinition, cardDefinition.Type == "Numeric" ? { Metric : cardDefinition.Metric } : {});
            }
        },
        cancel() {
            this.pickervisible = false;
        },
        getHeader(h) {
            const header = [];
            header.push(
                <v-btn transparent icon v-show={this.isEditableComputed && !this.dash_tabs[this.dashboard]?.editing} on-click={() => this.toggleEditing(this.dash_tabs[this.dashboard])}>
                    <v-icon style="fontSize:larger !important; color:black!important">mdi-pencil</v-icon>
                </v-btn>
            );

            if (this.controlData.HeaderControls && this.controlData.HeaderControls.length > 0) {
                for (let i = 0; i < this.controlData.HeaderControls.length; i++) {
                    const c = this.controlData.HeaderControls[i];
                    let DynamicControl = utils.getDynamicComponent(h, c);
                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';
        
                    if (!c.$objectId)
                        c.$objectId = utils.generateUUID();
        
                    let id = `${c.$objectId}_${i}`;
        
                    header.push(
                        <DynamicControl
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            type={c.ControlType}
                            key={id}
                            name={c.ControlData ? (c.ControlData.Name || 'unnamed') : 'unnamed'}
                            root={this.root}
                            parentType="HorizontalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            controlName={c.Name}
                            cacheControl={c.CacheControl}
                            controlEvents={c.Events}
                        >
                        </DynamicControl>
                    );
                }
                return header;
            }
            
            return header;
        }
    },
    props: {},
    render(h) {
        if (!this.todisplay || this.dash_loading) return null;

        const tabs = [];
        const window_body = [];

        for (let i = 0; i < this.dash_tabs.length; i++) {
            const t = this.dash_tabs[i];

            let title = t.Title;
            if (t.editing)
            {
                if (t.$isEditing) {
                    title =
                        <div style="display: flex; align-items: center;">
                            <v-text-field style="width: 300px;" dense hide-details outlined class="pt-1 pb-1" value={t.Title} on-change={(e) => t.Title = e}></v-text-field>
                            {utils.generateTooltip(h, <v-btn elevation={0} small icon on-click={(e) => this.saveTabTitle(e, t)}><v-icon small>mdi-check</v-icon></v-btn>, 'Save', 'right')}
                            {utils.generateTooltip(h, <v-btn elevation={0} small icon on-click={(e) => this.cancelEditTabTitle(e, t)}><v-icon small>mdi-close</v-icon></v-btn>, 'Cancel', 'right')}
                        </div>;
                }
                else {
                    title =
                        <span>
                            {t.Title}
                            {utils.generateTooltip(h, <v-btn elevation={0} small icon on-click={(e) => this.editTabTitle(e, t)}><v-icon small>mdi-pencil</v-icon></v-btn>, 'Edit', 'right')}
                        </span>;
                }
            }
            else if (!t.ID)
                title += ' (Not Saved)';

            const scopedSlots = {
                content: (item) => {
                    if (item.ControlURL) {
                        return (
                            <dynamic-user-control
                                key={item.id}
                                class="c-Dash-Content"
                                style="flex-grow: 1;"
                                on={{ "finished-render": (reference) => this.finishRenderHandler(reference) }}
                                root={this.root}
                                parentType="Dash"
                                controlData={item.ControlData}
                                controlURL={item.ControlURL}
                            ></dynamic-user-control>
                        );
                    } else {
                        let addbtn;
                        if (t.editing)
                            addbtn = (
                                <v-btn elevation={0} icon large on-click={(e) => this.browse(e, t, item)}>
                                    <v-icon large>mdi-plus</v-icon>
                                </v-btn>
                            );

                        return (
                            <div>
                                <span><translation-container context={this} value="No content defined"></translation-container>: {item.id}</span>
                                <br />
                                {addbtn}
                            </div>
                        );
                    }
                },
            };

            window_body.push(
                <v-window-item key={i} transition={false} style="overflow-y: scroll">
                    <ResponsiveDash
                        scopedSlots={scopedSlots}
                        layout={t.layout}
                        editing={t.editing}
                        on-remove={(item) => this.removeItem(t, item)}
                    ></ResponsiveDash>
                </v-window-item>
            );
        }

        var dashboardEditButtons = [
            (
                <v-btn outlined color="primary" v-show={this.dash_tabs[this.dashboard]?.editing} on-click={() => this.browse(this.dash_tabs[this.dashboard])}>
                    <translation-container context={this} value="Add Card"></translation-container>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            ),
            (
                <v-btn outlined color="primary" v-show={this.dash_tabs[this.dashboard]?.ID && this.dash_tabs[this.dashboard]?.editing && !this.isTemplateComputed} on-click={() => this.deleteTab(this.dash_tabs[this.dashboard])}>
                    <translation-container context={this} value="Remove Dashboard"></translation-container>
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            ),
        ]

        const header = this.getHeader(h);
        

        var dashboardEditTitleAndSave = [
            (
                <div style="display: flex; align-items: center;">
                    <v-text-field style="width: 250px;" dense hide-details outlined class="pt-1 pb-1" value={this.dash_tabs[this.dashboard]?.Title} on-change={(e) => this.dash_tabs[this.dashboard].Title = e}></v-text-field>
                </div>
            ),
            (
                <v-btn color="primary" on-click={() => this.saveTab(this.dash_tabs[this.dashboard])}>
                    <translation-container context={this} value="Save"></translation-container>
                </v-btn>
            ),
            (
                <v-btn color="secondary" on-click={() => this.cancelTab(this.dash_tabs[this.dashboard])}>
                    <translation-container context={this} value="Cancel"></translation-container>
                </v-btn>
            )

        ]

        var dashboardListItems = this.dash_tabs.map((d, index) => {
            return (
                <v-list-item key={index} on-click={() => {this.dashboard = index; this.System.LocalStorage(`Dashboard_${this.controlData.Name || 'undefined'}`, this.dash_tabs[this.dashboard].ID)}} color={this.dashboard == index ? 'secondary' : ''}>
                    <v-list-item-icon><v-icon> mdi-view-dashboard </v-icon> </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title flat transparent>
                            <translation-container context={this} value={d.Title}></translation-container>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            )
        });

        if(this.isEditableComputed && !this.isTemplateComputed)
            dashboardListItems.push([
                (<v-divider></v-divider>),
                (
                    <v-list-item on-click={() => this.addTab()}>
                        <v-list-item-icon><v-icon> mdi-plus </v-icon> </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title flat transparent>
                                <translation-container context={this} value="New Dashboard"></translation-container>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                )
            ])

        return (
            <div style={this.style} class={{ "c-Dash": true, [`c-name-${this.name || "unnamed"}`]: true }}>
                { 
                    this.dash_tabs.length ?
                        <div style="display:flex; flex-grow:1; flex-direction: column;">
                            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;" class="mx-2">
                                <div>
                                    { 
                                        !this.dash_tabs[this.dashboard]?.editing ?
                                            <v-menu
                                                bottom
                                                left
                                                offset-y
                                                scopedSlots={{
                                                    activator: ({ on, attrs }) => (
                                                        <v-btn elevation={0} color="primary" outlined class="align-self-center mr-4 my-2" {...{ on }} {...{ attrs }}>
                                                            {this.dash_tabs[this.dashboard]?.Title}
                                                            <v-icon right>mdi-menu-down</v-icon>
                                                        </v-btn>
                                                    ),
                                                }}
                                            >
                                                <v-list dense>
                                                    <v-list-item-group>
                                                        {dashboardListItems}
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        : null
                                    }              
                                </div>
                                <div>
                                    {
                                        this.dash_tabs[this.dashboard]?.editing ? 
                                            <div style="display:flex; flex-direction: row; gap: 10px; align-items: center; justify-content:center;">
                                                {dashboardEditTitleAndSave}
                                            </div> 
                                        : null
                                    }
                                </div>
                                <div style="padding-right: 10px; display:flex; flex-direction:row; gap: 5px; align-items:center; justify-content:flex-end">
                                    <div style="display:flex; flex-direction:row; gap: 5px; align-items:center">
                                        {
                                            this.dash_tabs[this.dashboard]?.editing ? 
                                            dashboardEditButtons 
                                            : header
                                        }
                                    </div>
                                </div>

                            </div>
                            <v-card style="display:flex; flex-grow:1">
                                <v-window style="display:flex; flex-grow: 1;" value={this.dashboard}>
                                    {window_body}
                                </v-window>
                                <v-dialog value={this.pickervisible} max-width={this.dialog_width} scrollable>
                                    {this.picker}
                                </v-dialog>
                            </v-card>
                        </div>
                    : 
                        <div style="display: grid; grid-template-rows: 1fr 1fr 1fr; height: 100%">
                            <v-icon style="font-size:20vh; top: 80px; color: #BDBDBD">mdi-view-dashboard-edit-outline</v-icon>
                            <div style="display:flex; flex-direction:column; justify-content:center; align-items:center; flex-grow:1; gap:10px;">
                                <div>
                                    <b style="font-size: 24px">
                                        <translation-container context={this} value="Let's get started!"></translation-container>
                                    </b>
                                </div>
                                <div>
                                    <translation-container context={this} value="Visualize data in one place by creating dashboards that show what is happening across your contact center in real-time"></translation-container>
                                </div>
                                <v-btn color="primary" on-click={() => this.addTab()}>
                                    <translation-container context={this} value="CREATE DASHBOARD"></translation-container>
                                </v-btn>
                            </div>
                        </div>
                }
            </div>
        );
    },
});