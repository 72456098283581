import Vue from 'vue';
import utils from '@/Shared/utils.jsx';
import BaseComponent from '../BaseComponentMixin.jsx';
import EventBus from '@/Application/event-bus';
import eventCoordinator from '@/Services/serverEventCoordinator';
import methods from '@/Shared/methods.js';
import filters from '@/Shared/filters.js';

import HSMContainer from './HSMContainer.jsx';
import HSMState from './HSMState.jsx';
import UserControHSM from './UserControlHSM.jsx';

/*
 * This is a newly rewritten HSM using async and await.
 *
*/

Vue.component('hsm-new', {
    mixins: [BaseComponent],
    data: function () {
        return {
            id: utils.generateUUID(),

            parentHSM: null,
            children: [], // This is the source of any dynamic child HSMs that may be added via AddChildHSM, not a reference to the physical component

            state_references: {},
            child_references: {},

            currentStatePath: '',
            eventQueue: [],
            queueProcessing: false,
            stateProcessing: false,

            currentHandler: null,

            sessionListeners: [],
            visible: false,
            destroyed: false,
        }
    },
    props: {
        payload: null,
    },
    created() {
        //utils.debug(`HSM:${this.parentHSM?.name}:${this.name}:${this._uid} create...`);

        if (this.payload) {
            if (this.payload.newid)
                this.id = this.payload.newid;

            if (this.payload.visible)
                this.visible = this.payload.visible;

            if (this.payload.parentHSM)
                this.parentHSM = this.payload.parentHSM;
        }
        else {
            //this.visible = this.System.LocalStorage(`HSMSettings_${this.name}`) == 'true';
            this.visible = localStorage.getItem(`HSMSettings_${this.name}`) == 'true';
        }

        this.$on('Action-HSMEvent', this.performHSMEvent);
        this.$on('Action-HSMSetNextState', this.performHSMSetNextState);
        this.$on('Action-HSMStartServerEventListener', this.performHSMStartServerEventListener);
        this.$on('Action-HSMStopServerEventListener', this.performHSMStopServerEventListener);
        this.$on('Action-HSMAddChildHSM', this.performHSMAddChildHSM);
        this.$on('Action-HSMRemoveChildHSM', this.performHSMRemoveChildHSM);

        this.$on(`Action-HSMEvent:${this.id}`, this.performHSMEvent);

        EventBus.$on(`Action-HSMEvent:${this.id}`, this.performHSMEvent);

        if (this.controlData.Name) {
            EventBus.$on(`Action-HSMEvent:${this.controlData.Name}`, this.performHSMEvent);
        }

        if (this.parentHSM)
            this.parentHSM.addChildReference(this.id, this);

        if (!this.parentHSM)
            utils.hsmlist.registerHSM(this.name, this);
    },
    destroyed() {
        this.destroyed = true;
        //utils.error(`HSM:${this.name} destroyed`);

        if (!this.parentHSM)
            utils.hsmlist.removeHSM(this.name);

        this.$off('Action-HSMEvent', this.performHSMEvent);
        this.$off('Action-HSMSetNextState', this.performHSMSetNextState);
        this.$off('Action-HSMStartServerEventListener', this.performHSMStartServerEventListener);
        this.$off('Action-HSMStopServerEventListener', this.performHSMStopServerEventListener);
        this.$off('Action-HSMAddChildHSM', this.performHSMAddChildHSM);
        this.$off('Action-HSMRemoveChildHSM', this.performHSMRemoveChildHSM);

        this.$off(`Action-HSMEvent:${this.id}`, this.performHSMEvent);

        EventBus.$off(`Action-HSMEvent:${this.id}`, this.performHSMEvent);

        if (this.controlData.Name) {
            EventBus.$off(`Action-HSMEvent:${this.controlData.Name}`, this.performHSMEvent);
        }

        if (this.parentHSM)
            this.parentHSM.removeChildReference(this.id);

        this.unsubcribeFromAllEvents();
    },
    //mounted Replaced with preRenderComplete
    mounted() {
    },
    methods: {
        preRenderComplete() {
            // All child states should be mounted and active - and in my state_references list
            // First step is to transition into the initial state
            //utils.debug(`HSM:${this.name}:${this._uid} preRenderComplete`);

            this.finishRenderHandler(this);

            const c = this;

            // When HSMs are dynamically added via AddChildHSM return object representing the newly
            // created control (can't pass the literal object as vue reactivity gets upset).
            // Note that this is most commonly used to reference the variables within the child HSM
            // but I've seen places that call ParamByName too -- so including methods as well.
            const copy = {
                $parent: function() {
                    // Exposing $parent as a getter to prevent the reactivity from attempting to
                    // traverse this property. Causes an infinite stack loop and breaks.
                    return c.$parent;
                },
                ...methods,
                ...filters,
                UniqueID: this.id,
                get CurrentState() {
                    return c.CurrentState;
                },
                get CurrentStatePath() {
                    return c.CurrentStatePath;
                },
                Vars: this.Vars,
                sendEvent: this.sendEvent,
                $attrs: { ...this.$attrs }, // In case paramData is here
            };
            // Cause "this" keyword to refer to the copy object within functions
            for (let key in copy)
                if (typeof copy[key] === 'function')
                    copy[key] = copy[key].bind(copy);

            this.$emit('created', copy);

            let initialStatePath;
            let initialStateData;
            if (this.payload) {
                initialStatePath = this.payload.initialStatePath;
                initialStateData = this.payload.initialStateData;
            }
            this.enterState(initialStatePath || this.controlData.InitialState, false, initialStateData);
        },
        setVisibility(value) {
            utils.debug(`HSM Visibility ${this.name} : ${value}`);
            this.visible = value;
            //this.System.LocalStorage(`HSMSettings_${this.name}`, value ? 'true' : 'false');
            localStorage.setItem(`HSMSettings_${this.name}`, value ? 'true' : 'false');
        },

        async performHSMEvent(action) {
            utils.log(`HSM action handler HSMEvent ${action.ActionData.EventName}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const eventname = utils.evaluate(action.eventName, action.context);
                const data = action.data ? utils.evaluateObject(action.data, action.context) : null;

                this.sendEvent(eventname, undefined, undefined, data);

                try {
                    await utils.success(action);
                }
                catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performHSMSetNextState(action) {
            utils.log(`HSM action handler HSMSetNextState ${action.ActionData.NextStatePath}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                if (this.currentHandler)
                    this.currentHandler.SetNextState(action);

                try {
                    await utils.success(action);
                }
                catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performHSMStartServerEventListener(action) {
            utils.log(`HSM action handler HSMStartServerEventListener ${action.ActionData.EventName} ${action.ActionData.SessionID}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const sessionid = utils.evaluate(action.sessionid, action.context);
                const eventname = utils.evaluate(action.eventname, action.context);

                if (sessionid) {
                    //this.hsmLogDebug("HSM: " + this.logName + ", startServerEventListnerHandler, SessionID: " + sessionid + ", UniqueID: " + this.uniqueId + ", EventName: " + eventname);
                    eventCoordinator.subscribeForSessionEvents(sessionid, this.id, eventname, this.onServerEventRecieved);

                    if (this.sessionListeners.indexOf(sessionid) == -1)
                        this.sessionListeners.push(sessionid);

                    try {
                        await utils.success(action);
                    }
                    catch (e) { }
                }
                else
                    try {
                        await utils.failure(action, { Reason: 'SessionID is unassigned' });
                    }
                    catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performHSMStopServerEventListener(action) {
            utils.log(`HSM action handler HSMStopServerEventListener ${action.ActionData.EventName} ${action.ActionData.SessionID}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const sessionid = utils.evaluate(action.sessionid, action.context);
                const eventname = utils.evaluate(action.eventname, action.context);

                if (sessionid) {
                    //this.hsmLogDebug("HSM: " + this.logName + ", startServerEventListnerHandler, SessionID: " + sessionid + ", UniqueID: " + this.uniqueId + ", EventName: " + eventname);
                    eventCoordinator.unsubscribeForSessionEvents(sessionid, this.id, eventname);

                    var idx = this.sessionListeners.indexOf(sessionid);
                    if (idx != -1)
                        sessionListeners.splice(idx, 1);

                    try {
                        await utils.success(action);
                    }
                    catch (e) { }
                }
                else
                    try {
                        await utils.failure(action, { Reason: 'SessionID is unassigned' });
                    }
                    catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performHSMAddChildHSM(action) {
            utils.log(`HSM action handler HSMAddChildHSM (action.context.Input:${JSON.stringify(action.context.Input)})`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                this.children.push({
                    newid: utils.evaluate(action.newid, action.context) || utils.generateUUID(),
                    initialstatepath: utils.evaluate(action.initialstatepath, action.context),
                    initialstatedata: utils.evaluate(action.initialstatedata, action.context),
                    control: action.ActionData.HSM,
                    action: action,
                });

                // success / complete / FinishFunc differed to the code within the on-created handler (below)
                //try {
                //    await utils.success(action);
                //}
                //catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            //finally {
            //    try {
            //        await utils.complete(action);
            //    }
            //    catch (e) { }

            //    // Complete the promise for the executeAction method
            //    action.FinishFunc(true);
            //}
        },
        async performHSMRemoveChildHSM(action) {
            utils.log(`HSM action handler HSMRemoveChildHSM: ${action.ActionData.UniqueID}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const id = utils.evaluate(action.uniqueid, action.context);

                if (id in this.child_references) {
                    this.removeChildReference(id);

                    try {
                        await utils.success(action);
                    }
                    catch (e) { }
                }
                else
                    try {
                        await utils.failure(action, { Reason: `ChildHSM UniqueID: ${id} not found` });
                    }
                    catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },

        onServerEventRecieved(eventData) {
            // add "Server" to the participants list (if it hasn't already been added) with an order of 5 so it will appear on the left hand side of the diagram
            // this.PlantUML.addParticipant("Server", "Server", 5);
            if (this.destroyed) return;

            this.sendEvent(eventData.EventName, null, null, eventData.Content);
        },

        addStateReference(fullname, component) {
            // Allows us to directly interact with the child state vue component
            this.state_references[fullname] = component;
        },
        addChildReference(id, component) {
            // Allows us to directly interact with the child HSM vue component
            this.child_references[id] = component;
        },
        removeChildReference(id) {
            const found = (id in this.child_references);
            delete this.child_references[id];

            const idx = this.children.findIndex(c => c.newid == id);
            if (idx >= 0)
                this.children.splice(idx, 1);

            //utils.debug(`HSM:${this.name} removeChildReference(${id}) ${found ? 'found IN' : 'NOT found in'} child_references; idx:${idx}`, null, true);
        },

        GetAbsoluteState(path) {
            return this.state_references[path];
        },
        FindLCA(fromStatePath, toStatePath) {
            let fromParts = fromStatePath.split('.');
            let toParts = toStatePath.split('.');
            let depth = -1;
            for (let i = 0; i < Math.min(fromParts.length, toParts.length) && fromParts[i] == toParts[i]; i++)
                depth = i;

            if (depth < 0)
                return [];
            else
                return fromParts.slice(0, depth + 1);
        },
        async enterState(newStatePath, external, data) {
            if (this.destroyed) return;
            this.stateProcessing = true;
            utils.debug(`HSM:${this.name} stateProcessing = true`);

            try {
                utils.debug(`HSM:${this.name} enterState(newStatePath:${newStatePath}) from:${this.currentStatePath}`);

                let lca_point = 0;
                if (this.currentState) {
                    // Invoke the exit events to the common ancester
                    let lca = this.FindLCA(this.currentStatePath, newStatePath);

                    // Walk up from the current state, executing the exit actions, until we reach the lca.
                    let currParts = this.currentStatePath.split('.');
                    for (lca_point = currParts.length - 1; lca_point >= lca.length; lca_point--) {
                        let path = currParts.slice(0, lca_point + 1).join(".");
                        let state = this.GetAbsoluteState(path);

                        await state.Exit(data);

                        this.currentState = state;
                    }
                    lca_point++;
                }

                // Now walk down from the lca_point to the new state, executing the enter actions
                let newParts = newStatePath.split('.');

                // Check if the new state is only a parent of the starting state, meaning we don't enter any other states
                if (lca_point === newParts.length) {
                    let path = newParts.join(".");
                    this.currentState = this.GetAbsoluteState(path);

                    if (external) {

                        await this.currentState.Exit(data);

                        //HSMStateExitMsg.Log(OwnerId, Name, path, _id, TransactionId);

                        //HSMStateEnterMsg.Log(OwnerId, Name, path, _id, TransactionId);

                        await this.currentState.Enter(data);
                    }
                }
                else
                    // Now walk down from the lca_point to the new state, executing the enter actions
                    for (; lca_point < newParts.length; lca_point++) {
                        let path = newParts.slice(0, lca_point + 1).join(".");

                        //HSMStateEnterMsg.Log(OwnerId, Name, path, _id, TransactionId);

                        this.currentState = this.GetAbsoluteState(path);

                        await this.currentState.Enter(data);
                    }

                this.currentStatePath = newStatePath;

                //utils.debug(`HSM:${this.name} now in:${this.currentStatePath}`);

                if (this.currentState && this.currentState.FinalState) {
                    //utils.debug(`HSM:${this.name} ${this.currentStatePath} is FinalState; ${this.parentHSM ? 'Removing from parentHSM' : 'No parentHSM found'}`);

                    if (this.parentHSM)
                        this.parentHSM.removeChildReference(this.id);
                }
            }
            finally {
                this.stateProcessing = false;
                utils.debug(`HSM:${this.name} stateProcessing = false`);
            }

            if (!this.queueProcessing && this.eventQueue.length > 0)
                this.handleQueuedEvents();
        },
        sendEvent(eventName, nextstatepath, external, data, senderpath) {
            utils.debug(`HSM:${this.name} in:${this.currentStatePath} - sendEvent(eventName:${eventName}, nextstatepath:${nextstatepath}) (from:${senderpath}) :: queueProcessing:${this.queueProcessing} stateProcessing:${this.stateProcessing}`);

            this.eventQueue.push({ EventName: eventName, NextStatePath: nextstatepath, External: external, Content: data, SenderPath: senderpath });
            if (!this.queueProcessing && !this.stateProcessing)
                this.handleQueuedEvents();
        },
        clearSenderEvents(eventName, senderpath) {
            this.eventQueue = this.eventQueue.filter(e => e.EventName != eventName || e.SenderPath != senderpath);
        },
        unsubcribeFromAllEvents() {
            this.sessionListeners.forEach(sessionId => {
                eventCoordinator.unsubscribeForSessionEvents(sessionId, this.id);
            });
        },
        pullItemFromQueue() {
            const item = this.eventQueue[0];
            this.eventQueue.splice(0, 1);
            return item;
        },
        async handleQueuedEvents() {
            if (this.destroyed) return;
            this.queueProcessing = true;
            utils.debug(`HSM:${this.name} queueProcessing = true`);

            try {
                while (this.eventQueue.length > 0) {
                    if (this.destroyed) return;
                    const evt = this.pullItemFromQueue();

                    if (evt.EventName == this.event_setnextstate) {
                        //HSMInfoMessageMsg.Log(OwnerId, Name, $"HandleEventAsync received {evt.ToString()} in {_currentStatePath} EventName == _event_setnextstate (JObject)", _id, TransactionId);

                        // Now, transition to the state defined in the event handler.
                        if (evt.Content && evt.NextStatePath) {
                            utils.debug(`HSM:${this.name} about to enterState(${evt.NextStatePath}, data:${evt.Content}, evt:${JSON.stringify(evt)}) defined in event`);
                            await this.enterState(evt.NextStatePath, evt.External, evt.Content);
                            utils.debug(`HSM:${this.name} enterState(${evt.NextStatePath} complete`);
                        }

                        return true;
                    }

                    this.currentHandler = this.currentState.getEventHandler(evt.EventName);

                    let state = this.currentState;
                    if (!this.currentHandler && this.currentStatePath.includes(".")) {
                        // We are in a child state, so traverse up the state hierarchy to see if there is a parent state that will handle this event
                        let stateParts = this.currentStatePath.split('.');
                        for (let i = stateParts.length - 1; i >= 0 && !this.currentHandler; i--) {
                            let nxtPath = stateParts.slice(0, i + 1).join(".");
                            state = this.GetAbsoluteState(nxtPath);
                            this.currentHandler = state.getEventHandler(evt.EventName);
                        }
                    }

                    if (this.currentHandler) {
                        this.LastEventReceived = new Date();
                        //HSMEventHandledMsg.Log(OwnerId, Name, evt.EventName, state.StateName, _currentHandler.NextStatePath, evt.Content?.ToString(), _id, TransactionId);

                        // Found our handler. First, fire the actions (if any).
                        utils.debug(`HSM:${this.name} about to execute EventActions`);
                        await utils.executeAndCompileAllActions(this.currentHandler.EventActions, { Data: evt.Content }, this);
                        utils.debug(`HSM:${this.name} execute EventActions complete`);
                        //await FireActions(state, _currentHandler.EventActions, JsonObj.FromObject(new { Data = evt.Content })).ConfigureAwait(false);

                        // After the actions complete, check to see if the next state has been set by an action (HSM_SetNextState)
                        let stateOverride = this.currentHandler.GetNextState();
                        if (stateOverride) {
                            utils.debug(`HSM:${this.name} about to enterState(${stateOverride.NextStatePath}, data:${stateOverride.Data}) overridden`);
                            await this.enterState(stateOverride.NextStatePath, stateOverride.ExternalTransition, stateOverride.Data);
                            utils.debug(`HSM:${this.name} enterState(${stateOverride.NextStatePath} complete`);
                        }
                        else
                            // Otherwise, transition to the state defined in the event handler.
                            if (this.currentHandler.NextStatePath) {
                                //utils.debug(`HSM about to enterState(${this.currentHandler.NextStatePath}, data:${this.currentHandler.NextStateData}) defined in handler`);
                                if (!this.currentHandler.NextStateData_expn && this.currentHandler.NextStateData)
                                    this.currentHandler.NextStateData_expn = utils.compile(this, this.currentHandler.NextStateData);

                                let data;
                                if (this.currentHandler.NextStateData_expn)
                                    data = utils.evaluate(this.currentHandler.NextStateData_expn, this);

                                utils.debug(`HSM:${this.name} about to enterState(${this.currentHandler.NextStatePath}`);
                                await this.enterState(this.currentHandler.NextStatePath, this.currentHandler.ExternalTransition, data);
                                utils.debug(`HSM:${this.name} enterState(${this.currentHandler.NextStatePath} complete`);
                            }
                    }
                }
            }
            catch (e) {
                utils.error('Error in HSM.handleQueuedEvents', e);
            }
            finally {
                this.currentHandler = null;
                this.queueProcessing = false;
                utils.debug(`HSM:${this.name} queueProcessing = false`);
            }
        },
    },
    computed: {
        totalItems() {
            let total = 0;

            if (this.controlData && this.controlData.ControlContainer && (
                this.controlData.ControlContainer.Controls?.length > 0 ||
                this.controlData.ControlContainer.PrerenderActions?.length > 0 ||
                this.controlData.ControlContainer.PostrenderActions?.length > 0))
                total++;

            // Each state will have to be rendered
            if (this.controlData.States && Array.isArray(this.controlData.States))
                total += this.controlData.States.length;

            // Add one to include the component itself.
            total++;

            return total;
        },
        states_components: function () {
            const h = this.$createElement;
            return this.controlData.States.map(s => (
                <hsm-state-new
                    key={s.StateName}
                    on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                    name={s.StateName}
                    root={this.root}
                    parentHSM={this}
                    type="HSM_State"
                    controlData={s}
                    visible={this.visible}
                >
                </hsm-state-new>
            ));
        },
        children_components: function () {
            const h = this.$createElement;
            return this.children.map(c => {
                let DynamicControl = utils.getDynamicComponent(null, c.control, 'dynamic-user-control-hsm');

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = `${this.id}_${c.$objectId}`;

                return (
                    <DynamicControl
                        key={id}
                        on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                        type={c.control.ControlType}
                        name={c.control.ControlData ? c.control.ControlData.Name : ''}
                        root={this.root}
                        parentType="HSM"
                        controlData={c.control.ControlData}
                        controlURL={c.control.ControlURL}
                        payload={{
                            parentHSM: this,
                            newid: c.newid,
                            initialStatePath: c.initialstatepath,
                            initialStateData: c.initialstatedata,
                            visible: this.visible,
                        }}
                        on-created={async newControl => {
                            //if (c.control.ControlURL == 'public/OmniChannel/v2/Voice/BLeg')
                            //    debugger;

                            try {
                                await utils.success(c.action, { HSM: newControl });
                            } catch (e) { }

                            try {
                                await utils.complete(c.action);
                            } catch (e) { }

                            // Complete the promise for the executeAction method
                            c.action.FinishFunc(true);
                        }}
                        inputcontext={c.action.context}
                        controlName={c.Name}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                        controlEvents={c.control.Events}
                    >
                    </DynamicControl>
                );
            });
        },
        //componentchildren: function () {
        //    return Object.keys(this.child_references).map(k => this.child_references[k]);
        //},
        DebugVisibility: {
            get: function () {
                return this.visible;
            },
            set: function (value) {
                utils.debug(`HSM Visibility ${this.name} : ${value}`);
                this.visible = value;
              //this.System.LocalStorage(`HSMSettings_${this.name}`, value ? 'true' : 'false');
                localStorage.setItem(`HSMSettings_${this.name}`, value ? 'true' : 'false');
            }
        },

        UniqueID: function () {
            return this.id;
        },
        CurrentState: function () {
            return this.currentStatePath;
        },
        CurrentStateName: function () {
            return this.currentState.name;
        },
        ChildHSMs: function () {
            return this.children;
        },
    },
    render(h) {
        let cc;
        if (this.controlData && this.controlData.ControlContainer && (
            this.controlData.ControlContainer.Controls?.length > 0 ||
            this.controlData.ControlContainer.PrerenderActions?.length > 0 ||
            this.controlData.ControlContainer.PostrenderActions?.length > 0))
            cc = (
                <hsm-container
                    key={this.id}
                    on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                    type="HSM_ControlContainer"
                    name={`${this.controlData.ControlContainer.Name || 'unnamedhsm'}_controlContainer`}
                    root={this.root}
                    parentType={this.parentType}
                    controlData={this.controlData.ControlContainer}
                    parentHSM={this}
                >
                </hsm-container>
            );

        let style = {
            display: "flex",
            flexWrap: "wrap",
        };

        if (this.visible)
            style = {
                ...style,
                padding: "5px",
                margin: "2px",
                borderRadius: "5px",
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: "blue",
            };

        try {
            return (
                <div v-show={this.visible} style={style}>
                    <div style="display: flex; flex-direction: column;">
                        <span>{`HSM: ${this.name} :: ${this.currentStatePath} [${this._uid}]`}</span>
                        <span>Queue:[{this.eventQueue.length}]</span>
                    </div>
                    <i title={`Toggle Visibility for HSM ${this.name}`} style="font-size: larger;" class="mdi mdi-help-circle" on-click={(e) => this.visible = !this.visible}></i>
                    {cc}
                    {this.states_components}
                    {this.children_components}
                </div>
            );
        }
        catch (e) {
            return <div>Error in HSM: {JSON.stringify(e)}</div>;
        }
    }
});