import Vue from 'vue';
import utils from './utils.jsx';
import careHelpfulFunctions from '../Application/careHelpfulFunctions.jsx';
import {appSettings} from '@/Shared/appSettings';
import dayjs from 'dayjs';

const datetimemin = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\dZ?/;
const datetimesec = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\dZ?/;
const dateonly    = /\d{4}-[01]\d-[0-3]\dZ?/;

const filters = {
    f_date: function (datevalue, format) {
        if (!datevalue)
            return '';

        let fmt;
        switch (format) {
            case 'longDate': fmt = 'll'; break;
            case 'shortDate': fmt = 'l'; break;
            default: fmt = 'll LTS'; break;
        }
        if (datevalue instanceof Date && !isNaN(datevalue)) {
            return dayjs(datevalue).format(fmt || 'lll');
        }
        else if (typeof datevalue === 'string') { // && (datevalue.match(datetimemin) || datevalue.match(datetimesec) || datevalue.match(dateonly))) {
            const dt = new Date(datevalue);
            if (dt instanceof Date && !isNaN(dt))
                return dayjs(dt).format(fmt || 'lll'); 
            else
                return datevalue;
        }
        else {
            return datevalue;
        }
    },
    f_shortDateShortTime(datetime) {
        const dt = new Date(datetime);
        return dayjs(dt).format('lll');
    },
    f_formatDateForLocale(datevalue, format) {
        return this.f_date(datevalue, format);
    },
    f_formatHourOnlyDateTimeForLocale(datevalue, format) {
        datevalue = datevalue + ":00:00";
        return this.f_date(datevalue, format);
    },
    f_phoneNumber: function (phonenum, format) {
        return careHelpfulFunctions.formatPhoneNumber(phonenum, format);
    },
    f_number: function (num, digits) {
        var num2 = parseFloat(num);
        if (typeof num2 === 'number') {
            return Intl.NumberFormat(appSettings.LocaleKey, { minimumFractionDigits: digits }).format(num2)
        }
        else
            return num;
    },
    f_bytes: function (size) {
        if (isNaN(size))
            size = 0;

        if (size < 1024)
            return size + ' Bytes';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' Kb';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' Mb';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' Gb';

        size /= 1024;

        return size.toFixed(2) + ' Tb';
    },
    f_currency: function (value) {
        if (typeof value !== "number") {
            return value;
        }

        // NEED TO MAKE THIS DYNAMIC FOR CURRENCY CODE
        // FOR NOW USD WILL BE FINE 

        var formatter = new Intl.NumberFormat(appSettings.LocaleKey, {
            style: 'currency',
            currency: 'USD'
        });
        return formatter.format(value);
    },
}

//return f_number(ParentByName('maincontainer').Vars.DisplayTime == 0 ? (util.durationSecondsAsString(RowData().TotalDuration)) : (((RowData().TotalDuration) / 60), 2));

export default filters;