import Vue from 'vue';
import BaseComponent from './BaseAgGridMixin.jsx';
import utils from '../../Shared/utils.jsx';

import { AgGridVue } from "ag-grid-vue";

Vue.component('infinite-grid', {
    mixins: [BaseComponent],
    components: {
        AgGridVue,
    },
    data: () => ({
        sortDef: null,
        startKeyList: null,
        pageSize: 100,
        allowExportAll: false,
        dataSource: null,
    }),
    created() {
        // Find the default sort column or the first one marked sortable
        this.sortDef = this.columnDefs.find(c => (this.controlData.DefaultSort && c.Field == this.controlData.DefaultSort) || (!this.controlData.DefaultSort && c.Sortable));
        if (!this.sortDef) {
            utils.warn(`InfiniteGrid ${this.name} - No default sortable column found - at least one column must be sortable; DataURL: ${this.controlData.DataURL}`);
            return;
        }

        const initialKeyValue = ((this.controlData.SortDirection == "desc") ? "%ff%ff" : "");

        let keys = '"' + encodeURIComponent(this.jumpToValue) + initialKeyValue + '"'; // add the initial value to handle asc/desc properly

        // Add an empty or an infinite value for each key field to generate the proper start key (["","",""] or ["%ff%ff","%ff%ff","%ff%ff"])
        if (this.sortDef.AdditionalKeyFields)
            for (let x = 0; x < this.sortDef.AdditionalKeyFields.length; x++)
                keys += ',"' + initialKeyValue + '"';

        this.startKeyList = "[" + keys + "]";

        // NOTE: Intentionally left model_all non-reactive by not including in the data properties (above) - large datasets will cause performance issues
        if (!this.model_all) this.model_all = [];

        const c = this;

        this.dataSource = {
            getRows: async (params) => {
                //debugger;

                // sort and filter parameters:
                // params.sortModel   : [{"sort":"asc","colId":"ObjectType"}]
                // params.filterModel
                
                if (c.modelurl) {
                    let url = c.modelurlvalue;
                    let pageSize = params.endRow - params.startRow; // loadOptions.take;
                    let skipFirst = false;
                    let sortCol = c.sortDef.Field;
                    let descending = c.controlData.SortDirection == "desc";

                    utils.debug(`InfiniteGrid load; params: ${JSON.stringify(params)} name:${c.name} url:${url}...`);
					//utils.debug(`InfiniteGrid load; Options: ${JSON.stringify(loadOptions)} name:${c.name} url:${url}...`);
					//if (loadOptions.filter)
					//	return null;

                    if (params.sortModel && params.sortModel.length > 0) {
                        sortCol = params.sortModel[0].colId;
                        descending = params.sortModel[0].sort == 'desc';

                        c.sortDef = c.columnDefs.find(c => c.Field == sortCol);
                    }
                    //if (loadOptions.sort && loadOptions.sort.length > 0 && typeof loadOptions.sort[0].selector === 'string') {
                    //    sortCol = loadOptions.sort[0].selector;
                    //    descending = loadOptions.sort[0].desc;

                    //    c.sortDef = c.columnDefs.find(c => c.Field == sortCol);
                    //}

                    if (params.startRow === 0) { //(loadOptions.skip === 0) {

                        const initialKeyValue = (descending ? "%ff%ff" : "");

                        let keys = '"' + encodeURIComponent(c.jumpToValue) + initialKeyValue + '"'; // add the initial value to handle asc/desc properly

                        // Add an empty or an infinite value for each key field to generate the proper start key (["","",""] or ["%ff%ff","%ff%ff","%ff%ff"])
                        if (c.sortDef.AdditionalKeyFields)
                            for (let x = 0; x < c.sortDef.AdditionalKeyFields.length; x++)
                                keys += ',"' + initialKeyValue + '"';

                        c.startKeyList = "[" + keys + "]";
                        c.model_all = [];
                    }
                    else if (params.startRow > 0 && c.model_all.length > 0) { //(loadOptions.skip > 0 && c.model_all.length > 0) {
                        // Find the last row that will be skipped or the end of the list, this will be used for the new startKey
                        //const last = c.model_all[loadOptions.skip <= c.model_all.length ? (loadOptions.skip - 1) : (c.model_all.length - 1)];
                        const last = c.model_all[params.startRow <= c.model_all.length ? (params.startRow - 1) : (c.model_all.length - 1)];

                        const initialKeyValue = encodeURIComponent(last[sortCol]);

                        let keys = '"' + initialKeyValue + '"'; // add the initial value to handle asc/desc properly

                        if (c.sortDef.AdditionalKeyFields)
                            for (let x = 0; x < c.sortDef.AdditionalKeyFields.length; x++)
                                keys += ',"' + last[c.sortDef.AdditionalKeyFields[x]] + '"';

                        c.startKeyList = "[" + keys + "]";
                        // pageSize++;
                        skipFirst = (c.Vars.DisableSkipFirst === undefined || !c.Vars.DisableSkipFirst);
                    }

                    // Append sort column, startKey, maxRows, and descending flag
                    url += (url.indexOf('?') == -1 ? "?" : "&") + // Example: /Apps/FilesExt/
                        "sortcol=" + sortCol.toLowerCase() + "&" + // Example: filenamenopath
                        "startKey=" + c.startKeyList + "&" +
                        "maxRows=" + (skipFirst ? pageSize + 1 : pageSize) + "&" +
                        "descending=" + (descending ? 'true' : 'false');

                    //Ex:
                    //url += '?sortcol=objecttype&startKey=["",""]&maxRows=100&descending=false';

                    let model = await utils.api.get(url, false, false);

                    let totalCount; // = 10000;
                    

                    if (skipFirst)
                        model.shift();

                    // Track all rows in a master array
                    if (c.model_all.length > 0) {
                        // we have already run once...
                        if (c.model_all.length > params.startRow) {
                            // override all spots in model_all with the model
                            c.model_all.splice(params.startRow, model.length, ...model);
                        }
                        else  {
                            c.model_all = c.model_all.concat(model);
                        }
                        
                    }
                    else {
                        c.model_all = model;
                    }

                    
                    if (model.length < pageSize) {
                        // Hit the end, insure the total count is correct
                        totalCount = c.model_all.length;// - (skipFirst ? 1 : 0);
                        if (totalCount < 0) totalCount = 0;
                    }
                    //c.model_all = c.model_all.concat(model);

                    utils.debug(`InfiniteGrid successCallback(model:${model.length}, totalCount:${totalCount})`);
                    utils.debug(`InfiniteGrid successCallback(model:${model.length}, pagesize:${pageSize})`);
                    //utils.debug(`model_all: ${JSON.stringify(c.model_all)}`);
                    //utils.debug(`model: ${JSON.stringify(model)}`);
                    utils.debug(`params: ${JSON.stringify(params)}`);
                    params.successCallback(model, totalCount);
                    //return {
                    //    data: model,
                    //    totalCount: totalCount,
                    //    summary: 0,
                    //    groupCount: 0
                    //};
                }
            },
        };
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
    },    
    computed: {
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async Refresh(clearSelectedRows) {
            this.internalRefresh(clearSelectedRows);
        }
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <div
                key={this.unique_id + '_main'}
                v-show={this.isvisible}
                class={{ 'c-InfiniteGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ag-theme-alpine': true }}
                style={style}
            >
                {this.gridHeaders}
                <ag-grid-vue
                    key={this.unique_id}
                    id={this.datatable_id}
                    class={{ 'ag-theme-alpine': true }}
                    style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
                    on-grid-ready={this.onGridReady}
                    on-first-data-rendered={(e) => this.onFirstDataRendered(e)}
                    on-selection-changed={(e) => this.rowSelectionChanged(e)}
                    rowModelType="infinite"
                    columnDefs={this.agColumnDefs}
                    datasource={this.dataSource}
                    rowSelection={this.controlData.SelectOptions.MultiSelect ? "multiple" : null}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    quickFilterText={this.searchText}
                    suppressScrollOnNewData={true}
                    suppressRowClickSelection={true}
                    suppressDragLeaveHidesColumns={true}
                    suppressCellSelection={true}
                    pagination={this.pagination}
                    paginationAutoPageSize={this.pagination}
                    getRowClass={this.getRowClass}
                    on-sort-changed={this.onSaveGridSortState}
                    on-column-visible={this.onSaveGridColumnState}
                    on-column-pinned={this.onSaveGridColumnState}
                    on-column-resized={this.onSaveGridColumnState}
                    on-column-moved={this.onSaveGridColumnState}
                    on-column-row-group-changed={this.onSaveGridColumnState}
                >
                </ag-grid-vue>
            </div>
        );

        //const style = {
        //    overflow: "auto",
        //    display: "flex",
        //    flexDirection: "column",
        //    maxHeight: "1000px",
        //    ...this.sizeStyle,
        //};

        //let scopedSlots = {};
        //let coldefs = this.getColDefs(scopedSlots);
        //this.getHeaderControls(scopedSlots);

        //let selector;
        //if (this.controlData.SelectOptions.Enable)
        //    selector = <DxSelection mode={this.controlData.SelectOptions.MultiSelect ? "multiple" : "single"} />;

        //return (
        //    <DxDataGrid
        //        class={{ 'c-InfiniteGrid': true, [`c-name-${this.name || 'unnamed'}`]: true }}
        //        style={style}
        //        ref={this.dataGridRefName}
        //        v-show={this.isvisible}
        //        data-source={this.dataSource}
        //        remote-operations={true}
        //        key-expr={this.controlData.KeyFields}
        //        allow-column-reordering={true}
        //        allow-column-resizing={true}
        //        column-resizing-mode="widget"
        //        column-auto-width={this.controlData.SizeColumnnsToContent || true}
        //        show-borders={true}
        //        show-row-lines={false}
        //        show-column-lines={true}
        //        row-alternation-enabled={true}
        //        word-wrap-enabled={false}
        //        scopedSlots={scopedSlots}
        //        selected-row-keys={this.selectedRowKeys}
        //        on-toolbar-preparing={(e) => this.onToolbarPreparing(e)}
        //        on-selection-changed={(s) => this.onSelectionChanged(s)}
        //    >
        //        <DxScrolling mode="infinite" />
        //        <DxSearchPanel visible={this.controlData.EnableFilter} />
        //        <DxPaging page-size={100}/>
        //        <DxLoadPanel enabled={true} />
        //        <DxStateStoring enabled={true} type="localStorage" storage-key={this.storageKey} />
        //        {selector}

        //        {coldefs}
        //    </DxDataGrid>
        //);
    }
});