import { EditPlugin } from 'roosterjs-content-model-plugins';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';

class ContentChangedPlugin extends EditPlugin{
    onChange;

    constructor(onChange) {
        super();
        this.onChange = careHelpfulFunctions.debounce(onChange, 250);
    }

    dispose() {
        this.onChange = null;
    }

    onPluginEvent(event) {
        // ContentChanged event = 'input'
        if((event.eventType == 'input' || event.eventType == 'contentChanged') && this.editor && this.onChange)
            this.onChange();
    }

    getName() {
        return 'ContentChangedPlugin'
    }
}

export default ContentChangedPlugin;